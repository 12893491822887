
import { Discussion, DiscussionItem } from "./discussions";
import { Game } from "./game";
import { Activity, LogType } from "./logViewModel";
import { Orchestrator } from "./orchestrator";
import { Zumo, ZumoCodes } from "./zumo";

import * as ko from 'knockout';
import {
  Observable, ObservableArray, PureComputed
} from 'knockout';


export class ChatVM {

  orchestrator: Orchestrator;

  opponentName = ko.observable("");
  opponentElo = ko.observable("");

  chatState = ko.observable("");

  discussion: Observable<Discussion>;

  newText = ko.observable("");

  showTextsFromOtherGames = ko.observable(true);

  gameId: number;

  constructor(orchestrator: Orchestrator) {
    this.orchestrator = orchestrator;
    this.discussion = ko.observable(new Discussion()); // need an empty discussion here
    this.gameId = 0;

    let self: ChatVM = this;

    // catch changes to the texts so that we can properly scroll the new ones into view.
    this.discussion.subscribe(function (newValue: any): void {

      self.discussion().items.subscribe(function (changes: any): void {

        changes.forEach(function (change: any): void {
          if (change.status === "added") {
            // use a timeout to give a chance for the HTML to be updated
            setTimeout(self._scrollChatListIntoView.bind(self), 5);
          }
        });

      }, null, "arrayChange");

    });

    this.showTextsFromOtherGames.subscribe(function (newValue: any): void {
      setTimeout(self._scrollChatListIntoView.bind(self), 5);
    });
  }

  private _scrollChatListIntoView(): void {
    let msgList: HTMLElement = document.getElementById("msgList");

    msgList.scrollTop = msgList.scrollHeight;
  }

  private async _refreshDiscussion(): Promise<void> {

    this.orchestrator.showProgress(true);

    let self: ChatVM = this;

    let result: Discussion | ZumoCodes = await this.orchestrator.discussionsCollection.getLatestDiscussionAsync(this.opponentName());

    self.orchestrator.showProgress(false);

    if (result instanceof Discussion) {
      self.discussion(result);
      self._scrollChatListIntoView();

      if (result.items().length > 0) {
        self.chatState(`last text on ${result.items()[result.items().length - 1].displayDateTime}`);
      } else {
        self.chatState("no communication with this person");
      }
      return;
    }
    self.orchestrator.showMessage(`Failed to get conversation. Error: ${Zumo.getErrorDetails(result)}`, LogType.Error, Activity.GetDiscussion);
  }

  private async _addChatAsync(): Promise<boolean> {

    let self: ChatVM = this;

    this.newText(this.newText().replace(/[~]/g, "≈"));
    this.newText(this.newText().replace(/[`]/g, "\""));

    this.newText(this.newText().replace(":-)", "\u263A"));
    this.newText(this.newText().replace(":)", "\u263A"));
    this.newText(this.newText().replace(": )", "\u263A"));
    this.newText(this.newText().replace(":- )", "\u263A"));

    this.newText(this.newText().replace(":-(", "\u2639"));
    this.newText(this.newText().replace(":(", "\u2639"));
    this.newText(this.newText().replace(": (", "\u2639"));
    this.newText(this.newText().replace(":- (", "\u2639"));

    let result: DiscussionItem | string = await this.discussion().addChatAsync(this.newText(), this.gameId);

    if (result instanceof DiscussionItem) {
      self.newText("");
      self.orchestrator.showProgress(false);
      self.chatState(`last text on ${result.displayDateTime}`);
      return true;
    }

    self.orchestrator.showMessage(`Failed to add the text. ${result}. Try again...`, LogType.Error, Activity.Text);
    return false;
  }

  public activate(g: Game): void {

    // need to empty the discussion if the game is different
    if (this.gameId != g.id) {
      this.discussion(new Discussion());

      this.newText("");
    }

    this.gameId = g.id;

    if (g.watchedGame) {
      return;
    }

    this.opponentName(g.opponent());

    if (g.opponentElo() === 0) {
      this.opponentElo("");
    } else {
      this.opponentElo(g.opponentElo().toString());
    }

    this._refreshDiscussion();
  }

  public refresh(): void {
    if (this.discussion().outOfSync()) {
      this._refreshDiscussion();
    }
  }

  public handleKeyInput(event: KeyboardEvent): void {

    if (event.keyCode === 13 /* ENTER */) {
      event.returnValue = false;

      var elem: any = document.getElementById("sendChatButton");
      elem.focus();
      this.sendClickAsync();
    }
  }

  public partner(): string {
    return (this.discussion().id() !== "0") ? this.discussion().partner() : null;
  }

  public async sendClickAsync(): Promise<void> {

    if (this.newText().length === 0) {
      this.orchestrator.showMessage(`no empty text`, LogType.Info);
      return;
    }

    let self: ChatVM = this;

    self.orchestrator.showProgress(true);

    if (this.discussion().id() === "0") {

      // this is a new discussion
      let result: Discussion | string = await this.orchestrator.discussionsCollection.newDiscussionAsync(self.opponentName());

      if (result instanceof Discussion) {
        self.discussion(result);
        self._addChatAsync();
        return;
      }

      self.orchestrator.showMessage(`Failed to create a discussion. ${result}`, LogType.Error, Activity.Text);
      return;
    }

    self._addChatAsync();
  }
}
