
export enum PieceType {
  Pawn = 1,
  Rook = 2,
  Knight = 3,
  Bishop = 4,
  Queen = 5,
  King = 6
}

export enum Color {
  White = 1,
  Black = 2
}


export class Piece {
  type: PieceType;
  color: Color;

  constructor(type: PieceType, color: Color) {
    this.type = type;
    this.color = color;
  }

  toNotation(): string {
    switch (this.type) {
      case PieceType.Pawn:
        return "P";
      case PieceType.Rook:
        return "R";
      case PieceType.Bishop:
        return "B";
      case PieceType.Knight:
        return "N";
      case PieceType.Queen:
        return "Q";
      case PieceType.King:
        return "K";
      default:
        throw "unknown piece type";
    }
  }

  equals(piece: Piece): boolean {
    return (piece !== null && this.type === piece.type && this.color === piece.color);
  }

  static fromNotation(notation: string, color: Color): Piece {

    let type: PieceType;

    switch (notation[0]) {
      case "P":
        type = PieceType.Pawn;
        break;
      case "R":
        type = PieceType.Rook;
        break;
      case "N":
        type = PieceType.Knight;
        break;
      case "B":
        type = PieceType.Bishop;
        break;
      case "Q":
        type = PieceType.Queen;
        break;
      case "K":
        type = PieceType.King;
        break;
      default:
        throw "unknown piece notation";
    }

    return new Piece(type, color);
  }

  static getMask(piece: Piece): number {
    if (piece === null) {
      return 0;
    }

    let mask: number = piece.type;

    if (piece.color === Color.Black) {
      mask += 8;
    }

    return mask;
  }

  static WhitePawn: Piece = new Piece(PieceType.Pawn, Color.White);
  static BlackPawn: Piece = new Piece(PieceType.Pawn, Color.Black);

  static WhiteRook: Piece = new Piece(PieceType.Rook, Color.White);
  static BlackRook: Piece = new Piece(PieceType.Rook, Color.Black);

  static WhiteKnight: Piece = new Piece(PieceType.Knight, Color.White);
  static BlackKnight: Piece = new Piece(PieceType.Knight, Color.Black);

  static WhiteBishop: Piece = new Piece(PieceType.Bishop, Color.White);
  static BlackBishop: Piece = new Piece(PieceType.Bishop, Color.Black);

  static WhiteQueen: Piece = new Piece(PieceType.Queen, Color.White);
  static BlackQueen: Piece = new Piece(PieceType.Queen, Color.Black);

  static WhiteKing: Piece = new Piece(PieceType.King, Color.White);
  static BlackKing: Piece = new Piece(PieceType.King, Color.Black);

  static NotationEmpty: string = "E";
}
