
export class Coord {

  r: number;
  c: number;

  constructor(r: number, c: number) {
    this.r = r;
    this.c = c;
  }

  public toNotation(): string {

    let vCol: number = 97 + this.c;
    let vRow: number = 48 + (8 - this.r);

    return String.fromCharCode(vCol) + String.fromCharCode(vRow);
  }

  public toNotationColumn(): string {
    let vCol: number = 97 + this.c;

    return String.fromCharCode(vCol);
  }

  public equals(coord: Coord): boolean {
    return (coord !== null && this.r === coord.r && this.c === coord.c);
  }

  static a1: Coord = new Coord(7, 0);
  static c1: Coord = new Coord(7, 2);
  static d1: Coord = new Coord(7, 3);
  static e1: Coord = new Coord(7, 4);
  static f1: Coord = new Coord(7, 5);
  static g1: Coord = new Coord(7, 6);
  static h1: Coord = new Coord(7, 7);

  static a8: Coord = new Coord(0, 0);
  static c8: Coord = new Coord(0, 2);
  static d8: Coord = new Coord(0, 3);
  static e8: Coord = new Coord(0, 4);
  static f8: Coord = new Coord(0, 5);
  static g8: Coord = new Coord(0, 6);
  static h8: Coord = new Coord(0, 7);

  static CoordN: Coord = new Coord(-1, 0);
  static CoordNW: Coord = new Coord(-1, -1);
  static CoordNE: Coord = new Coord(-1, 1);
  static CoordW: Coord = new Coord(0, -1);
  static CoordE: Coord = new Coord(0, 1);
  static CoordS: Coord = new Coord(1, 0);
  static CoordSW: Coord = new Coord(1, -1);
  static CoordSE: Coord = new Coord(1, 1);

  public static fromNotation(notation: string): Coord {

    let ccR: number = notation.charCodeAt(1);
    let ccC: number = notation.charCodeAt(0);

    let r: number = 8 - (ccR - 48);
    let c: number = ccC - 97;

    return new Coord(r, c);
  }

  public static equalCoords(coord1: Coord, coord2: Coord): boolean {
    if (coord1 === null && coord2 === null) {
      return true;
    }

    if (coord1 === null || coord2 === null) {
      return false;
    }

    return coord1.equals(coord2);
  }
}
