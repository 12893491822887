import { Orchestrator } from "./orchestrator";


export class HelpTopic {
  public id: number;
  public page: string;
  public message: string;
  public displayed: boolean;
  public byRequestOnly: boolean;
  public location: number;

  public static Nowhere: number = 0;
  public static Top: number = 1;
  public static Bottom: number = 2;
  public static Center: number = 3;

  constructor(id: number, page: string, message: string, byRequestOnly: boolean, location: number) {
    this.id = id;
    this.page = page;
    this.message = message;
    this.displayed = false;
    this.byRequestOnly = byRequestOnly;
    this.location = location;
  }
}

export interface HelpTopicLocation {
  message: string;
  location: number;
}

export class Help {

  topics: Array<HelpTopic>;

  viewedTopicIds: Array<number>;

  constructor() {
    this.topics = new Array<HelpTopic>();
    this.viewedTopicIds = new Array<number>();

    this.topics.push(new HelpTopic(1, Orchestrator.Page_Games,
      "You can start a new game by tapping on the + icon below", false, HelpTopic.Bottom));

    this.topics.push(new HelpTopic(2, Orchestrator.Page_Games,
      "You can refresh the games and log out by tapping on the ... below", false, HelpTopic.Bottom));

    this.topics.push(new HelpTopic(3, Orchestrator.Page_Game,
      "More commands available by expanding the menu below", false, HelpTopic.Bottom));

    this.topics.push(new HelpTopic(4, Orchestrator.Page_Game,
      "You can send a message to your opponent by tapping on the text icon below", false, HelpTopic.Bottom));

    this.topics.push(new HelpTopic(5, Orchestrator.Page_Game,
      "Tap on the player above to see stats against him/her", false, HelpTopic.Top));

    this.topics.push(new HelpTopic(6, Orchestrator.Page_Game,
      "You can ask for a rematch by expanding the list of commands below", true, HelpTopic.Bottom));

    this.topics.push(new HelpTopic(7, Orchestrator.Page_Game,
      "You received a text from this player. Tap on the text icon below to see it", true, HelpTopic.Bottom));

    this.topics.push(new HelpTopic(8, Orchestrator.Page_Games,
      "You can change how big or small things are in the Settings page", false, HelpTopic.Bottom));

    this.topics.push(new HelpTopic(9, Orchestrator.Page_Games,
      "You can mark completed games as favorites and they will show in the favorites page", false, HelpTopic.Bottom));

    this.topics.push(new HelpTopic(10, Orchestrator.Page_Chat,
      "You can copy messages to clipboard by tapping on them", false, HelpTopic.Bottom));

    this.loadViewedTopics();
  }

  private topicById(id: number): HelpTopic {
    for (let i: number = 0; i < this.topics.length; i++) {
      let topic: HelpTopic = this.topics[i];

      if (topic.id === id) {
        return topic;
      }
    }
    return null;
  }

  private loadViewedTopics(): void {
    let viewedTopicsString: string = window.localStorage.getItem("viewedHelpTopics");

    if (viewedTopicsString === null) {
      return;
    }

    try {
      this.viewedTopicIds = JSON.parse(viewedTopicsString);
    } catch (e) {
      return;
    }

    for (let i: number = 0; i < this.viewedTopicIds.length; i++) {

      let id: number = this.viewedTopicIds[i];

      let topic: HelpTopic = this.topicById(id);

      if (topic !== null) {
        topic.displayed = true;
      }
    }
  }

  private saveViewedTopics(): void {
    if (this.viewedTopicIds.length === 0) {
      window.localStorage.removeItem("viewedHelpTopics");
      return;
    }
    let viewedTopicsString: string = JSON.stringify(this.viewedTopicIds);

    window.localStorage.setItem("viewedHelpTopics", viewedTopicsString);
  }

  public getNextTopicForPage(page: string): HelpTopicLocation {

    for (let i: number = 0; i < this.topics.length; i++) {
      let topic: HelpTopic = this.topics[i];

      if (topic.page === page && !topic.displayed && !topic.byRequestOnly) {
        topic.displayed = true;
        this.viewedTopicIds.push(topic.id);
        this.saveViewedTopics();

        let helpLoc: HelpTopicLocation = {
          message: topic.message,
          location: topic.location
        };

        return helpLoc;
      }
    }
    return {
      message: "",
      location: HelpTopic.Nowhere
    };
  }

  public getTopicById(id: number): HelpTopicLocation {

    for (let i: number = 0; i < this.topics.length; i++) {
      let topic: HelpTopic = this.topics[i];

      if (topic.id === id && !topic.displayed) {
        topic.displayed = true;
        this.viewedTopicIds.push(topic.id);
        this.saveViewedTopics();

        let helpLoc: HelpTopicLocation = {
          message: topic.message,
          location: topic.location
        };

        return helpLoc;
      }
    }

    return {
      message: "",
      location: HelpTopic.Nowhere
    };
  }

  public reset(): void {
    for (let i: number = 0; i < this.topics.length; i++) {
      let topic: HelpTopic = this.topics[i];

      topic.displayed = false;
    }
    this.viewedTopicIds = new Array<number>();
    this.saveViewedTopics();
  }
}
