import { Activity, LogType } from "./logViewModel";
import { Move } from "./move";
import { Openings } from "./openings";
import { Orchestrator } from "./orchestrator";

import * as ko from 'knockout';
import {
  Observable, ObservableArray, PureComputed
} from 'knockout';


export interface IAnalysisNotification {
  gameId: string;
  userName1: string;
  userName2: string;
}

export interface IGameNotification {
  game: IGame;
  by: string;
  byElo: number;
  otherElo: number;
  move: string;
  info: string;
}

export enum MoveNotificationStatus {
  Current,
  Old,
  GameNotInSync
}

export enum Completed {
  NotCompleted = 0,
  Draw = 1,
  WhiteMated = 2, // white has been mated
  BlackMated = 3, // black has been mated
  WhiteResigned = 4,
  BlackResigned = 5,
  WhiteDroppedOut = 6,
  BlackDroppedOut = 7,
  ChallengeRejected = 8,
  Abandoned = 9 // if the moves count is even => Black abandoned. Otherwise, White abandoned
}

export enum ChallengeType {
  NoChallenge = 0,
  WhiteChallenged = 1,
  BlackChallenged = 2
}

export interface IGame {
  id: number;
  White: string;
  Black: string;
  EloWhite: number;
  EloBlack: number;
  EloDiff: number;
  Moves: string;
  MovesCount: number;
  Rated: boolean;
  SimilarOpponent: boolean;
  Challenge: ChallengeType;
  Completed: Completed;
  DrawOffered: boolean;
  DrawOfferRejected: boolean;
  Flags: number;
  Version: number;
  createdAt: Date;
  updatedAt: Date;
}

export enum CloudCall {
  None,
  Move,
  TakeBack,
  OfferDraw,
  Resign,
  Abandon,
  AskRematch,
  AcceptDraw,
  RejectDraw,
  RejectChallenge
}

export interface IGameInfo {
  alert: boolean;
  info: string;
}

export class Game implements IGame {

  public id: number;
  public White: string;
  public Black: string;
  public EloWhite: number;
  public EloBlack: number;
  public EloDiff: number;
  public Moves: string;
  public MovesCount: number;
  public Rated: boolean;
  public SimilarOpponent: boolean;
  public Challenge: ChallengeType;
  public Completed: Completed;
  public DrawOffered: boolean;
  public DrawOfferRejected: boolean;
  public Flags: number;
  public Version: number;
  public createdAt: Date;
  public updatedAt: Date;

  public cloudCall: CloudCall;

  private _commitedMoves: Array<Move>;
  private _extraMoves: Array<Move>;
  private _extraMovesPosition: number;

  public static CurrentUser: string;

  public watchedGame: boolean;

  public inSyncWithChessLogic: boolean;

  public opponent: Observable<string>;

  public favorite: Observable<boolean>;

  public hasAnalysis: Observable<boolean>;

  public currentPlayerIsWhite: boolean;

  public static orchestrator: Orchestrator;

  public static EMPTY_OPPONENT = "'unmatched'";

  static Flag_WhiteFavorite: number = 1;
  static Flag_BlackFavorite: number = 2;
  static Flag_Analyzed: number = 4;

  constructor(g: IGame) {
    this.cloudCall = CloudCall.None;

    let self = this;

    this.watchedGame = (Game.CurrentUser !== g.White && Game.CurrentUser !== g.Black);

    // TODO: should we set opponent for watched games?
    this.opponent = ko.observable(this.watchedGame ? 'NONE' : (this.White === Game.CurrentUser ? (this.Black === "" ? Game.EMPTY_OPPONENT : this.Black) : this.White));

    this.copy(g, true);

    this.favorite = ko.observable(!this.watchedGame && ((this.White === Game.CurrentUser && (this.Flags & Game.Flag_WhiteFavorite) !== 0) || (this.Black === Game.CurrentUser && (this.Flags & Game.Flag_BlackFavorite) !== 0)));
    this.hasAnalysis = ko.observable((this.Flags & Game.Flag_Analyzed) !== 0);

    this.favorite.subscribe(function (newValue: boolean): void {
      if (newValue === true) {
        if (self.White === Game.CurrentUser) {
          self.Flags |= Game.Flag_WhiteFavorite;
        } else {
          self.Flags |= Game.Flag_BlackFavorite;
        }
      } else {
        if (self.White === Game.CurrentUser) {
          self.Flags &= ~Game.Flag_WhiteFavorite;
        } else {
          self.Flags &= ~Game.Flag_BlackFavorite;
        }
      }
    });

    this.currentPlayerIsWhite = (Game.CurrentUser === this.White);
  }

  public static IGameFromGame(g: Game): IGame {
    let ig: IGame = {
      id: g.id,
      White: g.White,
      Black: g.Black,
      EloWhite: g.EloWhite,
      EloBlack: g.EloBlack,
      EloDiff: g.EloDiff,
      Moves: g.Moves,
      MovesCount: g.MovesCount,
      Rated: g.Rated,
      SimilarOpponent: g.SimilarOpponent,
      Challenge: g.Challenge,
      Completed: g.Completed,
      DrawOffered: g.DrawOffered,
      DrawOfferRejected: g.DrawOfferRejected,
      Flags: g.Flags,
      Version: g.Version,
      createdAt: g.createdAt,
      updatedAt: g.updatedAt
    };
    return ig;
  }

  public getObjectToDump(): any {

    let obj: any = {
      id: this.id,
      inSyncWithChessLogic: this.inSyncWithChessLogic,
      White: this.White,
      Black: this.Black,
      Moves: this.Moves,
      MovesCount: this.MovesCount,
      _commitedMoves: this._commitedMoves,
      _extraMoves: this._extraMoves,
      _extraMovesPosition: this._extraMovesPosition,
      Completed: this.Completed,
      updatedAt: this.updatedAt,
      DrawOffered: this.DrawOffered,
      DrawOfferRejected: this.DrawOfferRejected,
      Flags: this.Flags,
      CloudCall: this.cloudCall,
      watchedGame: this.watchedGame
    };

    return obj;
  }

  public copy(g: IGame, fromConstructor: boolean): boolean {

    if (!fromConstructor) {
      let change: string = "Game id: " + this.id + "; ";
      let differences: boolean = false;

      if (this.Version !== g.Version) {
        change += "Version: this: " + this.Version + ", g: " + g.Version + "; ";
        differences = true;
      }
      if (this.White !== g.White) {
        change += "White: this: " + this.White + ", g: " + g.White + "; ";
        differences = true;
      }
      if (this.Black !== g.Black) {
        change += "Black: this: " + this.Black + ", g: " + g.Black + "; ";
        differences = true;
      }
      if (this.EloWhite !== g.EloWhite) {
        change += "EloWhite: this: " + this.EloWhite + ", g: " + g.EloWhite + "; ";
        differences = true;
      }
      if (this.EloBlack !== g.EloBlack) {
        change += "EloBlack: this: " + this.EloBlack + ", g: " + g.EloBlack + "; ";
        differences = true;
      }
      if (this.Moves !== g.Moves) {
        change += "Moves: this: " + this.Moves + ", g: " + g.Moves + "; ";
        differences = true;
      }
      if (this.MovesCount !== g.MovesCount) {
        change += "MovesCount: this: " + this.MovesCount + ", g: " + g.MovesCount + "; ";
        differences = true;
      }
      if (this.Rated !== g.Rated) {
        change += "Rated: this: " + this.Rated + ", g: " + g.Rated + "; ";
        differences = true;
      }
      if (this.SimilarOpponent !== g.SimilarOpponent) {
        change += "SimilarOpponent: this: " + this.SimilarOpponent + ", g: " + g.SimilarOpponent + "; ";
        differences = true;
      }
      if (this.Challenge !== g.Challenge) {
        change += "SimilarOpponent: this: " + this.Challenge + ", g: " + g.Challenge + "; ";
        differences = true;
      }
      if (this.Completed !== g.Completed) {
        change += "Completed: this: " + this.Completed + ", g: " + g.Completed + "; ";
        differences = true;
      }
      if (this.DrawOffered !== g.DrawOffered) {
        change += "DrawOffered: this: " + this.DrawOffered + ", g: " + g.DrawOffered + "; ";
        differences = true;
      }
      if (this.DrawOfferRejected !== g.DrawOfferRejected) {
        change += "DrawOfferRejected: this: " + this.DrawOfferRejected + ", g: " + g.DrawOfferRejected + "; ";
        differences = true;
      }
      if (this.createdAt.getTime() !== g.createdAt.getTime()) {
        change += "createdAt: this: " + this.createdAt + ", g: " + g.createdAt + "; ";
        differences = true;
      }
      if (this.updatedAt.getTime() !== g.updatedAt.getTime()) {
        change += "updatedAt: this: " + this.updatedAt + ", g: " + g.updatedAt + "; ";
        differences = true;
      }
      if (this.Flags !== g.Flags) {
        change += "Flags: this: " + this.Flags + ", g: " + g.Flags + "; ";
        differences = true;
      }
      if (!differences) {
        return false;
      }

      if (this.Version === g.Version) {
        change += "version: " + this.Version;
        Game.orchestrator.log(LogType.Error, Activity.Game, change);
      }
      this.favorite((g.White === Game.CurrentUser && (g.Flags & Game.Flag_WhiteFavorite) !== 0) || (g.Black === Game.CurrentUser && (g.Flags & Game.Flag_BlackFavorite) !== 0));

      this.hasAnalysis((g.Flags & Game.Flag_Analyzed) !== 0);

      // fall through
    }

    this.id = g.id;
    this.White = g.White;
    this.Black = g.Black;
    this.EloWhite = g.EloWhite;
    this.EloBlack = g.EloBlack;
    this.EloDiff = g.EloDiff;
    this.Moves = g.Moves;
    this.MovesCount = g.MovesCount;
    this.Rated = g.Rated;
    this.SimilarOpponent = g.SimilarOpponent;
    this.Challenge = g.Challenge;
    this.Completed = g.Completed;
    this.DrawOffered = g.DrawOffered;
    this.DrawOfferRejected = g.DrawOfferRejected;
    this.Flags = g.Flags;
    this.Version = g.Version;
    this.createdAt = g.createdAt;
    this.updatedAt = g.updatedAt;

    this._commitedMoves = [];
    this._extraMoves = [];
    this._extraMovesPosition = -1;

    this._buildMovesCollection();

    this.inSyncWithChessLogic = false;

    this.opponent(this.White === Game.CurrentUser ? (this.Black === "" ? Game.EMPTY_OPPONENT : this.Black) : this.White);

    return true;
  }

  opponentElo(): number {
    return this.watchedGame ? 10000 : (this.currentPlayerIsWhite ? this.EloBlack : this.EloWhite);
  }

  public isWhiteToMove(): boolean {
    return ((this.MovesCount % 2) === 0);
  }

  public currentPlayerToMove(): boolean {

    if (this.watchedGame) {
      return false;
    }

    let isWhiteToMoveTemp: boolean = this.isWhiteToMove();

    let currentPlayerToMoveTemp: boolean = true;

    if (this.Black === "") {
      currentPlayerToMoveTemp = (this.MovesCount === 0);

      return currentPlayerToMoveTemp;
    }

    currentPlayerToMoveTemp = (this.Black === this.opponent()) && isWhiteToMoveTemp;

    if (currentPlayerToMoveTemp) {
      return true;
    }

    currentPlayerToMoveTemp = (this.White === this.opponent()) && !isWhiteToMoveTemp;

    return currentPlayerToMoveTemp;
  }

  public canSubmit(): boolean {
    if (this.Completed !== Completed.NotCompleted) {
      return false;
    }

    if (this.watchedGame) {
      return false;
    }

    if (this.DrawOffered) {
      return (this.currentPlayerToMove()) ? false : true;
    }

    return this.currentPlayerToMove();
  }

  private _notCompletedStatus(): string {

    switch (this.cloudCall) {
      case CloudCall.Move:
        return "submitting move ...";
      case CloudCall.Resign:
        return "resigning ...";
      case CloudCall.Abandon:
        return "abandoning ...";
      case CloudCall.OfferDraw:
        return "offering a draw ...";
      case CloudCall.TakeBack:
        return "taking back last move ...";
    }

    let status: string = "last move";

    if (this.currentPlayerToMove()) {
      if (this.DrawOffered) {
        status = "I offered a draw";
      } else if (this.currentPlayerIsWhite && this.Challenge === ChallengeType.WhiteChallenged) {
        status = "move to challenge";
      } else if (!this.currentPlayerIsWhite && this.Challenge === ChallengeType.WhiteChallenged ||
        this.currentPlayerIsWhite && this.Challenge === ChallengeType.BlackChallenged) {
        status = "reply to challenge";
      }
    } else if (this.DrawOffered) {
      if (this.watchedGame) {

        if (this.isWhiteToMove()) {
          status = "white offered a draw";
        } else {
          status = "black offered a draw";
        }
      } else {
        status = "opponent offered a draw";
      }
    } else if (!this.currentPlayerIsWhite && this.Challenge === ChallengeType.BlackChallenged ||
      this.currentPlayerIsWhite && this.Challenge === ChallengeType.WhiteChallenged) {
      status = "waiting for challenge";
    }

    let lastTimeMS: number = this.updatedAt.getTime();
    let now: Date = new Date();
    let nowMS: number = now.getTime();

    let diff: number = (nowMS - lastTimeMS) / 1000; // get the difference in seconds

    if (diff < 60) {
      status += " moments ago";
    } else if (diff < 60 * 60) {
      status += " " + Math.floor(diff / 60) + " minutes ago";
    } else if (diff < 60 * 60 * 24) {
      status += " " + Math.floor(diff / 3600) + " hours ago";
    } else {
      status += " " + Math.floor(diff / (60 * 60 * 24)) + " days ago";
    }

    return status;
  }

  private _buildMovesCollection(): void {
    if (this.Moves === null || this.Moves === "") {
      return;
    }

    let moves: string[] = this.Moves.split(",");

    for (let i: number = 0; i < moves.length; i++) {
      let m: string = moves[i];

      let move: Move = Move.moveFromNotation(i, m);

      if (this.Flags & Game.Flag_Analyzed) {
        let tokens: Array<string> = m.split(":");

        if (tokens.length > 1) {
          move.cp = parseInt(tokens[1]);
          tokens.splice(0, 2);
          if (tokens.length > 0) {
            move.bestMoves = tokens;
          }
        }
      }

      this._commitedMoves.push(move);
    }
    // Workaround for mated games
    if (this.Flags & Game.Flag_Analyzed) {
      if (this.Completed == Completed.BlackMated) {
        this._commitedMoves[this._commitedMoves.length - 1].cp = 100000;
      }
      if (this.Completed == Completed.WhiteMated) {
        this._commitedMoves[this._commitedMoves.length - 1].cp = -100000;
      }
    }
  }

  public updateGameFromCloud(g: IGame): void {

    if (this.cloudCall === CloudCall.Move) {
      // expect g to have a new move
      if (g.MovesCount !== this.MovesCount + 1) {
        throw "Sync on a new move. Expected cloud game to have " + (this.MovesCount + 1) + " moves but it has " + g.MovesCount;
      }

      if (this._extraMoves.length !== 1) {
        throw "Extra moves does not contain the move we submitted.";
      }

      this._commitedMoves.push(this._extraMoves[0]);

      this.Moves = g.Moves;
      this.MovesCount = g.MovesCount;
    } else if (this.cloudCall === CloudCall.TakeBack) {
      // do nothing here. The lat move was already removed!!!
    }

    this.Completed = g.Completed;
    this.Challenge = g.Challenge;
    this.DrawOffered = g.DrawOffered;
    this.DrawOfferRejected = g.DrawOfferRejected;
    this.EloWhite = g.EloWhite;
    this.EloBlack = g.EloBlack;
    this.EloDiff = g.EloDiff;
    this.Version = g.Version;
    this.updatedAt = g.updatedAt;

    this.cloudCall = CloudCall.None;
    this.eraseExtraMoves();
  }

  public getInfo(): IGameInfo {

    let info: string;
    let alert: boolean = false;

    info = this.getGameState();

    if (this.Completed === Completed.NotCompleted) {

      let warningTime: Date = new Date();
      let hoursBetweenMoves: number = this.Rated ? Game.orchestrator.hoursBetweenMovesRated() : Game.orchestrator.hoursBetweenMovesUnrated();

      warningTime.setHours(warningTime.getHours() - hoursBetweenMoves + Game.orchestrator.hoursBeforeExpiration());

      if (this.updatedAt < warningTime) {
        info += " - will expire soon";
        alert = true;
      }
    }

    let sd: IGameInfo = {
      info: info,
      alert: alert
    };

    return sd;
  }

  private _gameStateForWatchedGame(): string {
    let status: string;
    switch (this.Completed) {
      case Completed.NotCompleted:
        status = this._notCompletedStatus();
        break;
      case Completed.Draw:
        status = "draw";
        break;
      case Completed.Abandoned:
        status = this.isWhiteToMove() ? "black abandoned" : "white abandoned";
        break;
      case Completed.WhiteMated:
        status = "White won";
        break;
      case Completed.BlackMated:
        status = "Black won";
        break;
      case Completed.WhiteResigned:
        status = "White resigned";
        break;
      case Completed.BlackResigned:
        status = "Black resigned";
        break;
      case Completed.WhiteDroppedOut:
        status = "White dropped out";
        break;
      case Completed.BlackDroppedOut:
        status = "Black dropped out";
        break;
      case Completed.ChallengeRejected:
        status = "challenge rejected"; // identify who rejected the challenge
        break;
    }

    return status;
  }

  public getGameState(): string {
    let status: string;

    if (this.watchedGame) {
      status = this._gameStateForWatchedGame();
    } else {
      switch (this.Completed) {
        case Completed.NotCompleted:
          status = this._notCompletedStatus();
          break;
        case Completed.Draw:
          status = "draw";
          break;
        case Completed.Abandoned:
          if (this.watchedGame) {
            status = this.isWhiteToMove() ? "black abandoned" : "white abandoned";
          } else {
          }
          if (this.currentPlayerToMove()) {
            status = "opponent abandoned";
          } else {
            status = "I abandoned";
          }
          break;
        case Completed.WhiteMated:
          status = this.currentPlayerIsWhite ? "I lost" : "I won";
          break;
        case Completed.BlackMated:
          status = this.currentPlayerIsWhite ? "I won" : "I lost";
          break;
        case Completed.WhiteResigned:
          status = this.currentPlayerIsWhite ? "I resigned" : "opponent resigned";
          break;
        case Completed.BlackResigned:
          status = this.currentPlayerIsWhite ? "opponent resigned" : "I resigned";
          break;
        case Completed.WhiteDroppedOut:
          status = this.currentPlayerIsWhite ? "I dropped out" : "opponent dropped out";
          break;
        case Completed.BlackDroppedOut:
          status = this.currentPlayerIsWhite ? "opponent dropped out" : "I dropped out";
          break;
        case Completed.ChallengeRejected:
          status = "challenge rejected"; // identify who rejected the challenge
          break;
      }
    }

    if (this.Completed !== Completed.NotCompleted) {
      status += " on " + this.updatedAt.toLocaleDateString();
    }

    return status;
  }

  public totalMovesCount(): number {

    if (this._extraMovesPosition === -1) {
      return this._commitedMoves.length + this._extraMoves.length;
    }

    return this._extraMovesPosition + this._extraMoves.length;
  }

  public getMove(position: number): Move {

    if (this._extraMovesPosition === -1) {
      if (position < this._commitedMoves.length) {
        return this._commitedMoves[position];
      }

      return null;
    }

    if (position < this._extraMovesPosition) {
      return this._commitedMoves[position];
    }

    if (position < this._extraMovesPosition + this._extraMoves.length) {
      return this._extraMoves[position - this._extraMovesPosition];
    }

    throw "trying to get position " + position + ". _extraMovesPosition: " +
    this._extraMovesPosition + ". _extraMoves count: " + this._extraMoves.length + ". _commitedMoves length: " + this._commitedMoves.length;
  }

  public getKnownOpening(moveId: number, openings: Openings): string {

    let moves: string = "";

    if (moveId >= this.totalMovesCount() && moveId > 0) {
      throw "moveId larger than the moves in the collection!";
    }

    let bestMatch: string = null;
    let newMatch: string = null;

    let i: number = 0;

    for (i = 0; i <= moveId; i++) {
      if (i > 0) {
        moves += ",";
      }

      moves += this.getMove(i).toNotation();

      newMatch = openings.valueForKey(moves);

      if (newMatch !== null) {
        bestMatch = newMatch;
      }

      if (i > 14) {
        break;
      }
    }

    if (bestMatch === null) {
      bestMatch = (i > 2) ? "Unusual Opening" : "-";
    }

    return bestMatch;
  }

  public getExtraMoveStartPosition(): number {
    return this._extraMovesPosition;
  }

  public setExtraMoveStartPosition(moveId: number): void {
    this._extraMovesPosition = moveId;
  }

  public addExtraMove(move: Move, position: number): void {

    if (this._extraMovesPosition === -1) {
      this._extraMovesPosition = position;
    }

    if (position !== this._extraMovesPosition + this._extraMoves.length) {
      throw "Expected extra move at a different location!";
    }

    this._extraMoves.push(move);
  }

  public eraseExtraMoves(fromPosition?: number): void {

    if (!fromPosition) {
      fromPosition = 0;
    }

    if (this._extraMovesPosition === -1) {

      if (this._extraMoves.length !== 0) {
        throw "_extraMoves count expected to be 0. It is instead " + this._extraMoves.length;
      }

      return;
    }

    if (fromPosition === 0) {
      this._extraMoves = [];
      this._extraMovesPosition = -1;
      return;
    }

    this._extraMoves.splice(fromPosition - this._extraMovesPosition);
  }

  public getMoveNotificationStatus(notification: IGameNotification): MoveNotificationStatus {

    // special case the new game scenario since the version difference will be 2
    if (notification.game.Version === this.Version + 2 && this.Black === "") {

      // update the black player
      this.Black = notification.by;
      this.opponent(this.Black);

      return MoveNotificationStatus.Current;
    }

    if (notification.game.Version < this.Version + 1) {
      return MoveNotificationStatus.Old;
    }

    if (notification.game.Version > this.Version + 1) {
      return MoveNotificationStatus.GameNotInSync;
    }

    return MoveNotificationStatus.Current;
  }

  public updateGameFromNotification(notification: IGameNotification): void {

    this.eraseExtraMoves();

    this.updatedAt = new Date(notification.game.updatedAt.toString());
    this.Version = notification.game.Version;

    if (notification.move === "-") {
      this.MovesCount--;
      this._commitedMoves.pop();

      // erase it from Moves
      let lastMoveIndex: number = this.Moves.lastIndexOf(",");

      this.Moves = this.Moves.substring(0, lastMoveIndex);
      return;
    }

    if (notification.move === "=") {
      // handle draw offer
      this.DrawOffered = notification.game.DrawOffered;
      return;
    }

    if (notification.move !== "") {
      let newMove: Move = Move.moveFromNotation(this.MovesCount, notification.move);

      this._commitedMoves.push(newMove);
      this.Moves = this.Moves + "," + notification.move;
      this.MovesCount++;
    }

    this.DrawOffered = notification.game.DrawOffered;
    this.DrawOfferRejected = notification.game.DrawOfferRejected;
    this.Completed = notification.game.Completed;
    this.Challenge = notification.game.Challenge;
  }

  public toPNG(plainText: boolean): string {

    let gameText: string;

    gameText = '[Event "ChessM8 game"]';
    gameText += (plainText ? '\r\n' : '<br>');
    gameText += '[Site "ChessM8 servers"]';
    gameText += (plainText ? '\r\n' : '<br>');
    gameText += '[Date "' + (this.updatedAt.getMonth() + 1).toString() + "-" + this.updatedAt.getDate() + "-" + this.updatedAt.getFullYear() + '"]';
    gameText += (plainText ? '\r\n' : '<br>');
    gameText += '[White "' + this.White + '"]';
    gameText += (plainText ? '\r\n' : '<br>');
    gameText += '[Black "' + this.Black + '"]';
    gameText += (plainText ? '\r\n' : '<br>');
    gameText += '[WhiteElo ' + this.EloWhite + ']';
    gameText += (plainText ? '\r\n' : '<br>');
    gameText += '[BlackElo ' + this.EloBlack + ']';
    gameText += (plainText ? '\r\n' : '<br>');

    if (this.Completed !== Completed.NotCompleted) {
      if (this.Completed === Completed.Draw) {
        gameText += '[Result "1/2-1/2"]';
      } else if (this.Completed === Completed.WhiteDroppedOut ||
        this.Completed === Completed.WhiteMated ||
        this.Completed === Completed.WhiteResigned) {

        gameText += '[Result "0-1"]';
      } else {
        gameText += '[Result "1-0"]';
      }
      gameText += (plainText ? '\r\n' : '<br>');
    }

    gameText += (plainText ? '\r\n' : '<br>');

    for (let i: number = 0; i < this.totalMovesCount(); i++) {
      if (i % 14 === 0) {
        gameText += (plainText ? '\r\n' : '<br>');
      }
      gameText += this.getMove(i).toVisualNotation(true) + " ";
    }

    if (this.Completed !== Completed.NotCompleted) {
      if (this.Completed === Completed.Draw) {
        gameText += '1/2-1/2';
      } else if (this.Completed === Completed.WhiteDroppedOut ||
        this.Completed === Completed.WhiteMated ||
        this.Completed === Completed.WhiteResigned) {

        gameText += '0-1';
      } else {
        gameText += '1-0';
      }
      gameText += (plainText ? '\r\n' : '<br>');
    }

    return gameText;
  }

  public save(): void {

    let setting: string = `${Game.CurrentUser}-game-${this.id}`;

    let ig: IGame = Game.IGameFromGame(this);

    let gs: string = JSON.stringify(ig);

    window.localStorage.setItem(setting, gs);
  }

  public clear(): void {

    let setting: string = `${Game.CurrentUser}-game-${this.id}`;

    window.localStorage.removeItem(setting);
  }

  public static load(id: number): IGame {
    let setting: string = `${Game.CurrentUser}-game-${id}`;

    let localGame: string = window.localStorage.getItem(setting);

    let game: IGame = null;

    try {
      game = JSON.parse(localGame);

      game.createdAt = new Date(game.createdAt);
      game.updatedAt = new Date(game.updatedAt);
    } catch (e) {
      this.orchestrator.log(LogType.Error, Activity.GetGames, "load ran into an exception: " + e.message);
    }
    return game;
  }
}
