

export class Openings {

  private _Dictionary: Map<string, string>;

  constructor() {
    this._Dictionary = new Map<string, string>();

    // variations of Open game: 1.e4 e5
    this._Dictionary.set("Pe2Ee4,Pe7Ee5", "Double King's Pawn");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3", "King's Knight Opening");

    // o: Ruy Lopez
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Eb5", "Ruy Lopez");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Eb5,Bf8Ec5", "Ruy Lopez, Classical Variation");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Eb5,Ng8Ef6", "Ruy Lopez, Berlin Defense");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Eb5,Pd7Ed6", "Ruy Lopez, Steinitz Defense");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Eb5,Nc6Ed4", "Ruy Lopez, Bird Defense");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Eb5,Pf7Ef5", "Ruy Lopez, Schliemann Defense");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Eb5,Pa7Ea6", "Ruy Lopez, Morphy Defense");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Eb5,Pa7Ea6,Bb5Nc6", "Ruy Lopez, Morphy Defense, Exchange Variation");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Eb5,Pa7Ea6,Bb5Ea4", "Ruy Lopez, Morphy Closed Defense");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Eb5,Pa7Ea6,Bb5Ea4,Ng8Ef6", "Ruy Lopez, Morphy Closed Defense.");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Eb5,Pg7Eg6", "Ruy Lopez, Fianchetto Defense");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Eb5,Ng8Ee7", "Ruy Lopez, Cozio Defense");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Pd2Ed4", "Scotch Game");

    // o: The Italian Game
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Ec4", "Italian Game");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Ec4,Bf8Ee7", "Italian Game, Hungarian Defense");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Ec4,Ng8Ef6", "Italian Game, Two Knights Defense");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Ec4,Ng8Ef6,Nf3Eg5,Bf8Ec5", "Italian Game, Traxler Variation");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Ec4,Bf8Ec5", "Italian Game, Giuoco Piano");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Ec4,Bf8Ec5,Pc2Ec3", "Italian Game, Giuoco Piano, Main line");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Ec4,Bf8Ec5,Pd2Ed3", "Italian Game, Giuoco Pianissimo");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Bf1Ec4,Bf8Ec5,Pb2Eb4", "Italian Game, Gioco Piano, Evans Gambit");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Nb8Ec6,Nb1Ec3,Ng8Ef6", "Four Knights Game");

    // o: Petro'v defense
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Ng8Ef6", "Petrov's Defense");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Ng8Ef6,Nb1Ec3", "Petrov's Defense, Three Knights Game");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Ng8Ef6,Bf1Ec4", "Petrov's Defense, Italian Variation");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Ng8Ef6,Bf1Ec4,Nf6Pe4", "Petrov's Defense, Italian Variation.");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Ng8Ef6,Bf1Ec4,Nf6Pe4,Nb1Ec3", "Petrov's Defense, Italian Variation, Boden-Kieseritzky Gambit");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Ng8Ef6,Nf3Pe5", "Petrov's Defense.");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Ng8Ef6,Nf3Pe5,Pd7Ed6", "Petrov's Defense..");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Ng8Ef6,Nf3Pe5,Pd7Ed6,Ne5Ef3", "Petrov's Defense Main Line");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Ng8Ef6,Nf3Pe5,Pd7Ed6,Ne5Ec4", "Petrov's Defense Paulsen's Variation");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Ng8Ef6,Nf3Pe5,Pd7Ed6,Ne5Ed3", "Petrov's Defense Karlin's Attack");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Ng8Ef6,Nf3Pe5,Pd7Ed6,Ne5Pf7", "Petrov's Defense Cochrane Gambit");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Ng1Ef3,Pd7Ed6", "Philidor Defense");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3", "Vienna Game");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Ng8Ef6", "Vienna Game.");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Ng8Ef6,Pf2Ef4", "Vienna Gambit");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Ng8Ef6,Pf2Ef4,Pd7Ed5", "Vienna Gambit.");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Ng8Ef6,Pg2Eg3", "Vienna Gambit, Mieses Variation");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Ng8Ef6,Pa2Ea3", "Vienna Gambit, Mengarini's Opening");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Nb8Ec6", "Vienna Game.");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Nb8Ec6,Bf1Ec4", "Vienna Game..");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Nb8Ec6,Pf2Ef4", "Vienna Game, Hamppe-Muzio Gambit");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Nb8Ec6,Pf2Ef4,Pe5Pf4", "Vienna Game, Steinitz Gambit");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Nb8Ec6,Pf2Ef4,Pe5Pf4,Pd2Ed4", "Vienna Game, Steinitz Gambit.");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Nb8Ec6,Pf2Ef4,Pe5Pf4,Pd2Ed4,Qd8Eh4+", "Vienna Game, Steinitz Gambit..");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Nb8Ec6,Pf2Ef4,Pe5Pf4,Pd2Ed4,Qd8Eh4+,Ke1Ee2", "Vienna Game, Steinitz Gambit...");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Nb1Ec3,Nb8Ec6,Pg2Eg3", "Vienna Game, Paulsen Variation");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Bf1Ec4", "Bishop's Opening");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4", "King's Gambit");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pd7Ed5", "King's Gambit Declined, Falkbeer Countergambit");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pd7Ed5,Pe4Pd5", "King's Gambit Declined, Falkbeer Countergambit.");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pd7Ed5,Pe4Pd5,Pe5Ee4", "King's Gambit Declined, Falkbeer Countergambit, Old Main line");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pd7Ed5,Pe4Pd5,Pe5Ee4,Pd2Ed3", "King's Gambit Declined, Falkbeer Countergambit, Old Main line.");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pd7Ed5,Pe4Pd5,Pe5Ee4,Pd2Ed3,Ng8Ef6", "King's Gambit Declined, Falkbeer Countergambit, Old Main line..");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pd7Ed5,Pe4Pd5,Pe5Ee4,Pd2Ed3,Ng8Ef6,Pd3Pe4", "King's Gambit Declined, Falkbeer Countergambit, Old Main line...");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pd7Ed5,Pe4Pd5,Pc7Ec6", "King's Gambit Declined, Nimzovich Countergambit");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pd7Ed5,Pe4Pd5,Pc7Ec6,Nb1Ec3", "King's Gambit Declined, Nimzovich Countergambit.");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pe5Pf4", "King's Gambit Accepted");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pe5Pf4,Ng1Ef3", "King Knight's Gambit");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pe5Pf4,Ng1Ef3,Pg7Eg5", "King Knight's Gambit, Classical Variation");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pe5Pf4,Ng1Ef3,Pg7Eg5,Ph2Eh4", "King Knight's Gambit, Classical Variation, Paris Attack");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pe5Pf4,Ng1Ef3,Pg7Eg5,Ph2Eh4,Pg5Eg4", "King Knight's Gambit, Paris Attack");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pe5Pf4,Ng1Ef3,Pg7Eg5,Ph2Eh4,Pg5Eg4,Nf3Eg5", "King Knight's Gambit, Paris Attack, Allgaier Gambit");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pe5Pf4,Ng1Ef3,Pg7Eg5,Ph2Eh4,Pg5Eg4,Nf3Ee5", "King Knight's Gambit, Paris Attack, Kieseritzky Gambit");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pe5Pf4,Ng1Ef3,Pg7Eg5,Bf1Ec4", "King Knight's Gambit, Classical Variation");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pe5Pf4,Ng1Ef3,Pg7Eg5,Bf1Ec4,Pg5Eg4", "King Knight's Gambit, Classical Variation, Muzio & Salvio");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pe5Pf4,Ng1Ef3,Pg7Eg5,Bf1Ec4,Pg5Eg4,Ke1Eg1", "King Knight's Gambit, Muzio Gambit");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pf2Ef4,Pe5Pf4,Ng1Ef3,Pg7Eg5,Nb1Ec3", "King Knight's Gambit, Classical Variation, Quaade Attack");

    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pd3Ed4,Pe5Pd4,Qd1Pd4", "Center Game");
    this._Dictionary.set("Pe2Ee4,Pe7Ee5,Pd3Ed4,Pe5Pd4,Pc2Ec3", "Danish Gambit");

    // variations of Semi-open game 1.e4

    // o: Sicilian
    this._Dictionary.set("Pe2Ee4,Pc7Ec5", "Sicilian Defense");

    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Pd2Ed4,Pc5Pd4,Pc2Ec3", "Sicilian Defense, Smith-Morra Gambit");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Pc2Ec3", "Sicilian Defense, Alapin Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Nb1Ec3", "Sicilian Defense, Closed Sicilian");

    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ee2", "Sicilian Defense, Keres Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Pg2Eg3", "Sicilian Defense, Steinitz Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Pb2Eb4", "Sicilian Defense, Wing Gambit.");

    // o: The Grand Prix Attack
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Pf2Ef4", "Sicilian Defense, Grand Prix Attack");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Pf2Ef4,Pd7Ed5,Pe4Pd5,Ng8Ef6", "Sicilian Defense, Grand Prix Attack, Tal Gambit");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Nb1Ec3,Nb8Ec6,Pf2Ef4,Pg7Eg6,Ng1Ef3,Bf8Eg7", "Sicilian Defense, Grand Prix Attack, Main line");

    // o: Open Sicilian
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3", "Open Sicilian");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6", "Open Sicilian.");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4", "Open Sicilian..");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4", "Open Sicilian...");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4,Nf3Pd4", "Open Sicilian....");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4,Nf3Pd4,Ng8Ef6", "Open Sicilian.....");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4,Nf3Pd4,Ng8Ef6,Nb1Ec3", "Open Sicilian......");

    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4,Nf3Pd4,Ng8Ef6,Nb1Ec3,Pa7Ea6", "Open Sicilian, Najdorf Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4,Nf3Pd4,Ng8Ef6,Nb1Ec3,Pg7Eg6", "Open Sicilian, Dragon Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4,Nf3Pd4,Ng8Ef6,Nb1Ec3,Nb8Ec6", "Open Sicilian, Classical Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4,Nf3Pd4,Ng8Ef6,Nb1Ec3,Pe7Ee6", "Open Sicilian, Scheveningen Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4,Nf3Pd4,Ng8Ef6,Nb1Ec3,Pe7Ee5", "Open Sicilian, Sveshnikov Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4,Nf3Pd4,Ng8Ef6,Nb1Ec3,Pe7Ee5,Bf1Eb5+", "Open Sicilian, Sveshnikov Variation, Venice Attack");

    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4,Nf3Pd4,Pg7Eg6", "Open Sicilian, Accelerated Dragon Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4,Nf3Pd4,Pg7Eg6,Pc2Ec4", "Open Sicilian, Accelerated Dragon, Maroczy Bind Variation");

    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pg7Eg6", "Open Sicilian, Hyper-Accelerated Dragon Variation");

    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Pc5Pd4,Qd1Pd4", "Open Sicilian, Chekhover Variation,");

    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Ng8Ef6,Nb1Ec3,Pc5Pd4,Nf3Pd4,Pa7Ea6", "Open Sicilian, Najdorf Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Ng8Ef6,Nb1Ec3,Pc5Pd4,Nf3Pd4,Pg7Eg6", "Open Sicilian, Dragon Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Ng8Ef6,Nb1Ec3,Pc5Pd4,Nf3Pd4,Nb8Ec6", "Open Sicilian, Classical Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Ng8Ef6,Nb1Ec3,Pc5Pd4,Nf3Pd4,Pe7Ee6", "Open Sicilian, Scheveningen Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pd7Ed6,Pd2Ed4,Ng8Ef6,Nb1Ec3,Pc5Pd4,Nf3Pd4,Pe7Ee5,Bf1Eb5+", "Open Sicilian, Venice Attack Variation");

    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Nb8Ec6,Pd2Ed4", "Open Sicilian");
    this._Dictionary.set("Pe2Ee4,Pc7Ec5,Ng1Ef3,Pe7Ee6,Pd2Ed4", "Open Sicilian");

    // o: French Defense
    this._Dictionary.set("Pe2Ee4,Pe7Ee6", "French Defense");
    this._Dictionary.set("Pe2Ee4,Pe7Ee6,Pd2Ed4,Pd7Ed5,Pe4Pd5,Pe6Pd5", "French Defense, Exchange Variation");
    this._Dictionary.set("Pe2Ee4,Pe7Ee6,Pd2Ed4,Pd7Ed5,Pe4Ee5", "French Defense, Advance Variation");
    this._Dictionary.set("Pe2Ee4,Pe7Ee6,Pd2Ed4,Pd7Ed5,Nb1Ed2", "French Defense, Tarrasch Variation");

    this._Dictionary.set("Pe2Ee4,Pe7Ee6,Pd2Ed4,Pd7Ed5,Nb1Ed2,Pd5Pe4,Nd2Pe4", "French Defense, Rubinstein Variation");
    this._Dictionary.set("Pe2Ee4,Pe7Ee6,Pd2Ed4,Pd7Ed5,Nb1Ec3,Pd5Pe4,Nc3Pe4", "French Defense, Rubinstein Variation");

    this._Dictionary.set("Pe2Ee4,Pe7Ee6,Pd2Ed4,Pd7Ed5,Nb1Ec3,Ng8Ef6", "French Defense, Classical Variation");
    this._Dictionary.set("Pe2Ee4,Pe7Ee6,Pd2Ed4,Pd7Ed5,Nb1Ec3,Bf8Eb4", "French Defense, Winawer Variation");

    // o: Caro-Kann Defense
    this._Dictionary.set("Pe2Ee4,Pc7Ec6", "Caro–Kann Defense");
    this._Dictionary.set("Pe2Ee4,Pc7Ec6,Pd2Ed4,Pd7Ed5", "Caro–Kann Defense, Main Line");
    this._Dictionary.set("Pe2Ee4,Pc7Ec6,Pd2Ed4,Pd7Ed5,Pe4Pd5,Pc6Pd5", "Caro–Kann Defense, Exchange Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec6,Pd2Ed4,Pd7Ed5,Pe4Pd5,Pc6Pd5,Pc2Ec4", "Caro–Kann Defense, Exchange Variation, Panov-Botvinnik Attack");
    this._Dictionary.set("Pe2Ee4,Pc7Ec6,Pd2Ed4,Pd7Ed5,Pe4Ee5", "Caro–Kann Defense, Advance Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec6,Pd2Ed4,Pd7Ed5,Nb1Ec3,Pd5Pe4,Nc3Pe4", "Caro–Kann Defense, Classical Variation");
    this._Dictionary.set("Pe2Ee4,Pc7Ec6,Pd2Ed4,Pd7Ed5,Nb1Ed2,Pd5Pe4,Nd2Pe4", "Caro–Kann Defense, Classical Variation");

    this._Dictionary.set("Pe2Ee4,Pd7Ed6", "Pirc Defense");
    this._Dictionary.set("Pe2Ee4,Pd7Ed6,Pd2Ed4,Ng8Ef6,Nb1Ec3,Pg7Eg6", "Pirc Defense, Classical Variation");

    this._Dictionary.set("Pe2Ee4,Ng8Ef6", "Alekhine's Defense");
    this._Dictionary.set("Pe2Ee4,Pg8Eg6", "Modern Defense");

    // o: Scandinavian Defense
    this._Dictionary.set("Pe2Ee4,Pd7Ed5", "Scandinavian Defense");
    this._Dictionary.set("Pe2Ee4,Pd7Ed5,Pe4Pd5", "Scandinavian Defense.");

    this._Dictionary.set("Pe2Ee4,Pd7Ed5,Pe4Pd5,Qd8Pd5", "Scandinavian Defense..");
    this._Dictionary.set("Pe2Ee4,Pd7Ed5,Pe4Pd5,Qd8Pd5,Nb1Ec3", "Scandinavian Defense...");
    this._Dictionary.set("Pe2Ee4,Pd7Ed5,Pe4Pd5,Qd8Pd5,Nb1Ec3,Qd5Ea5", "Scandinavian Defense Classical Variation");
    this._Dictionary.set("Pe2Ee4,Pd7Ed5,Pe4Pd5,Qd8Pd5,Nb1Ec3,Qd5Ea5,Pd2Ed4", "Scandinavian Defense Classical Variation.");

    this._Dictionary.set("Pe2Ee4,Pd7Ed5,Pe4Pd5,Qd8Pd5,Nb1Ec3,Qd5Ed6", "Scandinavian Defense Pytel Variation");
    this._Dictionary.set("Pe2Ee4,Pd7Ed5,Pe4Pd5,Qd8Pd5,Nb1Ec3,Qd5Ee5+", "Scandinavian Defense Patzer Variation");

    this._Dictionary.set("Pe2Ee4,Pd7Ed5,Pe4Pd5,Ng8Ef6", "Scandinavian Defense Marshall Gambit");
    this._Dictionary.set("Pe2Ee4,Pd7Ed5,Pe4Pd5,Ng8Ef6,Pd2Ed4", "Scandinavian Defense Marshall Gambit Modern Variation");
    this._Dictionary.set("Pe2Ee4,Pd7Ed5,Pe4Pd5,Ng8Ef6,Pd2Ed4,Nf6Pd5", "Scandinavian Defense Marshall Gambit Modern Variation.");
    this._Dictionary.set("Pe2Ee4,Pd7Ed5,Pe4Pd5,Ng8Ef6,Pd2Ed4,Nf6Pd5,Pc2Ec4", "Scandinavian Defense Marshall Gambit Modern Variation..");

    this._Dictionary.set("Pd2Ed4", "Queen Pawn Opening");

    // o: Variations of Closed game 1.d4 d5
    this._Dictionary.set("Pd2Ed4,Pd7Ed5", "Double Queen's Pawn");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Pc2Ec4", "Queen's Gambit");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Pc2Ec4,Pd5Pc4", "Queen's Gambit Accepted");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Pc2Ec4,Pe7Ee6", "Queen's Gambit Declined");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Pc2Ec4,Pc7Ec6", "Slav Defense");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Pe2Ee3,Ng8Ef6,Bf1Ed3", "Stonewall Attack");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Pe2Ee3,Ng8Ef6,Bf1Ed3,Pc7Ec5,Pc2Ec3,Nb8Ec6,Pf2Ef4", "Stonewall Attack, Main Line");

    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Ng1Ef3", "Queen Pawn Game, Zukertort Variation");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Ng1Ef3,Ng8Ef6", "Queen Pawn Game, Symmetrical Variation");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Ng1Ef3,Nb8Ec6", "Queen Pawn Game, Chigorin Variation");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Ng1Ef3,Pc7Ec5", "Queen Pawn Game, Krause Variation");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Ng1Ef3,Bc8Eg4", "Queen Pawn Game, Anti-Torre");

    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Ng1Ef3,Ng8Ef6,Pe2Ee3", "Colle System");

    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Bc1Eg5", "Queen Pawn Game, Levitsky Attack");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Bc1Ef4", "Queen Pawn Game, Mason Attack");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Nb1Ec3", "Queen Pawn Game, Veresov Attack");
    this._Dictionary.set("Pd2Ed4,Pd7Ed5,Pe2Ee4", "Blackmar-Diemer Gambit");

    // o: Indian Defense Systems: 1.d4 Nf6
    this._Dictionary.set("Pd2Ed4,Ng8Ef6", "Indian Game");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4", "Indian Game, Normal Variation");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pc7Ec5", "Benoni Defense, Modern Variation");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pc7Ec5,Pd4Ed5,Pe7Ee6", "Benoni Defense, Modern Variation, Main Line");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pc7Ec5,Pd4Ed5,Pb7Eb5", "Benko/Volga Gambit");

    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pe7Ee6", "Indian Game, East Indian Defense");

    // o: Nimzo-Indian Defense
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pe7Ee6,Nb1Ec3,Bf8Eb4", "Nimzo-Indian Defense");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pe7Ee6,Nb1Ec3,Bf8Eb4,Pf2Ef3", "Nimzo-Indian Defense, Kmoch Variation");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pe7Ee6,Nb1Ec3,Bf8Eb4,Qd1Ed3", "Nimzo-Indian Defense, Mikenas Attack");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pe7Ee6,Nb1Ec3,Bf8Eb4,Pg2Eg3", "Nimzo-Indian Defense, Romanishin-Kasparov (Steiner) System");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pe7Ee6,Nb1Ec3,Bf8Eb4,Ng1Ef3", "Nimzo-Indian Defense, three Knights Variation");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pe7Ee6,Nb1Ec3,Bf8Eb4,Ng1Ef3,Pc7Ec5,Pd4Ed5", "Nimzo-Indian Defense, three Knights, Korchnoi Variation");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pe7Ee6,Nb1Ec3,Bf8Eb4,Ng1Ef3,Pc7Ec5,Pd4Ed5,Nf6Ee4", "Nimzo-Indian Defense, three Knights, Euwe Variation");

    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pe7Ee6,Ng1Ef3", "Indian Game, Anti-Nimzo-Indian");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pe7Ee6,Ng1Ef3,Pb7Eb6", "Queen's Indian Defense");

    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pe7Ee6,Pg2Eg3 ", "Catalan Opening");

    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Ng1Ef3", "Indian Game, Knights Variation");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Ng1Ef3,Pg7Eg6", "Indian Game, Pseudo-King's Indian Variation");

    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Bc1Eg5", "Trompowsky Attack");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Bc1Eg5,Nf6Ee4", "Trompowsky Attack, Modern Defense");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Bc1Eg5,Pe7Ee6", "Trompowsky Attack, Classical Defense");

    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pg2Eg3", "Indian Game, Tartakower Attack");

    // o: Grünfeld Defense
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6", "Indian Game, West Indian Defense");

    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Nb1Ec3,Pd7Ed5", "Grünfeld Defense");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Nb1Ec3,Pd7Ed5,Pc4Pd5,Nf6Pd5", "Grünfeld Defense, Exchange Variation");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Nb1Ec3,Pd7Ed5,Ng1Ef3,Bf8Eg7,Qd1Eb3", "Grünfeld Defense, Russian System");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Nb1Ec3,Pd7Ed5,Bc1Ef4,Bf8Eg7,Pe2Ee3,Ke8Eg8", "Grünfeld Gambit");

    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Pf2Ef3", "Indian Game, Anti-Grünfeld, Alekhine Variation");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Pf2Ef3,Pd7Ed5", "Neo-Grünfeld Defense, Goglidze Attack");

    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Pg2Eg3,Pd7Ed5", "Neo-Grünfeld Defense, Kemeri Variation");

    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Nb1Ec3,Pd7Ed5,Bc1Eg5", "Grünfeld Defense, Taimanov Variation");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Nb1Ec3,Pd7Ed5,Qd1Eb3", "Grünfeld Defense, Accelerated Russian System");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Nb1Ec3,Pd7Ed5,Ng1Ef3,Bf8Eg7,Qd1Ea4+", "Grünfeld Defense, Flohr Variation");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Nb1Ec3,Pd7Ed5,Ng1Ef3,Bf8Eg7,Pe2Ee3", "Grünfeld Defense, Quiet System");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Nb1Ec3,Pd7Ed5,Pc4Pd5,Nf6Pd5,Nc3Ea4", "Grünfeld Defense, Nadanian Variation");

    // o: King's Indian Defense
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Nb1Ec3,Bf8Eg7", "King's Indian Defense");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Pd4Ed5", "King's Indian Defense, Anti-Gruenfeld");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Pd4Ed5,Pb7Eb5", "King's Indian Defense, Danube Gambit");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Qd1Ec2", "King's Indian Defense, Queen's Pawn Game, Mengarini Attack");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Nb1Ec3,Bf8Eg7,Ng1Ef3,Pd7Ed6,Bc1Eg5", "King's Indian Defense, Smyslov System");
    this._Dictionary.set("Pd2Ed4,Ng8Ef6,Pc2Ec4,Pg7Eg6,Nb1Ec3,Bf8Eg7,Ng1Ef3,Pd7Ed6,Pg2Eg3", "King's Indian Defense, Fianchetto Variation");

    // o: Benoni Defense
    this._Dictionary.set("Pd2Ed4,Pc7Ec5", "Old Benoni Defense");

    // o: Dutch Defense
    this._Dictionary.set("Pd2Ed4,Pf7Ef5", "Dutch Defense");
    this._Dictionary.set("Pd2Ed4,Pf7Ef5,Pg2Eg3", "Dutch Defense, Fianchetto Attack");
    this._Dictionary.set("Pd2Ed4,Pf7Ef5,Nb1Ec3", "Dutch Defense, Raphael Variation");
    this._Dictionary.set("Pd2Ed4,Pf7Ef5,Bc1Eg5", "Dutch Defense, Hopton Attack");
    this._Dictionary.set("Pd2Ed4,Pf7Ef5,Pe2Ee4", "Dutch Defense, Staunton Gambit");
    this._Dictionary.set("Pd2Ed4,Pf7Ef5,Ph2Eh3", "Dutch Defense, Korchnoi Attack");
    this._Dictionary.set("Pd2Ed4,Pf7Ef5,Pg2Eg4", "Dutch Defense, Krejcik Gambit");

    this._Dictionary.set("Pd2Ed4,Pd7Ed6", "Rat Defense");

    this._Dictionary.set("Pd2Ed4,Pg7Eg6", "Modern Defense");

    this._Dictionary.set("Pd2Ed4,Pe7Ee6", "Horwitz Defense");

    this._Dictionary.set("Pd2Ed4,Nb8Ec6", "Mikenas Defense");

    // o: Flank openings

    // o: Nimzowitsch–Larsen Attack
    this._Dictionary.set("Pb2Eb3", "Nimzowitsch–Larsen Attack");
    this._Dictionary.set("Pb2Eb3,Pe7Ee5", "Nimzowitsch–Larsen Attack, Modern Variation");
    this._Dictionary.set("Pb2Eb3,Pd7Ed5", "Nimzowitsch–Larsen Attack, Classical Variation");
    this._Dictionary.set("Pb2Eb3,Ng8Ef6", "Nimzowitsch–Larsen Attack, Indian Variation");
    this._Dictionary.set("Pb2Eb3,Pc7Ec5", "Nimzowitsch–Larsen Attack, English Variation");
    this._Dictionary.set("Pb2Eb3,Pb7Eb6", "Nimzowitsch–Larsen Attack, Symmetrical Variation");
    this._Dictionary.set("Pb2Eb3,Pf7Ef5", "Nimzowitsch–Larsen Attack, Dutch Variation");
    this._Dictionary.set("Pb2Eb3,Pb7Eb5", "Nimzowitsch–Larsen Attack, Polish Variation");
    this._Dictionary.set("Ng1Ef3,Pd7Ed5,Pb2Eb3", "Nimzowitsch–Larsen Attack");

    // o: English Opening
    this._Dictionary.set("Pc2Ec4", "English Opening");
    this._Dictionary.set("Pc2Ec4,Ng8Ef6", "English Opening, Anglo-Indian Defense, Mikenas-Carls Variation");
    this._Dictionary.set("Pc2Ec4,Pe7Ee5", "English Opening, King's English Variation");
    this._Dictionary.set("Pc2Ec4,Pe7Ee6", "English Opening, Agincourt Defense");
    this._Dictionary.set("Pc2Ec4,Pc7Ec5", "English Opening, Symmetrical Variation");
    this._Dictionary.set("Pc2Ec4,Pg7Eg6", "English Opening, Great Snake Variation");
    this._Dictionary.set("Pc2Ec4,Pc7Ec6", "English Opening, Anglo-Slav Variation");
    this._Dictionary.set("Pc2Ec4,Pf7Ef5", "English Opening, Anglo-Dutch Defense");

    // o: Zukertort Opening
    this._Dictionary.set("Ng1Ef3", "Zukertort Opening");
    this._Dictionary.set("Ng1Ef3,Ng8Ef6", "Zukertort Opening, Symmetrical Variation");
    this._Dictionary.set("Ng1Ef3,Pd7Ed5", "Zukertort Opening, Queen Pawn Defense");
    this._Dictionary.set("Ng1Ef3,Pc7Ec5", "Zukertort Opening, Sicilian Invitation");
    this._Dictionary.set("Ng1Ef3,Pg7Eg6", "Zukertort Opening, Kingside Fianchetto");
    this._Dictionary.set("Ng1Ef3,Pf7Ef5", "Zukertort Opening, Dutch Variation");
    this._Dictionary.set("Ng1Ef3,Pd7Ed6", "Zukertort Opening, Pirc Invitation");
    this._Dictionary.set("Ng1Ef3,Pe7Ee6", "Zukertort Opening, Queen's Gambit Invitation");
    this._Dictionary.set("Ng1Ef3,Nb8Ec6", "Zukertort Opening, Black Mustang Defense");

    // o: Bird's Opening
    this._Dictionary.set("Pf2Ef4", "Bird's Opening");
    this._Dictionary.set("Pf2Ef4,Pd7Ed5", "Bird's Opening, Dutch Variation");
    this._Dictionary.set("Pf2Ef4,Pe7Ee5", "Bird's Opening, From's Gambit");
    this._Dictionary.set("Pf2Ef4,Pb7Eb5", "Bird's Opening, Polish Defense");
    this._Dictionary.set("Pf2Ef4,Pg7Eg5", "Bird's Opening, Hobbs Gambit");

    // o: Benko Opening (also called Hungarian Opening)
    this._Dictionary.set("Pg2Eg3", "Benko Opening");
    this._Dictionary.set("Pg2Eg3,Pg7Eg6", "Benko Opening, Symmetrical Variation");
    this._Dictionary.set("Pg2Eg3,Ng8Ef6", "Benko Opening, Indian Defense");
    this._Dictionary.set("Pg2Eg3,Pc7Ec5", "Benko Opening, Sicilian Invitation");

    // o: Match on empty moves
    // this._Dictionary.set("Nf3, 2.g3, 3.Bg2, 4.0-0, 5.d3, 6.Nbd2, 7.e4", "King's Indian Attack ");

    // o: Uncommon Openings
    this._Dictionary.set("Pe2Ee4,Pb7Eb6", "Owen's Defence");
    this._Dictionary.set("Pa2Ea3", "Anderssen Opening");
    this._Dictionary.set("Pa2Ea4", "Ware Opening");
    this._Dictionary.set("Pb2Eb4", "Polish Opening");
    this._Dictionary.set("Pc2Ec3", "Saragossa Opening");
    this._Dictionary.set("Pd2Ed3", "Mieses Opening");
    this._Dictionary.set("Pd2Ed3,Pe7Ee5", "Mieses Opening, Reversed Rat");
    this._Dictionary.set("Pe2Ee3", "Van't Kruijs Opening");
    this._Dictionary.set("Pf2Ef3", "Gedult's Opening");
    this._Dictionary.set("Pg2Eg4", "Grob Opening");
    this._Dictionary.set("Ph2Eh3", "Clemenz Opening");
    this._Dictionary.set("Ph2Eh4", "Kadas Opening");
    this._Dictionary.set("Nb1Ec3", "Van Geet Opening");

  }

  valueForKey(key: string): string {

    let value: string = this._Dictionary.get(key);

    if (value === undefined) {
      return null;
    }
    return value;
  }
}
