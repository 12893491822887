import { get, set, del, clear } from 'idb-keyval';

export class Storage {

  public static async get(key: string): Promise<string> {

    try {
      let res = await get<string>(key);

      return res;
    } catch (e) {
      console.error(`Trying to get ${key} failed with ${e}`);
    }

    return '';
  }

  public static async set(key: string, val: string): Promise<void> {
    let res = await set(key, val);
  }

  public static async delete(key: string): Promise<void> {
    await del(key);
  }

  public static async clear(): Promise<void> {
    await clear();
  }
}
