import { Game } from "./game";
import { Orchestrator } from "./orchestrator";

import * as ko from 'knockout';
import {
  Observable, ObservableArray, PureComputed
} from 'knockout';

export class InviteToWatchVM {
  orchestrator: Orchestrator;

  inviteType = ko.observable("player");

  showPlayerDoesNotExist = ko.observable(false);

  opponents = ko.observableArray([]);
  selectedOpponents = ko.observableArray([]);

  opponent = ko.observable("");

  playerNameHasFocus = ko.observable(false);
  opponentsHasFocus = ko.observable(false);

  private _game: Game;

  constructor(orchestrator: Orchestrator) {
    this.orchestrator = orchestrator;

    let self: InviteToWatchVM = this;

    this.playerNameHasFocus.subscribe(function (focus: any): void {
      if (focus) {
        self.inviteType("player");
      }
    });

    this.opponentsHasFocus.subscribe(function (focus: any): void {
      if (focus) {
        self.inviteType("opponent");
      }
    });
  }

  public activate(g: Game): void {
    this._game = g;

    this.opponents().length = 0;

    this.orchestrator.getOpponents().forEach(opponent => {
      if (opponent !== this._game.opponent()) {
        this.opponents.push(opponent);
      }
    });
  }

  private _invitePlayer(player: string): void {

    console.log(`Inviting player ${player} to watch game ${this._game.id} against ${this._game.opponent()}`);
  }

  public inviteToWatchClick() {

    let opponent: string;

    if (this.inviteType() === "opponent") {

      opponent = this.selectedOpponents()[0];
      this._invitePlayer(opponent);

    } else {

      opponent = this.opponent();

      this._invitePlayer(opponent);
    }
  }

  public cancelClick(): void {
  }
}
