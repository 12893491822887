import { Discussion } from "./discussions";
import { ChallengeType, CloudCall, Completed, Game, IGameNotification } from "./game";
import { GameBoard, IGameBoardCallbacks } from "./gameboard";
import { Orchestrator } from "./orchestrator";
import { Color, PieceType } from "./piece";

import * as ko from 'knockout';
import {
  Observable, ObservableArray, PureComputed
} from 'knockout';

export class GameVM {
  orchestrator: Orchestrator;

  gameId: number;

  myElo = ko.observable("");

  opponentName = ko.observable("");
  opponentElo = ko.observable("");

  ratedInfo = ko.observable("");

  discussion: Observable<Discussion>;

  showNewChat: PureComputed<boolean>;

  gameState = ko.observable("");

  // shows if at the current move there"s a check, mate, draw
  moveState = ko.observable("");

  moveNotation = ko.observable("");

  gameBoard: GameBoard = null;

  whatIfMode = ko.observable(false);
  whatIfText = ko.observable("What If");

  showWhatIf = ko.observable(false);

  showSubmit = ko.observable(false);

  showAcceptDrawOffer = ko.observable(false);

  hideAcceptDrawOffer = false;

  showRejectChallenge = ko.observable(false);

  canGoBack = ko.observable(false);
  canGoForward = ko.observable(false);

  canResign = ko.observable(false);
  canAbandon = ko.observable(false);
  canOfferDraw = ko.observable(false);
  canOfferRematch = ko.observable(false);
  canTakeBack = ko.observable(false);

  showMenu = ko.observable(false);

  opening = ko.observable("");

  currentPlayerToMove = ko.observable(true);

  canBeFavorite = ko.observable(false);
  isFavorite = ko.observable(false);

  canInviteToWatch = ko.observable(false);

  showAnalysis = ko.observable(false);

  watchedGame = ko.observable(false);

  white = ko.observable("");
  black = ko.observable("");

  constructor(orchestrator: Orchestrator, canvas: HTMLCanvasElement, analysisCanvas: HTMLCanvasElement) {
    this.orchestrator = orchestrator;

    let callbacks: IGameBoardCallbacks = {
      that: this,
      update: this._updateCallback,
      showPromotionChoices: this._showPromotionChoicesCallback
    };

    this.gameId = 0;

    this.gameBoard = new GameBoard(canvas, analysisCanvas, callbacks, this.orchestrator);

    // TODO: This may need to be changed since the event fires sometimes with a width of 0.

    window.addEventListener("resize", this.gameBoard.resizeCanvas.bind(this.gameBoard), false);

    this.discussion = ko.observable(new Discussion());

    this.showNewChat = ko.computed(function (): boolean {
      return (this.discussion().id() !== "0" && (this.discussion().outOfSync() || this.discussion().userOutOfSync()));
    }, this);

    let self: GameVM = this;

    self.whatIfMode.subscribe(function (newValue: any): void {
      self.whatIfText((newValue ? "stop What If" : "What If"));
    });
  }

  public getObjectToDump(): any {

    let obj: any = {
      gameId: this.gameId,
      opponentName: this.opponentName(),
      moveNotation: this.moveNotation(),
      whatIfMode: this.whatIfMode(),
      showWhatIf: this.showWhatIf(),
      showSubmit: this.showSubmit(),
      showAcceptDrawOffer: this.showAcceptDrawOffer(),
      hideAcceptDrawOffer: this.hideAcceptDrawOffer,
      showRejectChallenge: this.showRejectChallenge(),
      canGoBack: this.canGoBack(),
      canGoForward: this.canGoForward(),
      canResign: this.canResign(),
      canAbandon: this.canAbandon(),
      canOfferDraw: this.canOfferDraw(),
      canOfferRematch: this.canOfferRematch(),
      canTakeBack: this.canTakeBack(),
      gameBoard: this.gameBoard.getObjectToDump()
    };

    return obj;
  }

  // this function is called by the Chess control to update state as a result of a move
  private _updateCallback(): void {

    let gb: GameBoard = this.gameBoard;
    let g: Game = gb.game();

    this.opening(gb.getOpening());

    let showSubmit: boolean = gb.shouldShowSubmit();
    let currentPlayerToMove: boolean = g.currentPlayerToMove();

    this.currentPlayerToMove(currentPlayerToMove);

    this.moveNotation(gb.getMoveNotation());
    this.canGoBack(gb.canGoBack());
    this.canGoForward(gb.canGoForward());
    this.showSubmit(showSubmit);
    this.gameState(g.getGameState());
    this.moveState(gb.getMoveState());

    let canSubmit: boolean = !g.watchedGame && g.canSubmit();

    this.canResign(!g.watchedGame && canSubmit && g.MovesCount > 1);
    this.canAbandon(!g.watchedGame && g.Completed === Completed.NotCompleted && (g.Black === "" || g.MovesCount <= 1 || (!g.Rated && !canSubmit)));
    this.canOfferDraw(!g.watchedGame && canSubmit && !g.DrawOffered && !g.DrawOfferRejected && g.MovesCount > 1);
    this.canOfferRematch(!g.watchedGame && g.Completed !== Completed.NotCompleted && g.Completed !== Completed.ChallengeRejected);
    this.canTakeBack(!g.watchedGame && g.Completed === Completed.NotCompleted && !canSubmit);

    this.showWhatIf(
      !showSubmit &&
      g.cloudCall === CloudCall.None &&
      (g.Completed !== Completed.NotCompleted ||
        g.DrawOffered && currentPlayerToMove ||
        g.DrawOffered && !currentPlayerToMove && this.hideAcceptDrawOffer ||
        !g.DrawOffered));

    this.showAcceptDrawOffer(
      !g.watchedGame &&
      g.Completed === Completed.NotCompleted &&
      g.DrawOffered && !this.hideAcceptDrawOffer && !currentPlayerToMove);

    this.showRejectChallenge(
      currentPlayerToMove && g.Completed === Completed.NotCompleted &&
      (g.Challenge === ChallengeType.WhiteChallenged && !g.currentPlayerIsWhite ||
        g.Challenge === ChallengeType.BlackChallenged && g.currentPlayerIsWhite));
  }

  private _showPromotionChoicesCallback(color: Color): void {
    this.orchestrator.showPromotionChoices();
  }

  private _reset(): void {
    this.showMenu(false);

    this.gameState("");

    this.moveNotation("");

    this.whatIfMode(false);
    this.showWhatIf(false);

    this.canGoBack(false);
    this.canGoForward(false);

    this.showSubmit(false);
    this.showAcceptDrawOffer(false);
    this.hideAcceptDrawOffer = false;

    this.showRejectChallenge(false);

    this.isFavorite(false);

    this.gameId = 0;

    this.opponentElo("");
  }

  public refresh(): void {

    let g: Game = this.orchestrator.getGame(this.gameId);

    if (g.updatedAt.getTime() !== this.gameBoard.game().updatedAt.getTime() || !g.inSyncWithChessLogic) {
      this.activate(g);
    }
  }

  public activate(g: Game): void {

    this.opening("");

    this._reset();

    this.gameId = g.id;

    this.watchedGame(g.watchedGame);
    this.white(g.White);
    this.black(g.Black);

    this.canInviteToWatch(!g.watchedGame);

    this.myElo(g.currentPlayerIsWhite ? g.EloWhite.toString() : g.EloBlack.toString());

    this.opponentName(g.opponent());

    if (g.opponentElo() !== 0) {
      this.opponentElo(g.opponentElo().toString());
    }

    this.ratedInfo(g.Rated ? "rated" : "casual");

    this.canBeFavorite(!g.watchedGame && g.Completed !== Completed.NotCompleted);

    this.isFavorite(g.favorite());
    this.showAnalysis(g.hasAnalysis());

    this.gameBoard.reset(g, this.orchestrator.materialAlwaysAtBottom);

    if (g.MovesCount == 0) {
      this._updateCallback();
    }

    let disc: Discussion;

    if (g.watchedGame) {
      disc = new Discussion();
    } else {
      disc = this.orchestrator.discussionsCollection.getDiscussion(g.opponent());
    }

    this.discussion(disc);

    // WEB_ONLY
    this.orchestrator.activateChat(this.gameBoard.game());
  }

  public handleKeyInput(event: KeyboardEvent): void {

    switch (event.keyCode) {
      case 37: // left arrow
        this.backOneClick();
        break;
      case 39: // right arrow
        this.forwardOneClick();
        break;
    }
  }

  public chatClick(data: any, event: any): void {
    this.showMenu(false);
    this.orchestrator.activateChat(this.gameBoard.game());
  }

  public whatIfClick(): void {
    this.whatIfMode(!this.whatIfMode());
    this.gameBoard.setWhatIfMode(this.whatIfMode());
  }

  public updateTakeBack(): void {
    let g: Game = this.gameBoard.game();

    if (g.cloudCall === CloudCall.TakeBack) {
      this.gameBoard.updateTakeBack();
    }
  }

  public zumoCompleted(): void {

    let g: Game = this.gameBoard.game();

    if (g.currentPlayerIsWhite) {
      this.opponentElo(g.EloBlack.toString());
      this.myElo(g.EloWhite.toString());
    } else {
      this.opponentElo(g.EloWhite.toString());
      this.myElo(g.EloBlack.toString());
    }

    this._updateCallback();
  }

  private async _callZumoAsync(action: CloudCall, api: string): Promise<void> {
    let g: Game = this.gameBoard.game();

    g.cloudCall = action;

    let self: GameVM = this;

    self.gameState(g.getGameState());

    if (action === CloudCall.AskRematch) {
      self.orchestrator.askRematch(g);
    } else {
      let success: boolean = await self.orchestrator.zumoCallAsync(g, api);
      if (!success) {
        g.cloudCall = CloudCall.None;
        self._updateCallback();
      }
    }
  }

  private _askAndCallZumo(question: string, action: CloudCall, api: string): void {

    let self: GameVM = this;

    this.showMenu(false);

    // cancel WhatIf
    this.whatIfMode(false);
    this.showWhatIf(false);
    this.gameBoard.setWhatIfMode(false);

    this.orchestrator.showYesNoDlg("Confirm", question, function (yesClicked: boolean): void {

      if (!yesClicked) {
        return;
      }

      self._callZumoAsync(action, api);
    });
  }

  public submitClick(): void {

    this.showSubmit(false);

    this._callZumoAsync(CloudCall.Move, "move");
  }

  public resignClick(): void {
    this._askAndCallZumo("Are you sure you want to resign?", CloudCall.Resign, "resignAcceptDraw");
  }

  public abandonClick(): void {
    this._askAndCallZumo("Are you sure you want to abandon the game?", CloudCall.Abandon, "abandonGame");
  }

  public offerDrawClick(): void {
    this._askAndCallZumo("Are you sure you want to offer a draw?", CloudCall.OfferDraw, "offerDraw");
  }

  public takeBackClick(): void {
    this._askAndCallZumo("Do you want to attempt to take back the last move?", CloudCall.TakeBack, "takeBack");
  }

  public askRematchClick(): void {
    this._askAndCallZumo("Do you want to ask for a rematch?", CloudCall.AskRematch, "");
  }

  public acceptClick(data: any, event: any): void {
    let name: string = event.currentTarget.name;

    if (name === "later") {
      this.hideAcceptDrawOffer = true;
      this._updateCallback();
      return;
    }

    if (name === "accept") {
      this._callZumoAsync(CloudCall.AcceptDraw, "resignAcceptDraw");
    } else {
      this._callZumoAsync(CloudCall.RejectDraw, "rejectDrawOffer");
    }
  }

  public rejectChallengeClick(): void {
    this._callZumoAsync(CloudCall.RejectChallenge, "rejectChallenge");
  }

  private copyTextToClipboard(text: string): void {
    var textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a flash,
    // so some of these are just precautions. However in IE the element
    // is visible whilst the popup box asking the user for permission for
    // the web page to copy to the clipboard.
    //

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2rem';
    textArea.style.height = '2rem';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = '0';

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
  }

  public shareClick(): void {

    this.showMenu(false);

    let game: Game = this.gameBoard.game();

    let gameText: string = game.toPNG(true);

    gameText += "\r\n\r\n";

    this.copyTextToClipboard(gameText);
  }

  public favoriteClick(): void {
    this.showMenu(false);
    this.isFavorite(!this.isFavorite());

    let game: Game = this.gameBoard.game();

    this.orchestrator.markFavoriteGameAsync(game, this.isFavorite());
  }

  public inviteToWatchClick(): void {
    this.showMenu(false);

    this.orchestrator.inviteToWatch(this.gameBoard.game());
  }

  public statsVsOpponentClick(): void {
  }

  public toStartClick(): void {
    this.gameBoard.goToBeginning();
  }

  public backOneClick(): void {
    this.gameBoard.backOneMove();
  }

  public forwardOneClick(): void {
    this.gameBoard.forwardMove();
  }

  public toEndClick(): void {
    this.gameBoard.goToEnd();
  }

  public moreOptionsClick(): void {
    this.showMenu(!this.showMenu());
  }

  public promotePawn(pieceType: PieceType): void {
    this.gameBoard.transformPiece(pieceType);
  }

  public redrawBoard(): void {
    this.gameBoard.draw();
  }

  // #region Notifications

  public handleMoveNotification(notification: IGameNotification): void {

    // cancel WhatIf
    this.gameBoard.setWhatIfMode(false);
    this.whatIfMode(false);

    if (this.opponentName() === Game.EMPTY_OPPONENT) {

      // need to update the discussion as well
      let opponent: string = this.gameBoard.game().opponent();
      let disc: Discussion = this.orchestrator.discussionsCollection.getDiscussion(opponent);

      this.discussion(disc);

      this.opponentName(this.gameBoard.game().opponent());
    }

    this.opponentElo(this.gameBoard.game().opponentElo().toString());

    this.gameBoard.handleMoveNotification(notification);

    this.gameBoard.goToEnd();
  }

  public handleDrawOfferNotification(notification: IGameNotification): void {

    // cancel WhatIf
    this.gameBoard.setWhatIfMode(false);
    this.whatIfMode(false);

    this.gameBoard.goToEnd();
  }

  // #endregion Notifications
}
