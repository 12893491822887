import { ExceptionObject, Orchestrator } from "./orchestrator";
import { Storage } from "./storage";


//import { WindowsAzure } from 'azure-mobile-apps';

"use strict"

// Azure App Service Client namespace
declare let WindowsAzure: any;

const AppBackend: string = 'chessm8';

export let appServiceClient: any;

export class Application {

  orchestrator: Orchestrator;

  constructor() {

    //let ab: AzureBlob = new AzureBlob();

    appServiceClient = new WindowsAzure.MobileServiceClient(`https://${AppBackend}.azurewebsites.net`);

    this._registerWorker();
  }

  private async _registerWorker() {

    if ('serviceWorker' in navigator) {
      try {
        let reg: ServiceWorkerRegistration = await navigator.serviceWorker.register('/sw.js');

        console.log(`service worker registered. Scope: ${reg.scope}`);
      } catch (e) {
        console.log('service worker failed to register!!');
      }
    }

    this.orchestrator = new Orchestrator();
  }

  //public ActivityChanged(active: boolean): void {
  //    let d: Date = new Date();

  //    console.log("Page " + (active ? 'visible' : 'hidden') + " changed at: " + d.toLocaleTimeString());
  //}
}


let app: Application = null;

window.onload = function (): void {

  app = new Application();
};

//window.onfocus = function () {
//    if (app) {
//        app.ActivityChanged(true);
//    }
//};

//window.onblur = function () {
//    if (app) {
//        app.ActivityChanged(false);
//    }
//};

window.onerror = function (msg: string, url: string, line: number, col: number, err: any): boolean {

  let content: ExceptionObject = {
    msg: msg,
    line: line,
    col: col,
    url: url
  };

  let contentStr: string = JSON.stringify(content);

  // save the exception
  window.localStorage.setItem("exception", contentStr);

  // check out the json online at: http://jsoneditoronline.org/
  if (app && app.orchestrator) {
    window.localStorage.setItem("exceptionDump", app.orchestrator.dump());
  }

  return false;
};