import { ChessLogic, IGameCallbacks } from "./chesslogic";
import { Coord } from "./coord";
import { Game, IGameNotification } from "./game";
import { Activity, LogType } from "./logViewModel";
import { Move, MoveInfo, MoveState } from "./move";
import { Openings } from "./openings";
import { Orchestrator } from "./orchestrator";
import { Color, Piece, PieceType } from "./piece";

export interface IGameBoardCallbacks {
  that: any;
  update: () => void;
  showPromotionChoices: (color: Color) => void;
}

export interface IPoint {
  x: number;
  y: number;
}

export interface IAnalysisChartCallbacks {
  that: any;
  gotoMove: (moveId: number) => void;
}

interface IInflectionPoint {
  x: number;
  gravity: number;
}

export class AnalysisChart {
  private _callbacks: IAnalysisChartCallbacks;

  private _ctx: CanvasRenderingContext2D;
  private _canvas: HTMLCanvasElement;

  private _game: Game;

  private _width: number;
  private _height: number;

  private _selection: number;

  static MAX_CP: number = 900;
  static CHART_COLOR: string = "red";
  static INDICATOR_COLOR: string = "#909090";
  static FILL: string = "#f0f0f0";
  static BUBBLE: number = 6;
  static BUBBLE_INSIDE: string = "white";


  constructor(canvas: HTMLCanvasElement, game: Game, callbacks: IAnalysisChartCallbacks) {
    this._canvas = canvas;
    this._callbacks = callbacks;
    this._ctx = this._canvas.getContext("2d");
    this._canvas.addEventListener("click", this._handleClick.bind(this), false);

    this._game = game;
    this._selection = -1;
  }

  private _handleClick(evt: PointerEvent): void {
    let moveId: number;

    let pointCount: number = this._game.MovesCount;
    let step: number = this._width / pointCount;

    moveId = 1 + Math.floor((evt.offsetX - step / 2) / step);

    if (this._callbacks.gotoMove !== null) {
      this._callbacks.gotoMove.bind(this._callbacks.that)(moveId);
    }
  }

  public setGame(game: Game): void {
    this._game = game;
    this._selection = 0;
  }

  private _relHeightFromCP(cp: number): number {

    if (cp > AnalysisChart.MAX_CP) {
      cp = AnalysisChart.MAX_CP;
    } else if (cp < -AnalysisChart.MAX_CP) {
      cp = -AnalysisChart.MAX_CP;
    }

    let y: number = Math.LOG10E * Math.log(1 + Math.abs(cp) / 100);

    if (cp > 0) {
      return -y;
    }
    return y;
  }

  private _displayCP(i: number): void {

    let cp: number = 0;

    if (i !== -1) {
      let m: Move = this._game.getMove(i);

      cp = m.cp;
    }
    let d: string = "";

    if (cp > 0) {
      d = "+";
    }

    if (cp > 10000) {
      d += "mate";
    } else if (cp < -10000) {
      d = "-mate";
    } else {
      d += (cp / 100).toString();
    }

    this._ctx.font = "14px Arial";
    this._ctx.fillStyle = "#050505";
    this._ctx.fillText(d, 3, 12);
  }

  private _realHeightFromCP(cp: number): number {

    let y: number = this._relHeightFromCP(cp);

    return this._height / 2 + y * (this._height / 2 - AnalysisChart.BUBBLE / 2);
  }

  private _drawPoly(arr: Array<IPoint>, stroke: string, line: number): void {
    this._ctx.beginPath();
    this._ctx.strokeStyle = stroke;
    this._ctx.lineWidth = line;

    this._ctx.moveTo(arr[0].x, arr[0].y);

    let l: number = arr.length;

    for (let i: number = 1; i < l; i++) {
      this._ctx.lineTo(arr[i].x, arr[i].y);
    }

    this._ctx.stroke();
  }

  public draw(selection?: number): void {

    let halfH = this._height / 2;

    this._ctx.fillStyle = "white";
    this._ctx.fillRect(0, 0, this._width, halfH);

    this._ctx.fillStyle = "#a0a0a0";
    this._ctx.fillRect(0, halfH, this._width, this._height);

    if (this._game === null) {
      return;
    }

    if (selection !== undefined) {
      this._selection = selection;
    }

    let pointCount: number = this._game.MovesCount;

    let step: number = this._width / pointCount;

    this._ctx.lineWidth = 1;
    this._ctx.strokeStyle = AnalysisChart.INDICATOR_COLOR;

    this._ctx.beginPath();
    this._ctx.moveTo(0, halfH);
    this._ctx.lineTo(this._width, halfH);
    this._ctx.stroke();

    let curve: Array<IPoint> = [];
    let whitePoly: Array<IPoint> = [];
    let blackPoly: Array<IPoint> = [];

    let markers: Array<IInflectionPoint> = [];

    whitePoly.push({
      x: this._width,
      y: 0
    });
    whitePoly.push({
      x: 0,
      y: 0
    });
    whitePoly.push({
      x: 0,
      y: halfH
    });

    blackPoly.push({
      x: this._width,
      y: this._height
    });
    blackPoly.push({
      x: 0,
      y: this._height
    });
    blackPoly.push({
      x: 0,
      y: halfH
    });

    curve.push({
      x: 0,
      y: halfH
    });

    let y: number;
    let pY: number = halfH;

    let prevCp: number = 0;

    for (let i: number = 0; i < pointCount; i++) {

      let m: Move = this._game.getMove(i);
      let cp: number = m.cp;

      y = this._realHeightFromCP(cp);

      let x: number = (i + 1) * step;

      if (Math.abs(cp - prevCp) >= 50) {
        if (Math.abs(cp - prevCp) >= 150) {
          markers.push({
            x: x,
            gravity: 2
          });
        } else {
          markers.push({
            x: x,
            gravity: 1
          });
        }
      }

      prevCp = cp;

      curve.push({
        x: x,
        y: y
      });

      if (y === halfH) {
        whitePoly.push({
          x: x,
          y: y
        });
        blackPoly.push({
          x: x,
          y: y
        });
      } else if (y > halfH) {
        if (pY < halfH) {
          whitePoly.push({
            x: x + (halfH - pY) * step / (y - pY) - step,
            y: halfH
          });
          blackPoly.push({
            x: x + (halfH - pY) * step / (y - pY) - step,
            y: halfH
          });
        }
        blackPoly.push({
          x: x,
          y: y
        });

      } else { // y < halfH
        if (pY > halfH) {
          whitePoly.push({
            x: x + (halfH - pY) * step / (y - pY) - step,
            y: halfH
          });
          blackPoly.push({
            x: x + (halfH - pY) * step / (y - pY) - step,
            y: halfH
          });
        }
        whitePoly.push({
          x: x,
          y: y
        });
      }
      pY = y;
    }

    if (y > halfH) {
      whitePoly.push({
        x: this._width,
        y: halfH
      });
    } else if (y < halfH) {
      blackPoly.push({
        x: this._width,
        y: halfH
      });
    }
    whitePoly.push({
      x: this._width,
      y: 0
    });
    blackPoly.push({
      x: this._width,
      y: this._height
    });

    this._ctx.fillStyle = AnalysisChart.FILL;
    this._drawPoly(whitePoly, AnalysisChart.FILL, 1);
    this._ctx.fill();
    this._drawPoly(blackPoly, AnalysisChart.FILL, 1);
    this._ctx.fill();

    this._ctx.strokeStyle = "lightgrey";
    this._ctx.lineWidth = 1;
    for (let i: number = 0; i < markers.length; i++) {
      this._ctx.beginPath();

      if (markers[i].gravity == 1) {
        this._ctx.strokeStyle = "lightgrey";
      } else {
        this._ctx.strokeStyle = "orange";
      }

      this._ctx.moveTo(markers[i].x, 2);
      this._ctx.lineTo(markers[i].x, this._height - 2);
      this._ctx.stroke();
    }

    this._drawPoly(curve, AnalysisChart.CHART_COLOR, 2);

    if (this._selection === -1) {
      y = this._height / 2;
    } else {
      let m: Move = this._game.getMove(this._selection);
      let cp: number = m.cp;
      y = this._realHeightFromCP(cp);
    }

    this._ctx.strokeStyle = AnalysisChart.CHART_COLOR;
    this._ctx.beginPath();
    this._ctx.arc(2 + (this._selection + 1) * step, y, AnalysisChart.BUBBLE, 0, Math.PI * 2);
    this._ctx.fillStyle = AnalysisChart.BUBBLE_INSIDE;
    this._ctx.fill();
    this._ctx.lineWidth = 2;
    this._ctx.stroke();

    this._displayCP(this._selection);
  }

  public resize(): void {
    if (this._game === null || !this._game.hasAnalysis()) {
      return;
    }

    let width: number = document.getElementById("analysisContainer").clientWidth;
    let height: number = document.getElementById("analysis").clientHeight;

    this._width = width;
    this._height = height;

    // set the canvas" dimensions

    this._canvas.width = this._width * window.devicePixelRatio;
    this._canvas.height = this._height * window.devicePixelRatio;

    this._canvas.style.width = this._width + "px";
    this._canvas.style.height = this._height + "px";

    this._ctx.scale(window.devicePixelRatio, window.devicePixelRatio);

    this.draw();
  }
}

export class Square {
  coord: Coord;
  piece: Piece;

  state: number; // the visual state

  private _isWhite: boolean;

  // wood: "#eed3b5", "#cc713a"
  // blue: "#f0f0f2", "#0984ff"
  public static lightColor = "#f0f0f2";
  public static darkColor = "#0984ff";

  private static _fillTarget = "#ff0000";
  private static _fillChecked = "#ffc90e";
  private static _fillMated = "#ff0000";

  public static pieceSet: number;
  public static showBoardNotations: boolean = false;

  static Normal = 0;
  static Selected = 1;
  static Checked = 4;
  static Mated = 8;

  static IMAGEGAP = 3;

  static ctx: CanvasRenderingContext2D;
  static side: number;
  static whiteAtBottom: boolean;

  constructor(coord: Coord, piece: Piece) {
    this.coord = coord;
    this.piece = piece;

    this.state = Square.Normal;
    this._isWhite = ((this.coord.r % 2 + this.coord.c % 2) % 2 === 0);
  }

  public static getImage(piece: Piece): HTMLImageElement {

    let id: string = "";

    if (piece === null) {
      return <HTMLImageElement>document.getElementById("NOIMG");
    }

    let color = (piece.color === Color.White ? "W" : "B");

    id += `${piece.toNotation()}${color}v${this.pieceSet}`;

    return <HTMLImageElement>document.getElementById(id);
  }

  public setState(state: number): void {
    this.state = state;
  }

  public clearState(): void {
    this.state = Square.Normal;
  }

  public getPoint(): IPoint {

    let point: IPoint = { x: 0, y: 0 };
    let side: number = Square.side;

    if (Square.whiteAtBottom) {
      point.x = this.coord.c * side + 1;
      point.y = this.coord.r * side + 1;
    } else {
      point.x = (7 - this.coord.c) * side + 1;
      point.y = (7 - this.coord.r) * side + 1;
    }

    point.x += side / 2;
    point.y += side / 2;

    return point;
  }

  public drawTarget(): void {
    let side: number = Square.side;

    let x: number, y: number;

    if (Square.whiteAtBottom) {
      x = this.coord.c * side + 1;
      y = this.coord.r * side + 1;
    } else {
      x = (7 - this.coord.c) * side + 1;
      y = (7 - this.coord.r) * side + 1;
    }

    Square.ctx.globalAlpha = 0.6;
    Square.ctx.strokeStyle = Square._fillTarget;
    Square.ctx.beginPath();
    Square.ctx.arc(x + side / 2, y + side / 2, 4, 0, 2 * Math.PI, true);
    Square.ctx.fillStyle = "white";
    Square.ctx.fill();
    Square.ctx.lineWidth = 2;
    Square.ctx.stroke();

    // restore the globa alpha
    Square.ctx.globalAlpha = 1;
  }

  public draw(): void {
    let side: number = Square.side;

    let x: number, y: number;

    if (Square.whiteAtBottom) {
      x = this.coord.c * side + 1;
      y = this.coord.r * side + 1;
    } else {
      x = (7 - this.coord.c) * side + 1;
      y = (7 - this.coord.r) * side + 1;
    }

    let xCenter: number = x + side / 2;
    let yCenter: number = y + side / 2;

    switch (this.state) {
      case Square.Checked: {
        Square.ctx.fillStyle = Square._fillChecked;
        break;
      }
      case Square.Mated: {
        Square.ctx.fillStyle = Square._fillMated;
        break;
      }
      case Square.Selected: {
        let grd: CanvasGradient = Square.ctx.createRadialGradient(xCenter, yCenter, 5, xCenter, yCenter, side * 0.7);
        grd.addColorStop(0, this._isWhite ? Square.darkColor : Square.lightColor);
        grd.addColorStop(1, this._isWhite ? Square.lightColor : Square.darkColor);
        Square.ctx.fillStyle = grd;
        break;
      }
      case Square.Normal: {
        Square.ctx.fillStyle = this._isWhite ? Square.lightColor : Square.darkColor;
        break;
      }
    }

    Square.ctx.fillRect(x, y, side, side);

    if (Square.showBoardNotations) {

      let fontSize: number = side / 4;

      Square.ctx.fillStyle = this._isWhite ? Square.darkColor : Square.lightColor;
      Square.ctx.font = `${fontSize}px Arial`;

      let not: string;

      if (this.coord.r === 7 && Square.whiteAtBottom || this.coord.r === 0 && !Square.whiteAtBottom) {
        not = String.fromCharCode(97 + this.coord.c);
        Square.ctx.fillText(not, x + side - fontSize / 2 - Square.IMAGEGAP, y + side - fontSize / 2);
      }
      if (this.coord.c === 0 && Square.whiteAtBottom || this.coord.c === 7 && !Square.whiteAtBottom) {
        not = String.fromCharCode(48 + 8 - this.coord.r);
        Square.ctx.fillText(not, x + Square.IMAGEGAP, y + fontSize);
      }
    }

    Square.ctx.drawImage(Square.getImage(this.piece), x + Square.IMAGEGAP, y + Square.IMAGEGAP, side - 2 * Square.IMAGEGAP, side - 2 * Square.IMAGEGAP);
  }
}

export class GameBoard {
  private _canvas: HTMLCanvasElement;
  private _ctx: CanvasRenderingContext2D;

  private _aChart: AnalysisChart;

  private _callbacks: IGameBoardCallbacks;

  private _side: number = 0;

  private _game: Game = null;

  private _board: Array<Array<Square>> = [];

  private _targets: Array<Coord> = [];

  private _selected: Coord = null;
  private _origin: Coord = null;
  private _destination: Coord = null;
  private _checked: Coord = null;

  private _chessLogic: ChessLogic = null;

  private _opening: string = "";

  // save the material diff so that we can save drawing time if there"s no difference
  private _materialDiff: Array<number> = [0, 0, 0, 0, 0];

  private static _boardBgrnd = "#f0f0f0";
  private static _materialBgrnd = "#f0f0f0";

  private _openings: Openings;

  private _whatIfModeOn: boolean;

  private _materialAtBottom: boolean = true;

  private _orchestrator: Orchestrator;

  private static whiteMat: Array<string> = ["QWv", "RWv", "BWv", "NWv", "PWv"];
  private static blackMat: Array<string> = ["QBv", "RBv", "BBv", "NBv", "PBv"];

  constructor(canvas: HTMLCanvasElement, aCanvas: HTMLCanvasElement, callbacks: IGameBoardCallbacks, orchestrator: Orchestrator) {
    this._canvas = canvas;

    this._orchestrator = orchestrator;

    let aChartCallbacks: IAnalysisChartCallbacks = {
      that: this,
      gotoMove: this._aChartGotoMove
    };

    this._aChart = new AnalysisChart(aCanvas, null, aChartCallbacks);

    this._canvas.addEventListener("click", this._handleClick.bind(this), false);

    this._ctx = this._canvas.getContext("2d");

    this._callbacks = callbacks;

    this._openings = new Openings();
  }

  public getObjectToDump(): any {

    let obj: any = {
      board: this._board,
      targets: this._targets,
      selected: this._selected,
      origin: this._origin,
      destination: this._destination,
      checked: this._checked,
      materialDiff: this._materialDiff,
      game: this._game === null ? null : this._game.getObjectToDump(),
      chessLogic: this._chessLogic === null ? null : this._chessLogic.getObjectToDump()
    };

    return obj;
  }

  private _aChartGotoMove(moveId: number): void {
    if (this._whatIfModeOn) {
      return;
    }
    this._chessLogic.goToMove(moveId);
  }

  private _clearSelection(): void {
    if (this._selected !== null) {
      this._board[this._selected.r][this._selected.c].clearState();
      this._selected = null;
    }
  }

  private _clearTargets(): void {
    this._targets = [];
  }

  private _checkTargetsAndMove(square: Square): void {

    for (let i: number = 0; i < this._targets.length; i++) {
      let coord: Coord = this._targets[i];

      if (this._board[coord.r][coord.c].coord.equals(square.coord)) {

        this._chessLogic.movePiece(this._selected, square.coord, null);
        return;
      }
    }

    // remove selection and get out.
    this._clearSelection();
    this._clearTargets();
  }

  private _setSelection(square: Square): void {

    square.setState(Square.Selected);
    this._selected = square.coord;
  }

  private _drawTargets(): void {
    if (this._targets.length === 0) {
      return;
    }

    for (let i: number = 0; i < this._targets.length; i++) {

      let coord: Coord = this._targets[i];

      this._board[coord.r][coord.c].drawTarget();
    }
  }

  private _squareClicked(square: Square): void {

    if (square.coord.equals(this._selected)) {
      return;
    }

    if (square.piece === null) {
      if (this._selected !== null) {
        this._checkTargetsAndMove(square);
      }
      this.draw();

      return;
    }

    if (this._selected === null && !this._chessLogic.isMoveAllowed()) {
      // do not allow selection of pieces if the move has already been made.
      return;
    }

    if (this._selected === null) {
      // allow the selection of pieces of the same color as the player who"s turn it is!
      if (square.piece.color !== this._chessLogic.playerToMove()) {
        return;
      }

      this._targets = this._chessLogic.getTargets(square.coord);
      this._setSelection(square);

    } else {
      if (square.piece.color === this._chessLogic.playerToMove()) {
        // new selection. Clear the previous targets and highlight the new ones.
        this._clearSelection();
        this._clearTargets();

        this._targets = this._chessLogic.getTargets(square.coord);
        this._setSelection(square);

      } else {
        // need to check if the selected square is in the targets collection!
        this._checkTargetsAndMove(square);
      }
    }
    this.draw();
    this._drawTargets();
  }

  private _handleClick(evt: PointerEvent): void {

    let r: number, c: number;

    r = Math.floor((evt.offsetY - 1) / this._side);
    c = Math.floor((evt.offsetX - 1) / this._side);

    //console.log(`tap (${evt.offsetX},${evt.offsetY}) coord (${r},${c})`);

    if (r > 7 || c > 7 || r < 0 || c < 0) {
      return;
    }

    if (!Square.whiteAtBottom) {
      r = 7 - r;
      c = 7 - c;
    }

    let square: Square = this._board[r][c];

    this._squareClicked(square);
  }

  private _clearLastMove(): void {
    if (this._origin !== null) {
      // clear the visuals for the previous move.
      this._origin = null;
      this._destination = null;

      // TODO: We may need to redraw!
    }
  }

  private _clearChecked(): void {
    if (this._checked !== null) {
      this._board[this._checked.r][this._checked.c].clearState();

      this._checked = null;
    }
  }

  private _clearAnnotations(): void {
    this._clearLastMove();
    this._clearTargets();
    this._clearChecked();
    this._clearSelection();
  }

  private _setChecked(coord: Coord, mated: boolean): void {
    this._board[coord.r][coord.c].setState(mated ? Square.Mated : Square.Checked);
    this._checked = coord;
  }

  private _addBestMoveIndicator(move: string, step: number) {
    let b: string = move.substr(0, 2);
    let e: string = move.substr(2, 2);

    let begin: Coord = Coord.fromNotation(b);

    let end: Coord = Coord.fromNotation(e);

    if (begin.r < 0 || begin.r > 7 || begin.c < 0 || begin.c > 7 ||
      end.r < 0 || end.r > 7 || end.c < 0 || end.c > 7) {
      this._orchestrator.log(LogType.Error, Activity.NotationError, "Invalid move notation: " + move);
    }
    if (b !== begin.toNotation() || e !== end.toNotation()) {
      this._orchestrator.showMessage(`Move ${move} is invalid! ${begin.toNotation()} ${end.toNotation()}`, LogType.Error, Activity.NotationError);
    }
    this._addMoveIndicatorBase(begin, end, step + 1);
  }

  private _addMoveIndicatorBase(begin: Coord, end: Coord, step?: number): void {

    let org: Square = this._board[begin.r][begin.c];
    let dst: Square = this._board[end.r][end.c];

    this._ctx.beginPath();
    this._ctx.globalAlpha = 0.8;

    let pOrg: IPoint = org.getPoint();
    let pDst: IPoint = dst.getPoint();

    let dist: number = Math.sqrt((pOrg.x - pDst.x) * (pOrg.x - pDst.x) + (pOrg.y - pDst.y) * (pOrg.y - pDst.y));
    let th: number = step ? 4 : 6;

    let xDelta: number = th * (pOrg.y - pDst.y) / dist;
    let yDelta: number = th * (pDst.x - pOrg.x) / dist;

    this._ctx.moveTo(pOrg.x + xDelta / 2, pOrg.y + yDelta / 2);
    this._ctx.lineTo(pDst.x + xDelta, pDst.y + yDelta);
    this._ctx.lineTo(pDst.x + 3 * xDelta, pDst.y + 3 * yDelta);
    this._ctx.lineTo(pDst.x + 2 * th * (pDst.x - pOrg.x) / dist, pDst.y + 2 * th * (pDst.y - pOrg.y) / dist);
    this._ctx.lineTo(pDst.x - 3 * xDelta, pDst.y - 3 * yDelta);
    this._ctx.lineTo(pDst.x - xDelta, pDst.y - yDelta);
    this._ctx.lineTo(pOrg.x - xDelta / 2, pOrg.y - yDelta / 2);
    this._ctx.closePath();

    let color: string = (step ? (step % 2 === 0 ? "aqua" : "lime") : "yellow");

    this._ctx.lineWidth = 1;

    let grd: CanvasGradient = this._ctx.createLinearGradient(pOrg.x, pOrg.y, pDst.x, pDst.y);

    grd.addColorStop(0, "white");
    grd.addColorStop(1, color);

    this._ctx.strokeStyle = grd;

    this._ctx.fillStyle = grd;
    this._ctx.fill();

    this._ctx.stroke();

    this._ctx.globalAlpha = 1;

    if (step > 0) {
      this._ctx.fillStyle = "red";
      this._ctx.font = "16px Arial";
      this._ctx.fillText(step.toString(), (pOrg.x + pDst.x) / 2 - 4, (pOrg.y + pDst.y) / 2 + 4);
    }
  }

  private _markMove(move: Move): void {

    this._clearAnnotations();

    if (move === null) {
      this._origin = null;
      this._destination = null;
      return;
    }

    this._origin = move.origin;
    this._destination = move.destination;

    if (move.state !== MoveState.Check && move.state !== MoveState.Mate) {
      return;
    }

    let otherKing: Piece = (move.piece.color === Color.White ? Piece.BlackKing : Piece.WhiteKing);

    let checkedKingCoord: Coord = this._chessLogic.getKingLocation(otherKing);

    this._setChecked(checkedKingCoord, move.state === MoveState.Mate);
  }

  private _castling(origin: Coord, destination: Coord): void {
    let squareOrg: Square = this._board[origin.r][origin.c];
    let squareDst: Square = this._board[destination.r][destination.c];

    // move the piece.
    squareDst.piece = squareOrg.piece;
    squareOrg.piece = null;

    this.draw();
  }

  private _simpleMove(move: Move, reverse: boolean, animate: boolean): void {

    let moveToMark: Move = move;

    if (reverse) {
      if (move.extraInfo === MoveInfo.EnPassant || move.pieceRemoved === null) {
        this._board[move.destination.r][move.destination.c].piece = null;
      }

      if (move.pieceRemoved !== null) {
        this._board[move.destinationRemoved.r][move.destinationRemoved.c].piece = move.pieceRemoved;
      }
      this._board[move.origin.r][move.origin.c].piece = move.piece;

      // mark the previous move
      // mark the previous move
      if (move.moveId > 0) {
        moveToMark = this._game.getMove(move.moveId - 1);
      } else {
        moveToMark = null;
      }
    } else {
      this._board[move.origin.r][move.origin.c].piece = null;

      if (move.pieceRemoved !== null) {
        this._board[move.destinationRemoved.r][move.destinationRemoved.c].piece = null;
      }

      if (move.pieceAtDestination === null) {
        this._board[move.destination.r][move.destination.c].piece = move.piece;
      } else {
        this._board[move.destination.r][move.destination.c].piece = move.pieceAtDestination;
      }
    }

    this._markMove(moveToMark);

    // update the openings
    if (!reverse) {
      this._updateOpening(this._game.getKnownOpening(move.moveId, this._openings));
    } else {
      this._updateOpening(this._game.getKnownOpening(move.moveId - 1, this._openings));
    }

    this.draw();

    this._callbacks.update.bind(this._callbacks.that)();

    if (this._game.hasAnalysis() && !this._whatIfModeOn) {

      let moveId: number = move.moveId;
      if (reverse) {
        moveId--;
      }

      this._aChart.draw(moveId);
    }
  }

  private _flipTheBoard(): void {
    Square.whiteAtBottom = !Square.whiteAtBottom;

    console.log(`flipping the board. WhiteAtBottom ${Square.whiteAtBottom}`);

    this.draw();
  }

  private _drawMaterialImage(x: number, y: number, side: number, id: string, count: number, horiz: boolean): number {

    let img: HTMLImageElement = <HTMLImageElement>document.getElementById(id);

    while (count > 0) {
      this._ctx.drawImage(img, x, y, side, side);
      count--;
      if (horiz) {
        x += side;
      } else {
        y += side + 1;
      }
    }

    return (horiz ? x : y);
  }

  private _updateMaterial(diff: number[]): void {

    if (diff !== null) {
      // see if anything changed
      let changes: boolean = false;

      for (let i: number = 0; i < 5; i++) {
        if (diff[i] !== this._materialDiff[i]) {
          changes = true;
          break;
        }
      }

      if (!changes) {
        return;
      }

      this._materialDiff = diff;
    } else {
      diff = this._materialDiff;
    }

    // draw the background
    let x: number;
    let y: number;

    this._ctx.fillStyle = GameBoard._materialBgrnd;

    let pieceSet: number = Square.pieceSet;

    let side: number = this._side / 2 - 3;
    if (this._materialAtBottom) {
      x = 1;
      y = 1 + this._side * 8;
      this._ctx.fillRect(x, y, this._side * 8, this._side * 0.6);
      x = 2;
      y += 3;

      for (let i = 0; i < GameBoard.whiteMat.length; i++) {
        if (diff[i] > 0) {
          x = this._drawMaterialImage(x, y, side, `${GameBoard.whiteMat[i]}${pieceSet}`, diff[i], true);
        }
      }

      // draw the diff on black pieces
      x = 2 + this._side * 4;

      for (let i = 0; i < GameBoard.whiteMat.length; i++) {
        if (diff[i] < 0) {
          x = this._drawMaterialImage(x, y, side, `${GameBoard.blackMat[i]}${pieceSet}`, -diff[i], true);
        }
      }
    } else {
      x = 1 + this._side * 8;
      y = 1;
      this._ctx.fillRect(x, y, this._side * 0.6, this._side * 8);
      x += 3;

      y = (Square.whiteAtBottom ? 2 + this._side * 4 : 2);

      for (let i = 0; i < GameBoard.whiteMat.length; i++) {
        if (diff[i] > 0) {
          y = this._drawMaterialImage(x, y, side, `${GameBoard.whiteMat[i]}${pieceSet}`, diff[i], false);
        }
      }

      // draw the diff on black pieces
      y = (Square.whiteAtBottom ? 2 : 2 + this._side * 4);

      for (let i = 0; i < GameBoard.whiteMat.length; i++) {
        if (diff[i] < 0) {
          y = this._drawMaterialImage(x, y, side, `${GameBoard.blackMat[i]}${pieceSet}`, -diff[i], false);
        }
      }
    }
  }

  private _updateOpening(opening: string): void {
    if (opening !== null) {
      this._opening = opening;
    }
  }

  private _showTransformChoices(color: Color): void {
    this._callbacks.showPromotionChoices.bind(this._callbacks.that)(color);
  }

  public reset(game: Game, materialAlwaysAtBottom: boolean): void {
    this._game = game;

    this._materialAtBottom = materialAlwaysAtBottom; // APP: || !game.hasAnalysis();

    this._aChart.setGame(game);

    this._materialDiff = [0, 0, 0, 0, 0];
    this._opening = "";
    this._whatIfModeOn = false;

    // Erase the content of the canvas. Use a little trick...
    this._canvas.width = this._canvas.width;

    this._clearAnnotations();

    let callbacks: IGameCallbacks = {
      that: this,
      updateMaterial: null,
      castling: null,
      simpleMove: null,
      showTransformChoices: null
    };

    this._chessLogic = new ChessLogic(this._game, callbacks);

    this._chessLogic.goToEnd();

    // init the board with squares
    for (let r: number = 0; r < 8; r++) {

      this._board[r] = [];

      for (let c: number = 0; c < 8; c++) {

        let coord: Coord = new Coord(r, c);

        this._board[r][c] = new Square(coord, this._chessLogic.getPiece(coord));
      }
    }

    // hook up the callbacks
    callbacks.castling = this._castling;
    callbacks.simpleMove = this._simpleMove;
    callbacks.updateMaterial = this._updateMaterial;
    callbacks.showTransformChoices = this._showTransformChoices;

    this.resizeCanvas();

    if (game.watchedGame) {
      if (!Square.whiteAtBottom) {
        this._flipTheBoard();
      }
    } else if (!game.currentPlayerIsWhite && Square.whiteAtBottom || game.currentPlayerIsWhite && !Square.whiteAtBottom) {
      this._flipTheBoard();
    }

    this._chessLogic.backOneMove(false);
    this._chessLogic.forwardMove(true);

    if (this.game().MovesCount == 0) {
      this._opening = ".";
    }

    this._game.inSyncWithChessLogic = true;
  }

  public draw(): void {

    if (this._game === null) {
      return;
    }

    // draw the board background.
    this._ctx.fillStyle = GameBoard._boardBgrnd;
    this._ctx.fillRect(1, 1, this._canvas.width, this._canvas.height);

    // draw the squares
    for (let r: number = 0; r < 8; r++) {
      for (let c: number = 0; c < 8; c++) {
        this._board[r][c].draw();
      }
    }

    let moveId: number = this._chessLogic.currentMove();

    if (moveId > 0) {
      let move: Move = this._game.getMove(moveId - 1);

      this._addMoveIndicatorBase(move.origin, move.destination);

      if (move.bestMoves !== null) {

        for (let i = 0; i < Math.min(5, move.bestMoves.length); i++) {
          this._addBestMoveIndicator(move.bestMoves[i], i);
        }
      }
    }

    // TODO: this can be done only when the material changed.
    this._updateMaterial(null);
  }

  public resizeCanvas(): void {

    let width: number = document.getElementById("boardContainer").clientWidth;
    let height: number;

    if (width === 0) {
      return;
    }

    if (this._materialAtBottom) {
      this._side = Math.round((width - 2) / 8);
      height = 2 + 8.6 * this._side;
    } else {
      this._side = Math.round((width - 2) / 8.6);
      height = 2 + 8 * this._side;
    }

    Square.ctx = this._ctx;
    Square.side = this._side;

    let sideX: number = this._side * window.devicePixelRatio;

    if (this._side < 36) {
      Square.IMAGEGAP = 2;
    } else if (this._side < 72) {
      Square.IMAGEGAP = 3;
    } else {
      Square.IMAGEGAP = 6;
    }

    // set the canvas" dimensions

    this._canvas.width = width * window.devicePixelRatio;
    this._canvas.height = height * window.devicePixelRatio;

    this._canvas.style.width = width + "px";
    this._canvas.style.height = height + "px";

    this._ctx.scale(window.devicePixelRatio, window.devicePixelRatio);

    this.draw();

    this._aChart.resize();
  }

  setWhatIfMode(whatIfMode: boolean): void {
    if (this._chessLogic !== null) {
      this._chessLogic.setWhatIfMode(whatIfMode);
      this._callbacks.update.bind(this._callbacks.that)();
    }
    this._whatIfModeOn = whatIfMode;
  }

  game(): Game {
    return this._game;
  }

  getOpening(): string {
    return this._opening;
  }

  canGoBack(): boolean {
    return this._chessLogic.canGoBack();
  }

  canGoForward(): boolean {
    return this._chessLogic.canGoForward();
  }

  shouldShowSubmit(): boolean {
    return this._chessLogic.shouldShowSubmit();
  }

  backOneMove(): void {
    this._chessLogic.backOneMove(true);
  }

  forwardMove(): void {
    this._chessLogic.forwardMove(true);
  }

  goToBeginning(): void {
    this._chessLogic.goToBeginning();
    this.draw();

    // update now since while going to the end there are no callbacks
    this._callbacks.update.bind(this._callbacks.that)();
    this._updateOpening("");
  }

  public goToEnd(): void {
    this._chessLogic.goToEnd();

    // update now since while going to the end there are no callbacks
    this._callbacks.update.bind(this._callbacks.that)();
    this._updateOpening(this._game.getKnownOpening(this._chessLogic.currentMove() - 1, this._openings));
  }

  public getMoveLocation(): number {
    // return "" + this._chessLogic.currentMove() + "/" + this._game.totalMovesCount();

    return this._chessLogic.currentMove();
  }

  public getMoveNotation(): string {

    let moveId: number = this._chessLogic.currentMove();

    if (moveId === 0) {
      return "";
    }

    let notation: string = "";

    if (moveId % 2 === 0) {
      notation += "" + moveId / 2 + "..";
    } else {
      notation += "" + (moveId + 1) / 2 + " ";
    }

    let m: Move = this._game.getMove(moveId - 1);

    return notation + m.toVisualNotation(false);
  }

  public getMoveState(): string {

    let moveId: number = this._chessLogic.currentMove();

    if (moveId === 0) {
      return "";
    }

    let m: Move = this._game.getMove(moveId - 1);

    let state: string = "";

    switch (m.state) {
      case MoveState.Check:
        state = "check!";
        break;
      case MoveState.Draw:
        state = "draw";
        break;
      case MoveState.Mate:
        state = "mate!";
        break;
    }
    return state;
  }

  public transformPiece(pieceType: PieceType): void {
    this._chessLogic.transformPiece(pieceType);
  }

  public handleMoveNotification(notification: IGameNotification): void {
    if (notification.move === "-") {
      // handle take back notification
      this._chessLogic.takeBackLastMove();
    }

    this._game.updateGameFromNotification(notification);
  }

  public updateTakeBack(): void {

    // g.MovesCount should be exactly one less than the current game!
    this._chessLogic.takeBackLastMove();
  }
}
