import { appServiceClient } from ".";
import { Discussion, DiscussionsCollection } from "./discussions";
import { Game } from "./game";
import { Activity, LogType } from "./logViewModel";
import { Orchestrator } from "./orchestrator";
import { INewUserReceived, INewUserSent, IUserInfoReceived, Zumo, ZumoCodes } from "./zumo";

import * as ko from 'knockout';
import {
  Observable, ObservableArray, PureComputed
} from 'knockout';
import { SettingsVM } from "./settingsViewModel";

export interface IUser {
  id: string;
  UserId: string;
  UserName: string;
  AuthName: string;
  AuthEmail: string;
  CurrentElo: number;
  Wins: number;
  Draws: number;
  Losses: number;
  Active: number;
  Pro: boolean;
  Version: number;
  createdAt: Date;
  updatedAt: Date;
  Flags: number;
  AvatarID: number;
  Provider: string;
  Country: string;
}

export class LoginVM {

  orchestrator: Orchestrator;

  showChooseUserName = ko.observable(false);
  loginErrorMsg = ko.observable('');

  userName = ko.observable("");

  constructor(orchestrator: Orchestrator) {
    this.orchestrator = orchestrator;
  }

  private _loginError(message: string): void {
    this.orchestrator.log(LogType.Error, Activity.Login, `Failed to log in: ${message}`);
    this.loginErrorMsg(message);

    // show the login failed
    document.getElementById("loginError").classList.remove("hidden");
    setTimeout(() => {
      document.getElementById("loginError").classList.add("hidden");
      this.loginErrorMsg('');
    }, 4000);
  }

  private async _getUserInfo(callback?: () => void): Promise<void> {

    let self: LoginVM = this;

    let dataReceived: IUserInfoReceived;

    try {
      let response: XMLHttpRequest = await appServiceClient.invokeApi("userInfo", { method: "GET" });
      dataReceived = (<any>response).result;

      if (dataReceived.user === null) {
        // show the page again since we need to pick a user name
        self.showChooseUserName(true);
        return;
      }

      self.orchestrator.hoursBetweenMovesRated(dataReceived.hoursBetweenMovesRated);
      self.orchestrator.hoursBetweenMovesUnrated(dataReceived.hoursBetweenMovesUnrated);
      self.orchestrator.hoursBeforeExpiration(dataReceived.hoursBeforeExpiration);
      self.orchestrator.maxActiveGames(dataReceived.maxActiveGames);
      self.orchestrator.maxCompletedGames(dataReceived.maxCompletedGames);

      self.orchestrator.saveUserInfo(dataReceived.user);

      window.localStorage.setItem("hoursBetweenMovesRated", dataReceived.hoursBetweenMovesRated.toString());
      window.localStorage.setItem("hoursBetweenMovesUnrated", dataReceived.hoursBetweenMovesUnrated.toString());
      window.localStorage.setItem("hoursBeforeExpiration", dataReceived.hoursBeforeExpiration.toString());
      window.localStorage.setItem("maxActiveGames", dataReceived.maxActiveGames.toString());
      window.localStorage.setItem("maxCompletedGames", dataReceived.maxCompletedGames.toString());

      if (callback) {
        callback();
      }
    } catch (_e) {
      let e: Error = _e;
      self.orchestrator.showMessage(`Failed to get user info. Error: ${e.message}`, LogType.Error, Activity.GetUserInfo);
    }
  }

  private _hasAllValidChars(str: string): boolean {

    if (str.length === 0 || str.length > 12) {
      return false;
    }

    let i: number = 0;

    for (i = 0; i < str.length; i++) {
      if ((str[i] >= "a" && str[i] <= "z") || (str[i] >= "A" && str[i] <= "Z") ||
        (str[i] >= "0" && str[i] <= "9")) {
        continue;
      } else {
        break;
      }
    }
    return i >= str.length;
  }

  private _hideApp() {
    document.getElementById("appCanvas").classList.remove("hidden");
  }

  private _revealTheApp() {
    document.getElementById("appCanvas").classList.add("hidden");
  }

  private _switchToGames(): void {
    this._revealTheApp();
    this.orchestrator.showGames();
  }

  public activate(): void {
    // ensure the app is hidden
    this._hideApp();
  }

  public loginWithCachedCredentials(): boolean {

    let userInfoString: string = window.localStorage.getItem("userInfo");

    let user: IUser = null;

    let self: LoginVM = this;

    if (userInfoString !== null) {

      try {
        user = JSON.parse(userInfoString);
      } catch (e) {
        self.orchestrator.log(LogType.Error, Activity.LocalUser, `Failed to parse user. Error: ${e.message}`);
      }
    }

    if (user === null) {
      return false;
    }

    self.orchestrator.saveUserInfo(user);

    // load the authenticated user
    let localAuthUser: string = window.localStorage.getItem("authUser");

    if (localAuthUser !== null) {

      try {
        appServiceClient.currentUser = JSON.parse(localAuthUser);
      } catch (e) {
        self.orchestrator.log(LogType.Error, Activity.LocalAuthUser, "Failed to parse auth user. Error: " + e.message);
        return false;
      }
    }

    self.orchestrator.hoursBetweenMovesRated(JSON.parse(window.localStorage.getItem("hoursBetweenMovesRated")));
    self.orchestrator.hoursBetweenMovesUnrated(JSON.parse(window.localStorage.getItem("hoursBetweenMovesUnrated")));
    self.orchestrator.hoursBeforeExpiration(JSON.parse(window.localStorage.getItem("hoursBeforeExpiration")));
    self.orchestrator.maxActiveGames(JSON.parse(window.localStorage.getItem("maxActiveGames")));
    self.orchestrator.maxCompletedGames(JSON.parse(window.localStorage.getItem("maxCompletedGames")));

    // lazy update the userinfo
    this._getUserInfo();

    // reveal the app
    self._revealTheApp();

    return true;
  }

  public loginClick(data: any, event: any): void {

    let self: LoginVM = this;

    self.showChooseUserName(false);

    appServiceClient.login(event.currentTarget.id).then(function (): void {
      if (appServiceClient.currentUser !== null) {

        // successful login. Save the token so we can authenticate without UX next time.
        window.localStorage.setItem("authUser", JSON.stringify(appServiceClient.currentUser));

        self._getUserInfo(function (): void {
          self.showChooseUserName(false);
          self._switchToGames();
        });
      } else {
        self._loginError('Failed to login. Please try again.');
      }
    }, function (error: any): void {
      self._loginError('Failed to login. Please try again.');
    });
  }

  public async chooseUserClickAsync(data: any, event: any): Promise<void> {

    let self: LoginVM = this;

    if (!this._hasAllValidChars(this.userName())) {
      self._loginError(`The alias you selected is not valid. Please try again.`);
      return;
    }

    let dataSent: INewUserSent = { UserName: this.userName() };

    let dataReceived: INewUserReceived = <INewUserReceived>await Zumo.postCallAsync<INewUserSent>('newUser', dataSent);

    if (dataReceived.statusCode !== ZumoCodes.Success) {
      self._loginError(`Failed to register that alias. Error ${dataReceived.message}`);
      return;
    }

    if (dataReceived.user === null) {
      self._loginError(`Alias is already taken. Try another alias`);
      return;
    }

    self.orchestrator.saveUserInfo(dataReceived.user);
    self._switchToGames();
  }
}
