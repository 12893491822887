import { Countries } from "./settingsViewModel";
import { Storage } from "./storage";

import * as ko from 'knockout';
import {
  Observable, PureComputed
} from 'knockout';
import { Game } from "./game";
import { IAvatarsReceived, IUserAvatar, Zumo, ZumoCodes } from "./zumo";

export interface IUserAvatarLocal {
  UserName: string;
  AvatarID: number;
  CountryCode: string;
}

class SignalWhenEmpty {

  private _tokens: Array<string> = [];
  private _signal: () => void;
  private _name: string;

  constructor(tokens: Array<string>, name: string, signal: () => void) {
    tokens.forEach((token: string) => this._tokens.push(token));
    this._name = name;
    this._signal = signal;
  }

  public addToken(token: string) {
    this._tokens.push(token);
  }

  public getRemainingTokens(): Array<string> {
    let remainingTokens: Array<string> = [];
    this._tokens.forEach((token: string) => { remainingTokens.push(token) });

    return remainingTokens;
  }

  public removeToken(token: string) {
    let index: number = this._tokens.indexOf(token);

    if (index === -1) {
      console.error(`trying to remove a token that does not exist ${token}`);
      return;
    }
    this._tokens.splice(index, 1);
    if (this._tokens.length === 0) {
      console.log(`calling the signal for ${this._name}`);
      this._signal();
    }
  }
}

export class UserAvatar {
  public Pic: Observable<string>;
  public Flag: Observable<string>;
  public UserName: string;
  public AvatarID: number;

  public CountryCode: Observable<string>;
  public Country: PureComputed<string>;

  public static toUserAvatarLocal(ua: UserAvatar): IUserAvatarLocal {
    let ual: IUserAvatarLocal = {
      UserName: ua.UserName,
      AvatarID: ua.AvatarID,
      CountryCode: ua.CountryCode()
    };

    return ual;
  }

  public static fromIUserAvatar(uac: IUserAvatar): UserAvatar {
    let ual: IUserAvatarLocal = {
      UserName: uac.UserName,
      AvatarID: uac.AvatarID,
      CountryCode: uac.Country
    };

    let ua: UserAvatar = new UserAvatar(ual);

    return ua;
  }

  constructor(ual: IUserAvatarLocal) {
    this.UserName = ual.UserName;
    this.AvatarID = ual.AvatarID;

    // Default to the picture for the avatar
    this.Pic = ko.observable(`https://generaldepot.blob.core.windows.net/chessm8-avatars/${this.UserName}.png`);

    this.CountryCode = ko.observable(ual.CountryCode);

    let flag = ual.CountryCode === null ? 'https://flagcdn.com/64x48/un.png' : `https://flagcdn.com/64x48/${ual.CountryCode}.png`;

    this.Flag = ko.observable(flag);

    this.Country = ko.computed(function (): string {
      return Countries.GetCountryByCode(this.CountryCode());
    }, this);
  }

  public switchToLocal() {
    this.Pic(PicCache.localAvatarURL(this.AvatarID));
  }

  public updateFromCloud(uac: IUserAvatar) {
    this.AvatarID = uac.AvatarID;
    if (this.Pic().startsWith('/images/Avatars')) {
      this.Pic(PicCache.localAvatarURL(uac.AvatarID));
    }
    this.CountryCode(uac.Country);
    let flag = uac.Country === null ? 'https://flagcdn.com/64x48/un.png' : `https://flagcdn.com/64x48/${uac.Country}.png`;
    this.Flag(flag);
  }
}

export class PicCache {

  private _Avatars: Map<string, UserAvatar>;
  private static AVATARS_COUNT = 19;

  private lastUpdateAvatarTime: Date;

  constructor() {
    this._Avatars = new Map<string, UserAvatar>();
    this._updateLastAvatarTime();
    this._getAvatarsFromLocal();
  }

  public static localAvatarURL(avatarID: number): string {
    if (avatarID === -1) {
      return `/images/Avatars/avatar_0.png`
    }
    return `/images/Avatars/avatar_${avatarID}.png`;
  }

  private _saveAvatars(): void {

    let ualArray: Array<IUserAvatarLocal> = [];

    this._Avatars.forEach((ua: UserAvatar, key: string) => {
      ualArray.push(UserAvatar.toUserAvatarLocal(ua));
    });

    let storeData: string = JSON.stringify(ualArray);

    let key = `${Game.CurrentUser}-avatars`;

    console.log(`saved ${ualArray.length} avatars to local.`);

    window.localStorage.setItem(key, storeData);
  }

  private _getAvatarsFromLocal(): void {

    let key = `${Game.CurrentUser}-avatars`;
    let storeData: string = window.localStorage.getItem(key);

    if (storeData !== null) {

      try {
        let ualArray: Array<IUserAvatarLocal> = JSON.parse(storeData);

        ualArray.forEach((ual: IUserAvatarLocal) => {
          let ua: UserAvatar = new UserAvatar(ual);

          this._Avatars.set(ua.UserName, ua);
        });

        console.log(`loaded ${ualArray.length} local avatars`);

      } catch (e) {
        console.error(`loading local avatars ran into an exception: ${e}`);
      }
    } else {
      console.warn(`no local user avatars available`);
    }
  }

  private _addAvatar(uac: IUserAvatar): UserAvatar {
    let ua: UserAvatar = UserAvatar.fromIUserAvatar(uac);

    this._Avatars.set(ua.UserName, ua);

    return ua;
  }

  private _addOrUpdateAvatar(uac: IUserAvatar) {
    let ua: UserAvatar = this._Avatars.get(uac.UserName);
    if (ua) {
      ua.updateFromCloud(uac);
    } else {
      ua = this._addAvatar(uac);
    }
  }

  private _updateLastAvatarTime() {
    let lastUpdateAvatarTimeString: string = window.localStorage.getItem("lastUpdateAvatarTime");

    if (lastUpdateAvatarTimeString !== null) {
      try {
        this.lastUpdateAvatarTime = new Date(JSON.parse(lastUpdateAvatarTimeString));

      } catch (e) {
        console.log(`Failed to parse lastUpdateAvatar: ${lastUpdateAvatarTimeString}. Error: ${e}`);
        this.lastUpdateAvatarTime = new Date("1/1/1980");
      }
    } else {
      this.lastUpdateAvatarTime = new Date("1/1/1980");
    }
  }

  public getUserAvatar(userName: string): UserAvatar {
    let ua: UserAvatar = this._Avatars.get(userName);
    if (!ua) {
      let uac: IUserAvatar = {
        UserName: userName,
        AvatarID: -1,
        Country: null
      }
      ua = this._addAvatar(uac);
    }
    return ua;
  }

  public updateUserCountry(userName: string, countryCode: string) {
    let ua: UserAvatar = this.getUserAvatar(userName);

    if (!ua) {
      console.error(`avatar for user ${userName} not found!`);
      return;
    }

    ua.CountryCode(countryCode);

    this._saveAvatars();
  }

  public async updateAvatarsAsync(opponents: string): Promise<void> {

    let diff: number = Date.now() - this.lastUpdateAvatarTime.getTime();

    // should be 3 minutes (1000 * 60 * 3).
    if (diff < 1000 * 60 * 3) {
      // less than 3 minutes have passed. no need to refresh the avatars
      console.log(`Not updating the avatars since only ${diff / 1000} seconds passed (less than 3 minutes)`);
      return;
    }

    // get the avatars from the cloud.
    let dataReceived: IAvatarsReceived = await Zumo.getAvatarsApiCallAsync(opponents);
    if (dataReceived.statusCode !== ZumoCodes.Success) {
      console.log(dataReceived.message);
    }

    let userAvatars: Array<IUserAvatar> = dataReceived.userAvatars;

    console.log(`Received cloud avatar info for ${userAvatars.length} avatars.`);

    let tokensForSignal: Array<string> = [];

    userAvatars.forEach((ua: IUserAvatar) => { tokensForSignal.push(ua.UserName) });

    for (let i: number = 0; i < userAvatars.length; i++) {

      let uac: IUserAvatar = userAvatars[i];

      this._addOrUpdateAvatar(uac);
    }

    this._saveAvatars();

    this.lastUpdateAvatarTime = new Date();

    // save the last move time
    window.localStorage.setItem("lastUpdateAvatarTime", JSON.stringify(this.lastUpdateAvatarTime));
  }
}

