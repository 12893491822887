import { Coord } from "./coord";
import { Activity, LogType } from "./logViewModel";
import { Orchestrator } from "./orchestrator";
import { Color, Piece, PieceType } from "./piece";

export enum MoveState {
  Normal = 0,
  Check = 1,
  Mate = 2,
  Draw = 3
}

export enum MoveInfo {
  Normal = 0,

  EnPassant = 1,
  Ambiguous = 2,

  TransformQueen = 3,
  TransformRook = 4,
  TransformKnight = 5,
  TransformBishop = 6,

  CastleKingSide = 7,
  CastleQueenSide = 8
}

export class Move {

  moveId: number;
  origin: Coord;
  destination: Coord;
  piece: Piece;
  pieceAtDestination: Piece;
  pieceRemoved: Piece;
  destinationRemoved: Coord;

  state: MoveState;
  extraInfo: MoveInfo;

  boardMask: Array<number>;

  cp: number;
  bestMoves: Array<string>;

  public static orchestrator: Orchestrator;

  constructor(moveId: number, origin: Coord, destination: Coord, piece: Piece,
    pieceAtDestination: Piece, pieceRemoved: Piece, destinationRemoved: Coord) {

    this.moveId = moveId;
    this.origin = origin;
    this.destination = destination;
    this.piece = piece;
    this.pieceAtDestination = pieceAtDestination;
    this.pieceRemoved = pieceRemoved;
    this.destinationRemoved = destinationRemoved;

    this.state = MoveState.Normal;
    this.extraInfo = MoveInfo.Normal;

    this.boardMask = null;
    this.cp = 0;
    this.bestMoves = null;
  }

  toNotation(): string {

    // notation: Re4Qe2, Pe5Ef6e, Pe7Qf8R+, Ke1Eg1#

    let notation: string = this.piece.toNotation() + this.origin.toNotation();

    if (this.pieceRemoved !== null) {
      notation = notation + this.pieceRemoved.toNotation();
    } else {
      notation = notation + Piece.NotationEmpty;
    }

    notation = notation + this.destination.toNotation();

    switch (this.extraInfo) {
      case MoveInfo.EnPassant:
        notation += "e";
        break;
      case MoveInfo.Ambiguous:
        notation += "a";
        break;
      case MoveInfo.TransformQueen:
        notation += "Q";
        break;
      case MoveInfo.TransformRook:
        notation += "R";
        break;
      case MoveInfo.TransformKnight:
        notation += "N";
        break;
      case MoveInfo.TransformBishop:
        notation += "B";
        break;
    }

    switch (this.state) {
      case MoveState.Check:
        notation = notation + "+";
        break;
      case MoveState.Mate:
        notation = notation + "#";
        break;
      case MoveState.Draw:
        notation = notation + "=";
        break;
    }

    return notation;
  }

  toVisualNotation(includePosition: boolean): string {
    let notation: string = "";

    if (includePosition && this.moveId % 2 === 0) {
      notation = notation + (this.moveId / 2 + 1) + ". ";
    }

    if (this.extraInfo === MoveInfo.CastleKingSide) {
      notation = notation + "O-O";
    } else if (this.extraInfo === MoveInfo.CastleQueenSide) {
      notation = notation + "O-O-O";
    } else {

      if (this.piece.type !== PieceType.Pawn) {
        notation = notation + this.piece.toNotation();
      }

      if (this.extraInfo === MoveInfo.Ambiguous) {
        notation = notation + this.origin.toNotation();
      }

      if (this.pieceRemoved !== null || this.extraInfo === MoveInfo.EnPassant) {
        if (this.piece.type === PieceType.Pawn) {
          notation = notation + this.origin.toNotationColumn();
        }
        notation = notation + "x";
      }

      notation = notation + this.destination.toNotation();

      switch (this.extraInfo) {
        case MoveInfo.TransformQueen:
          notation = notation + "Q";
          break;
        case MoveInfo.TransformRook:
          notation = notation + "R";
          break;
        case MoveInfo.TransformKnight:
          notation = notation + "N";
          break;
        case MoveInfo.TransformBishop:
          notation = notation + "B";
          break;
      }
    }

    switch (this.state) {
      case MoveState.Check:
        notation = notation + "+";
        break;
      case MoveState.Mate:
        notation = notation + "#";
        break;
      case MoveState.Draw:
        notation = notation + "=";
        break;
    }

    return notation;
  }

  static toVisualFromBackendMove(notation: string): string {

    let move: Move = Move.moveFromNotation(0, notation);
    let visual = move.toVisualNotation(false);

    return visual;
  }

  static moveFromNotation(moveId: number, notation: string): Move {

    let moveColor: Color = (moveId % 2 === 0) ? Color.White : Color.Black;
    let oppositeColor: Color = (moveColor === Color.White) ? Color.Black : Color.White;

    let piece: Piece = Piece.fromNotation(notation[0], moveColor);

    let origin: Coord = Coord.fromNotation(notation.substr(1, 2));

    let dest: Coord = Coord.fromNotation(notation.substr(4, 2));

    if (origin.r < 0 || origin.r > 7 || origin.c < 0 || origin.c > 7 ||
      dest.r < 0 || dest.r > 7 || dest.c < 0 || dest.c > 7) {
      this.orchestrator.log(LogType.Error, Activity.NotationError, "Invalid notation: " + notation);
    }

    let destinationRemoved: Coord = null;

    let pieceRemoved: Piece = null;

    if (notation[3] !== "E") {
      pieceRemoved = Piece.fromNotation(notation[3], oppositeColor);
      destinationRemoved = dest;
    }

    let state: MoveState = MoveState.Normal;
    let extraInfo: MoveInfo = MoveInfo.Normal;

    let pieceAtDestination: Piece = null;

    if (notation.length > 6) {

      let nextIndexToCheck: number = 6;

      switch (notation[6]) {
        case "e":
          extraInfo = MoveInfo.EnPassant;
          pieceRemoved = Piece.fromNotation("P", oppositeColor);
          destinationRemoved = new Coord(origin.r, dest.c);
          nextIndexToCheck++;
          break;
        case "a":
          extraInfo = MoveInfo.Ambiguous;
          nextIndexToCheck++;
          break;
        case "Q":
          extraInfo = MoveInfo.TransformQueen;
          pieceAtDestination = Piece.fromNotation("Q", moveColor);
          nextIndexToCheck++;
          break;
        case "R":
          extraInfo = MoveInfo.TransformRook;
          pieceAtDestination = Piece.fromNotation("R", moveColor);
          nextIndexToCheck++;
          break;
        case "N":
          extraInfo = MoveInfo.TransformKnight;
          pieceAtDestination = Piece.fromNotation("N", moveColor);
          nextIndexToCheck++;
          break;
        case "B":
          extraInfo = MoveInfo.TransformBishop;
          pieceAtDestination = Piece.fromNotation("B", moveColor);
          nextIndexToCheck++;
          break;
      }

      if (notation.length > nextIndexToCheck) {
        switch (notation[nextIndexToCheck]) {
          case "+":
            state = MoveState.Check;
            break;
          case "#":
            state = MoveState.Mate;
            break;
          case "=":
            state = MoveState.Draw;
            break;
        }
      }
    }

    // castling
    if (notation === "Ke1Eg1" || notation === "Ke8Eg8") {
      extraInfo = MoveInfo.CastleKingSide;
    } else if (notation === "Ke1Ec1" || notation === "Ke8Ec8") {
      extraInfo = MoveInfo.CastleQueenSide;
    }

    let move: Move = new Move(moveId, origin, dest, piece, pieceAtDestination, pieceRemoved, destinationRemoved);

    move.extraInfo = extraInfo;
    move.state = state;

    return move;
  }
}
