import { Activity, LogType } from "./logViewModel";
import { Orchestrator } from "./orchestrator";
import { Storage } from "./storage";
import { IDiscussionReceived, IDiscussionsReceived, INewDiscussionSent, ITextReceived, ITextSent, Zumo, ZumoCodes } from "./zumo";

import * as ko from 'knockout';
import {
  Observable, ObservableArray, PureComputed
} from 'knockout';

export class DiscussionItem {

  public message: string;
  public gameId: number;
  public timeStampBackend: string;
  public displayDateTime: Date;
  public leftItem: boolean;
  public emojiId: number;

  constructor(message: string, gameId: number, timeStampBackend: string, leftItem: boolean, emojiId: number) {
    this.message = message;

    // check for non-standard Date format coming from SQL's GETDATE() and convert it to ISO format
    let index: number = timeStampBackend.indexOf(" +00:00");

    if (index !== -1) {
      timeStampBackend = timeStampBackend.slice(0, index);
      timeStampBackend = timeStampBackend.replace(" ", "T");
      timeStampBackend += "Z";
    }

    this.displayDateTime = new Date(timeStampBackend);

    this.leftItem = leftItem;
    this.gameId = gameId;
    this.emojiId = emojiId;
  }

  private _zeroPadded(num: number): string {
    if (num <= 9) {
      return "0" + num;
    }
    if (num === 0) {
      return "00";
    }
    return num.toString();
  }

  private _getDateString(date: Date): string {
    let str: string = date.toLocaleDateString();

    str += " ";

    str += this._zeroPadded(date.getHours()) + ":" + this._zeroPadded(date.getMinutes()) + ":" +
      this._zeroPadded(date.getSeconds());

    return str;
  }

  public static fromString(content: string, currentUserIsFirst: boolean): DiscussionItem {

    let items: Array<string> = content.split("`");

    let gameId: number = 0;
    let offset: number = 0;

    let emojiId: number = 0;

    if (items.length === 3) {
      offset = 0;
    } else {
      offset = 1;
      gameId = parseInt(items[0]);

      if (items.length > 4) {
        emojiId = parseInt(items[4]);
      }
    }

    let leftItem: boolean = (items[2 + offset] === "1") && currentUserIsFirst || (items[2 + offset] === "0") && !currentUserIsFirst;

    let d: DiscussionItem = new DiscussionItem(items[offset], gameId, items[1 + offset], leftItem, emojiId);

    return d;
  }
}

export interface IDiscussionBackend {
  id: string;
  User1: string;
  User2: string;
  Content: string;
  Version: number;
  createdAt: Date;
  updatedAt: Date;
  deleted: boolean;
}

export interface IDiscussionJSON {
  id: string;
  user1: string;
  user2: string;
  content: string;
  version: number;
  createdAt: Date;
  updatedAt: Date;
  items: Array<DiscussionItem>;
  outOfSync: boolean;
  userOutOfSync: boolean;
}

export class Discussion {

  public id: Observable<string>;
  public user1: string;
  public user2: string;
  public content: string;
  public version: number;
  public createdAt: Date;
  public updatedAt: Date;

  public items: ObservableArray<DiscussionItem>;

  public static currentUserName: string;

  public outOfSync: Observable<boolean>; // true if the discussion has not been downloaded from the cloud or
  // the cloud version is newer

  public userOutOfSync: Observable<boolean>; // true if the user has not seen the latest update of the discussion

  public static orchestrator: Orchestrator;

  constructor() {
    this.id = ko.observable("0");
    this.content = "";
    this.version = 0;
    this.items = ko.observableArray([]);
    this.outOfSync = ko.observable(false);
    this.userOutOfSync = ko.observable(false);
  }

  public cloneFromDiscussion(disc: Discussion): void {

    this.id(disc.id());
    this.user1 = disc.user1;
    this.user2 = disc.user2;
    this.content = disc.content;
    this.items = disc.items;
    this.version = disc.version;
    this.createdAt = disc.createdAt;
    this.updatedAt = disc.updatedAt;
    this.outOfSync(disc.outOfSync());
    this.userOutOfSync(disc.userOutOfSync());
  }

  public static discussionFromBackend(d: IDiscussionBackend): Discussion {

    let disc: Discussion = new Discussion();

    disc.id(d.id);
    disc.user1 = d.User1;
    disc.user2 = d.User2;
    disc.content = d.Content;
    disc.createdAt = new Date(d.createdAt.toString());
    disc.updatedAt = new Date(d.updatedAt.toString());
    disc.version = d.Version;
    disc.outOfSync(true);
    disc.userOutOfSync(true);

    disc.deserialize();

    return disc;
  }

  public static discussionFromJSON(d: IDiscussionJSON): Discussion {

    let disc: Discussion = new Discussion();

    disc.id(d.id);
    disc.user1 = d.user1;
    disc.user2 = d.user2;
    disc.content = d.content;
    disc.createdAt = new Date(d.createdAt.toString());
    disc.updatedAt = new Date(d.updatedAt.toString());
    disc.version = d.version;
    disc.outOfSync(d.outOfSync);
    disc.userOutOfSync(d.userOutOfSync);
    disc.items(d.items);

    return disc;
  }

  public partner(): string {
    if (this.id() === "0") {
      return null;
    }

    return Discussion.currentUserName === this.user1 ? this.user2 : this.user1;
  }

  public deserialize(): void {

    if (this.content === "") {
      return;
    }

    let items: Array<string> = this.content.split("~");

    this.content = "";

    this.items([]);

    for (let i: number = 0; i < items.length; i++) {
      this.items.push(DiscussionItem.fromString(items[i], Discussion.currentUserName === this.user1));
    }
  }

  public async addChatAsync(message: string, gameId: number): Promise<DiscussionItem | string> {

    let self: Discussion = this;

    if (message === "") {
      return 'cannot send an empty text';
    }
    let dataSent: ITextSent = {
      Machine: Zumo.machine,
      DiscussionId: this.id(),
      Content: message,
      gameId: gameId,
      Version: this.version
    };

    let dataReceived: ITextReceived = <ITextReceived>await Zumo.postCallAsync<ITextSent>('text', dataSent);

    if (dataReceived.statusCode !== ZumoCodes.Success) {
      return dataReceived.message;
    }

    // use the timestamp from TimeAndOrder
    let timeStamp: string = dataReceived.TimeAndOrder.slice(0, dataReceived.TimeAndOrder.indexOf("`"));

    let d: DiscussionItem = new DiscussionItem(message, gameId, timeStamp, false, 0);

    self.items.push(d);

    if (dataReceived.Version === self.version + 1) {
      self.version = dataReceived.Version;

      self.outOfSync(false);
      self.userOutOfSync(false);

      self.save();

    } else {
      // TODO: the discussion is not in sync. We need to fetch the entire discussion again!!!
      // for now, don't save the discussion. this will lead to some weird UI...
      Discussion.orchestrator.log(LogType.Error, Activity.NotImplemented,
        "Discussion id: " + self.id() + " not in sync. Local version: " + self.version +
        ". Notification version: " + dataReceived.Version);
    }
    return d;
  }

  public receivedChat(content: string, version: number, textForPartnerOnChatView: boolean, chatViewOn: boolean): void {

    if (this.outOfSync()) {
      return;
    }

    if (this.version === version - 1) {

      this.items.push(DiscussionItem.fromString(content, Discussion.currentUserName === this.user1));

      if (!chatViewOn || !textForPartnerOnChatView) {
        this.userOutOfSync(true);
      }

      this.version = version;
      this.save();

      return;
    }

    this.outOfSync(true);

    // TODO: deal with the case where we need to update it from the cloud.
  }

  public save(): void {

    if (this.id() === "0") {
      // nothing to save since this is a fake discussion
      return;
    }

    this.content = "";

    let discussionString: string = ko.toJSON(ko.toJS(this));

    Storage.set(`${Discussion.currentUserName}.${this.partner()}`, discussionString);
  }

  public static async loadDiscussion(partner: string): Promise<Discussion> {

    let discString: string = await Storage.get(`${Discussion.currentUserName}.${partner}`);

    if (discString === null) {
      return null;
    }

    try {
      let disc: Discussion = Discussion.discussionFromJSON(JSON.parse(discString));

      return disc;
    } catch (e) {
      Discussion.orchestrator.log(LogType.Error, Activity.Discussions,
        "Failed to parse discussion: " + discString + ". Error: " + e.message);
    }

    return null;
  }

  public update(disc: IDiscussionBackend): void {
    this.updatedAt = disc.updatedAt;
    this.version = disc.Version;
    this.content = disc.Content;

    this.deserialize();

    this.outOfSync(false);
  }

  public async updateFromTheCloudAsync(): Promise<string> {

    let self: Discussion = this;

    // load the discussion from the cloud.
    let dataReceived: IDiscussionReceived = await Zumo.getDiscussionApiCallAsync(this.id(), this.partner());

    if (dataReceived.statusCode !== ZumoCodes.Success) {
      return dataReceived.message;
    }

    // save the content of the conversation and mark it appropriately
    let discussion: Discussion = Discussion.discussionFromBackend(dataReceived.discussion);

    self.cloneFromDiscussion(discussion);

    self.userOutOfSync(false);
    self.outOfSync(false);

    self.save();
    return dataReceived.message;
  }

  public async getDiscussionForPartnerFromTheCloudAsync(partner: string): Promise<string> {

    let self: Discussion = this;

    // load the discussion from the cloud.
    let dataReceived: IDiscussionReceived = await Zumo.getDiscussionApiCallAsync("0", partner);

    if (dataReceived.statusCode !== ZumoCodes.Success) {
      return dataReceived.message;
    }
    let discussion: Discussion = Discussion.discussionFromBackend(dataReceived.discussion);

    self.cloneFromDiscussion(discussion);

    self.outOfSync(false);

    self.save();

    return dataReceived.message;
  }

  public static async newDiscussionAsync(partner: string): Promise<Discussion | string> {

    let dataSent: INewDiscussionSent = { Partner: partner };

    // start a new discussion
    let dataReceived: IDiscussionReceived = <IDiscussionReceived>await Zumo.postCallAsync<INewDiscussionSent>('newDiscussion', dataSent);

    if (dataReceived.statusCode !== ZumoCodes.Success) {
      return dataReceived.message;
    }

    // save the content of the conversation and mark it appropriately
    let discussion: Discussion = Discussion.discussionFromBackend(dataReceived.discussion);

    return discussion;
  }
}

export class DiscussionsCollection {

  public static currentUserName: string; // this needs to be set globally

  public partners: Array<string> = [];

  public discussions: Array<Discussion> = [];

  private _saveLocal(): void {

    // build the list of partners
    this.partners = [];

    for (let i: number = 0; i < this.discussions.length; i++) {

      let disc: Discussion = this.discussions[i];

      let partner: string = disc.partner();

      if (disc.id() !== "0" && this.partners.indexOf(partner) === -1) {
        this.partners.push(partner);
      }

      disc.save();
    }

    let partnersString: string = JSON.stringify(this.partners);

    Storage.set(`${Discussion.currentUserName}.discussionPartners`, partnersString);
  }

  private _updateDiscussion(disc: Discussion, forceUpdate: boolean): void {

    let partner: string = (disc.user1 === DiscussionsCollection.currentUserName) ? disc.user2 : disc.user1;

    let crtDisc: Discussion = this.getDiscussion(partner);

    if (crtDisc.id() === "0" || forceUpdate) {

      crtDisc.cloneFromDiscussion(disc);
      crtDisc.outOfSync(true);
    } else {

      if (crtDisc.version < disc.version) {
        crtDisc.outOfSync(true);
      }
    }
  }

  public async loadLocalDiscussions(): Promise<boolean> {
    let partnersString: string = await Storage.get(`${Discussion.currentUserName}.discussionPartners`);

    if (partnersString === null) {
      return false;
    }

    try {
      this.partners = JSON.parse(partnersString);
    } catch (e) {
      Discussion.orchestrator.log(LogType.Error, Activity.Discussions,
        "Failed to parse discussion partners: " + partnersString + ". Error: " + e.message);
      return false;
    }

    this.discussions = [];

    for (let i: number = 0; i < this.partners.length; i++) {
      let disc: Discussion = await Discussion.loadDiscussion(this.partners[i]);

      if (disc !== null) {
        this.discussions.push(disc);
      }
    }
    return true;
  }

  public async updateDiscussionsFromCloudAsync(all: boolean): Promise<string> {

    let self: DiscussionsCollection = this;

    // load the discussions from the cloud.
    let dataReceived: IDiscussionsReceived = await Zumo.getDiscussionsApiCallAsync();

    if (dataReceived.statusCode !== ZumoCodes.Success) {
      return dataReceived.message;
    }

    let discussions: Array<IDiscussionBackend> = dataReceived.discussions;

    for (let i: number = 0; i < discussions.length; i++) {

      let disc: Discussion = Discussion.discussionFromBackend(discussions[i]);

      self._updateDiscussion(disc, all);
    }

    self._saveLocal();

    return dataReceived.message;
  }

  public getDiscussion(partner: string): Discussion {

    let disc: Discussion = null;

    for (let i: number = 0; i < this.discussions.length; i++) {

      disc = this.discussions[i];

      if (disc.user1 === partner || disc.user2 === partner) {
        return disc;
      }
    }

    // no discussion for the partner requested. Create a fake one and add it to the collection

    let newDisc: Discussion = new Discussion();

    newDisc.user1 = Discussion.currentUserName;
    newDisc.user2 = partner;

    this.discussions.push(newDisc);

    return newDisc;
  }

  public async newDiscussionAsync(partner: string): Promise<Discussion | string> {

    let disc: Discussion = this.getDiscussion(partner);

    if (disc.id() !== "0") {
      // this should not be called for an existing partner. Play nicely though...
      return `unexpected call`;
    }

    let self: DiscussionsCollection = this;

    let result: Discussion | string = await Discussion.newDiscussionAsync(partner);

    if (result instanceof Discussion) {
      // we have a new discussion. Add it to the collection and save it locally
      disc.cloneFromDiscussion(result);
      disc.save();

      self._saveLocal();

      return disc;
    }

    return result;
  }

  public async getLatestDiscussionAsync(partner: string): Promise<Discussion | ZumoCodes> {

    let disc: Discussion = this.getDiscussion(partner);

    if (disc.id() === "0") {

      // TODO: this should force getting the discussion for the specified partner
      Discussion.orchestrator.log(LogType.Error, Activity.NotImplemented,
        "Need to fetch the latest discussion with partner: " + partner);

      return disc;
    }

    if (disc.outOfSync() === false) {

      if (disc.userOutOfSync()) {
        disc.userOutOfSync(false);
        disc.save();
      }
      return disc;
    }

    // the discussion is out of sync
    let error: string = await disc.updateFromTheCloudAsync();

    if (error) {
      Discussion.orchestrator.log(LogType.Error, Activity.Discussions,
        `Failed to update from the cloud discussion for partner: ${partner}. Error ${error}`);
    }

    return disc;
  }

  public async receivedChatForDiscussionAsync(partner: string, content: string, version: number,
    textForPartnerOnChatView: boolean, chatViewOn: boolean): Promise<void> {

    let disc: Discussion = this.getDiscussion(partner);

    if (disc.id() !== "0") {
      disc.receivedChat(content, version, textForPartnerOnChatView, chatViewOn);
      return;
    }

    // this is a new discussion and this is the first message
    let self: DiscussionsCollection = this;

    let error: string = await disc.getDiscussionForPartnerFromTheCloudAsync(partner);

    if (error) {
      Discussion.orchestrator.log(LogType.Error, Activity.ChatNotification,
        `Failed to get discussion for partner: ${partner}. Error: ${error}`);
      return;
    }

    self.discussions.push(disc);
    self._saveLocal();
    return;
  }

  public clear(): void {
    this.discussions.splice(0, this.discussions.length);
  }
}
