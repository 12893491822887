import { appServiceClient } from ".";
import { IDiscussionBackend } from "./discussions";
import { Completed, Game, IGame } from "./game";
import { IUser } from "./loginViewModel";
import { LogEntry } from "./logViewModel";

export const AppName: string = 'ChessM8';

export enum ZumoCodes {
  Success = 0,
  FailedToConnect = 1,
  UnexpectedResult = 2,

  Exception = 5,

  GameNotFound = 100,

  game_already_completed = 101,
  game_not_in_sync = 102,
  a_move_must_be_specified = 103,
  black_player_missing = 104,
  game_has_a_draw_offer = 105,
  white_user_not_found = 106,
  white_user_has_no_active_games = 107,
  black_user_not_found = 108,
  black_user_has_no_active_games = 109,
  updating_user_is_not_a_player_in_this_game = 110,
  wrong_user_updating_the_game = 111,
  current_player_cannot_be_mating_himself = 112,

  invalid_user = 113,
  too_many_active_games_for_current_user = 114,
  too_many_moves = 115,
  game_is_matched = 116,
  challenging_same_as_challenged = 117,
  too_many_active_games_for_opponent = 118,
  a_text_must_be_specified = 119,
  discussion_not_found = 120,
  updating_user_is_not_a_part_of_the_conversation = 121,
  game_must_have_at_least_two_moves = 122,
  game_has_a_draw_offer_rejected = 123,
  game_does_not_have_a_draw_offer = 124,
  game_does_not_have_a_challenge = 125,
  user_name_already_registered = 126,

  game_not_completed = 127,

  user_is_a_player_in_the_game = 128,
  invite_existing = 130,
  game_version_mismatch = 131,
  discussion_version_mismatch = 132,
  game_not_available_anymore = 133,

  invalid_parameter = 200,
  out_of_retries = 201,

  exception = 1000
}

export interface IBasicGameDataSent {
  Machine: string;
  gameId: number;
  MovesCount: number;
  Version: number;
}

export interface IMoveDataSent extends IBasicGameDataSent {
  Move: string;
  MoveToDisplay: string;
  Completed: Completed;
}

export interface IResignAcceptDrawDataSent extends IBasicGameDataSent {
  AcceptDraw: boolean;
}

export interface IJoinGameDataSent {
  Machine: string;
  gameId: number;
}

export interface IChallengeDataSent {
  Machine: string;
  Rated: boolean;
  Opponent: string;
  AsWhite: boolean;
}

export interface IEmailInviteSent {
  Machine: string;
  Email: string;
  From: string;
  Content: string;
}

export interface IGetGamesSent {
  NewerThan: Date;
}

export interface IUpdateUserInfoSent {
  NotifyWatchedGames: boolean;
  Country: string;
}

export interface INewUserSent {
  UserName: string;
}

export interface INewGameSent {
  Machine: string;
  Rated: boolean;
  SimilarOpponent: boolean;
  ComputerOpponent: boolean;
  NewGame: boolean;
}

export interface IDiscussionSent {
  id: string;
  Partner: string;
  date: Date; // BUGBUG: only for Windows Phone 8.1 bug
}

export interface INewDiscussionSent {
  Partner: string;
}

export interface ITextSent {
  Machine: string;
  DiscussionId: string;
  Content: string;
  gameId: number;
  Version: number;
}

export interface IFavoriteGameSent {
  Machine: string;
  gameId: number;
  favorite: boolean;
}

export interface IBasicDataReceived {
  statusCode: number;
  message: string; // filled by the client in case of exception. statusCode should be Exception: 5
}

// Structure for HTTP GET requests
export interface IGetAllDiscussionsSent {
  date: Date;
}

export interface IUpdateUserInfoReceived extends IBasicDataReceived {
  user: IUser;
}

export interface INewUserReceived extends IBasicDataReceived {
  user: IUser;
}

export interface IGetGamesReceived extends IBasicDataReceived {
  games: IGame[];
  watching: IGame[];
  watchingGamesIds: number[];
  user?: IUser;
}


export interface IDataReceived extends IBasicDataReceived {
  game: Game;
  crtUserId: string;
  user1: IUser;
  user2: IUser;
  machineSending: string;
}

export interface IEmailInviteReceived extends IBasicDataReceived {
  sent: boolean;
  foundUserName: string;
}

export interface IUserAvatar {
  UserName: string;
  AvatarID: number;
  Country: string;
}

export interface IJoinableGame {
  id: number;
  White: string;
  CurrentElo: number;
  Moves: string;
  Rated: boolean;
  Flags: number;
  updatedAt: Date;
  created: string;
  Joinable: boolean;
}

export interface IWebPushSubscriptionSent {
  machine: string;
  userName: string;
  endpoint: string;
  p256dh: string;
  auth: string;
  browser: string;
}

export interface IAvatarsReceived extends IBasicDataReceived {
  userAvatars: Array<IUserAvatar>;
}

export interface IDiscussionsReceived extends IBasicDataReceived {
  discussions: Array<IDiscussionBackend>;
}

export interface IDiscussionReceived extends IBasicDataReceived {
  discussion: IDiscussionBackend;
}

export interface ITextReceived extends IBasicDataReceived {
  Version: number;
  updatedAt: Date;
  TimeAndOrder: string;
  Sender: string;
  Receiver: string;
}

export interface IWebPushReceived {
  statusCode: number;
}

export interface ILogsSent {
  sessionId: number;
  userName: string;
  machine: string;
  Logs: Array<LogEntry>;
}

export interface IFavoriteGameReceived extends IBasicDataReceived {
  game: Game;
}

export interface IRunQueryJoinableGamesReceived extends IBasicDataReceived {
  rows: Array<IJoinableGame>;
}

export interface IUserInfoReceived extends IBasicDataReceived {
  user: IUser;
  hoursBetweenMovesRated: number;
  hoursBetweenMovesUnrated: number;
  hoursBeforeExpiration: number;
  maxActiveGames: number;
  maxCompletedGames: number;
}

export interface IDownloadPictureReceived extends IBasicDataReceived {
  content: string;
  url: string;
}


export class Zumo {

  static machine: string = "";

  constructor() { }

  static setMachineId(machine: string): void {
    Zumo.machine = machine;
  }

  static getErrorDetails(error: ZumoCodes): string {

    let details: string;

    switch (error) {
      case ZumoCodes.Success:
        details = "success";
        break;
      case ZumoCodes.FailedToConnect:
        details = `Failed to connect with the ${AppName} servers.`;
        break;
      case ZumoCodes.UnexpectedResult:
        details = `Unexpected code from ${AppName} servers.`;
        break;
      case ZumoCodes.Exception:
        details = `Exception.`;
        break;
      case ZumoCodes.game_already_completed:
        details = "Cannot update a game that is already completed.";
        break;
      case ZumoCodes.game_not_in_sync:
        details = `Game is not in sync with the ${AppName} servers.`;
        break;
      case ZumoCodes.a_move_must_be_specified:
        details = "A move must be specified.";
        break;
      case ZumoCodes.black_player_missing:
        details = "The game needs to have a black player.";
        break;
      case ZumoCodes.game_has_a_draw_offer:
        details = "The game has a pending draw offer.";
        break;
      case ZumoCodes.white_user_not_found:
        details = `The White user does not exist on the ${AppName} servers.`;
        break;
      case ZumoCodes.white_user_has_no_active_games:
        details = `The White user has no active games on the ${AppName} servers.`;
        break;
      case ZumoCodes.black_user_not_found:
        details = `The Black user does not exist on the ${AppName} servers.`;
        break;
      case ZumoCodes.black_user_has_no_active_games:
        details = `The Black user has no active games on the ${AppName} servers.`;
        break;
      case ZumoCodes.updating_user_is_not_a_player_in_this_game:
        details = "The updating user is not a player in this game.";
        break;
      case ZumoCodes.wrong_user_updating_the_game:
        details = "The wrong user is updating the game.";
        break;
      case ZumoCodes.current_player_cannot_be_mating_himself:
        details = "The current player cannot be mating himself/herself.";
        break;

      case ZumoCodes.invalid_user:
        details = `The user does not exist on the ${AppName} servers.`;
        break;
      case ZumoCodes.too_many_active_games_for_current_user:
        details = "There are too many active games for the current user. Complete some games to be able to play new ones.";
        break;
      case ZumoCodes.too_many_moves:
        details = "Too many moves.";
        break;
      case ZumoCodes.game_is_matched:
        details = "The game is matched.";
        break;
      case ZumoCodes.challenging_same_as_challenged:
        details = "The challenging player is the same as the challenged.";
        break;
      case ZumoCodes.too_many_active_games_for_opponent:
        details = "Too many active games for opponent.";
        break;
      case ZumoCodes.a_text_must_be_specified:
        details = "A text must be specified.";
        break;
      case ZumoCodes.discussion_not_found:
        details = "The discussion between you and your opponent was not found.";
        break;
      case ZumoCodes.updating_user_is_not_a_part_of_the_conversation:
        details = "The updating user is not part of this conversation.";
        break;
      case ZumoCodes.game_must_have_at_least_two_moves:
        details = "The game must have at least two moves.";
        break;
      case ZumoCodes.game_has_a_draw_offer_rejected:
        details = "The game has a draw offer rejected.";
        break;
      case ZumoCodes.game_does_not_have_a_draw_offer:
        details = "The game does not have a draw offer.";
        break;
      case ZumoCodes.game_does_not_have_a_challenge:
        details = "The game does not have a challenge.";
        break;
      case ZumoCodes.user_name_already_registered:
        details = "The user name is already taken.";
        break;
      case ZumoCodes.game_not_completed:
        details = "The game is not completed.";
        break;

      case ZumoCodes.invalid_parameter:
        details = "Invalid parameter.";
        break;

      case ZumoCodes.out_of_retries:
        details = "Out of retries.";
        break;
      default:
        details = "unknown error";
        break;
    }

    return details;
  }

  public static async postCallAsync<T>(apiName: string, dataSent: T): Promise<IBasicDataReceived> {

    let dataReceived: IBasicDataReceived;

    try {
      let response: XMLHttpRequest = await appServiceClient.invokeApi(apiName, { body: dataSent, method: "POST" });
      dataReceived = (<any>response).result;
      dataReceived.message = dataReceived.statusCode === ZumoCodes.Success ? null : Zumo.getErrorDetails(dataReceived.statusCode);
    } catch (_e) {
      let e: Error = _e;
      dataReceived = { statusCode: ZumoCodes.Exception, message: e.message };
    }

    return dataReceived;
  }

  public static async gamePostAsync(g: Game, dataSent: IBasicGameDataSent, apiName: string): Promise<IDataReceived> {

    // dataSent extends IBasicGameDataSent
    dataSent.Machine = Zumo.machine;
    dataSent.gameId = g.id;
    dataSent.MovesCount = g.MovesCount;
    dataSent.Version = g.Version;

    console.log(`gamePost ${JSON.stringify(dataSent)}`);

    let dataReceived: IDataReceived = <IDataReceived>await Zumo.postCallAsync<IBasicGameDataSent>(apiName, dataSent);

    return dataReceived;
  }

  public static async getJoinableGamesApiCallAsync(): Promise<IRunQueryJoinableGamesReceived> {

    let dataReceived: IRunQueryJoinableGamesReceived;

    let query = "select Top 12 G.id,G.White,G.Moves,U.CurrentElo,G.Rated,G.Flags,G.updatedAt from [dbo].[Game] G join [dbo].[User] U on G.White = U.UserName where Completed = 0 and Black = '' order by updatedAt DESC";

    try {
      let response: XMLHttpRequest = await appServiceClient.invokeApi("runQuery", { method: "GET", parameters: { Query: query } });
      dataReceived = (<any>response).result;
      dataReceived.message = dataReceived.statusCode === ZumoCodes.Success ? null : Zumo.getErrorDetails(dataReceived.statusCode);
    } catch (_e) {
      let e: Error = _e;
      dataReceived = { statusCode: ZumoCodes.Exception, message: '', rows: null };
    }

    return dataReceived;
  }

  public static async getAvatarsApiCallAsync(opponents: string): Promise<IAvatarsReceived> {

    let dataReceived: IAvatarsReceived;

    try {
      let response: XMLHttpRequest = await appServiceClient.invokeApi("getAvatars", { method: "GET", parameters: { users: opponents } });
      dataReceived = (<any>response).result;
      dataReceived.message = dataReceived.statusCode === ZumoCodes.Success ? null : Zumo.getErrorDetails(dataReceived.statusCode);
    } catch (_e) {
      let e: Error = _e;
      dataReceived = { statusCode: ZumoCodes.Exception, userAvatars: null, message: e.message };
    }

    return dataReceived;
  }

  public static async getDiscussionsApiCallAsync(): Promise<IDiscussionsReceived> {

    let dataReceived: IDiscussionsReceived;

    // passing the date as a parameter to get around a bug
    // in (mostl likely) the Azure Mobile Apps plugin for Windows Phone 8.1
    try {
      let response: XMLHttpRequest = await appServiceClient.invokeApi("getAllDiscussions", { method: "GET", parameters: { date: new Date() } });
      dataReceived = (<any>response).result;
      dataReceived.message = dataReceived.statusCode === ZumoCodes.Success ? null : Zumo.getErrorDetails(dataReceived.statusCode);
    } catch (_e) {
      let e: Error = _e;
      dataReceived = { statusCode: ZumoCodes.Exception, discussions: null, message: e.message };
    }

    return dataReceived;
  }

  public static async getDiscussionApiCallAsync(id: string, partner: string): Promise<IDiscussionReceived> {

    let dataSent: IDiscussionSent = { id: id, Partner: partner, date: new Date() };

    let dataReceived: IDiscussionReceived;

    try {
      let response: XMLHttpRequest = await appServiceClient.invokeApi("getDiscussion", { parameters: dataSent, method: "GET" });
      dataReceived = (<any>response).result;
      dataReceived.message = dataReceived.statusCode === ZumoCodes.Success ? null : Zumo.getErrorDetails(dataReceived.statusCode);
    } catch (_e) {
      let e: Error = _e;
      dataReceived = { statusCode: ZumoCodes.Exception, discussion: null, message: e.message };
    }

    return dataReceived;
  }

  static sendLogs(
    sessionId: number,
    userName: string,
    logs: Array<LogEntry>,
    successCallback: (dataReceived: any) => void,
    errorCallback: (err: string) => void): void {

    let dataSent: ILogsSent = <ILogsSent>{
      sessionId: sessionId,
      userName: userName,
      machine: Zumo.machine,
      Logs: logs
    };

    appServiceClient.invokeApi("log", {
      body: dataSent,
      method: "POST"
    }).done(function (data: any): void {

      let dataReceived: any = data.result;

      // call the function to handle the result
      successCallback(dataReceived);

    }, function (error: any): void {
      errorCallback(error.message);
    });
  }

  private static _getBrowser(): string {
    let browser: string = 'Unknown';

    //Check if browser is IE
    if (navigator.userAgent.search("MSIE") >= 0) {
      // insert conditional IE code here
      browser = 'IE';
    }
    //Check if browser is Chrome
    else if (navigator.userAgent.search("Chrome") >= 0) {
      // insert conditional Chrome code here
      browser = 'Chrome';
    }
    //Check if browser is Firefox 
    else if (navigator.userAgent.search("Firefox") >= 0) {
      // insert conditional Firefox Code here
      browser = 'Firefox';
    }
    //Check if browser is Safari
    else if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
      // insert conditional Safari code here
      browser = 'Safari';
    }
    //Check if browser is Opera
    else if (navigator.userAgent.search("Opera") >= 0) {
      // insert conditional Opera code here
      browser = 'Opera';
    }

    return browser;
  }

  public static async registerWebPushSubscriptionAsync(userName: string, endpoint: string, p256dh: string, auth: string): Promise<boolean> {

    let dataSent: IWebPushSubscriptionSent = <IWebPushSubscriptionSent>{
      machine: Zumo.machine,
      userName: userName,
      endpoint: endpoint,
      p256dh: p256dh,
      auth: auth,
      browser: this._getBrowser()
    };
    let dataReceived: IWebPushReceived;

    let success: boolean = true;

    try {
      let response: XMLHttpRequest = await appServiceClient.invokeApi('registerWebPush', { body: dataSent, method: "POST" });
      dataReceived = (<any>response).result;
    } catch (_e) {
      success = false;
    }

    return success;
  }

  public static async downloadPictureAsync(): Promise<string> {

    let dataReceived: IDownloadPictureReceived;
    let content: string = null;

    try {
      let response: XMLHttpRequest = await appServiceClient.invokeApi("downloadPicture", { method: "GET" });
      dataReceived = (<any>response).result;
      dataReceived.message = dataReceived.statusCode === ZumoCodes.Success ? null : Zumo.getErrorDetails(dataReceived.statusCode);
      console.log(`downloadPicture returned ${dataReceived.content?.length} bytes. Url: ${JSON.stringify(dataReceived.url)}`);
      if (dataReceived.content?.length > 0) {
        content = dataReceived.content;
      }
    } catch (_e) {
      let e: Error = _e;
      dataReceived = { statusCode: ZumoCodes.Exception, content: null, url: null, message: e.message };
      console.error(`downloadPicture failed with ${e}`);
    }

    return content;
  }
}
