import { Modal, Toast } from "bootstrap";
import { appServiceClient } from ".";
import { ChatVM } from "./chatViewModel";
import { Discussion, DiscussionsCollection } from "./discussions";
import { ErrorVM } from "./errorViewModel";
import { CloudCall, Completed, Game, IAnalysisNotification, IGameNotification, MoveNotificationStatus } from "./game";
import { GamesVM, StatsVsOpponent } from "./gamesViewModel";
import { GameVM } from "./gameViewModel";
import { Help, HelpTopic, HelpTopicLocation } from "./help";
import { InviteToWatchVM } from "./inviteToWatchViewModel";
import { IUser, LoginVM } from "./loginViewModel";
import { Activity, LogType, LogVM } from "./logViewModel";
import { Move, MoveState } from "./move";
import { NewGameVM } from "./newGameViewModel";
import { PieceType } from "./piece";
import { SettingsVM } from "./settingsViewModel";
import { AppName, IAvatarsReceived, IBasicGameDataSent, IChallengeDataSent, IDataReceived, IFavoriteGameReceived, IFavoriteGameSent, IMoveDataSent, IResignAcceptDrawDataSent, IUserAvatar, Zumo, ZumoCodes } from "./zumo";
import { Storage } from "./storage";

import * as ko from 'knockout';
import {
  Observable, ObservableArray, PureComputed
} from 'knockout';
import { PicCache, UserAvatar } from "./picFileCache";

export var device: IDevice;

export interface IDevice {
  uuid: string;
  platform: string;
  version: string;
}

export interface ExceptionObject {
  msg: string;
  line: number;
  col: number;
  url: string;
}

export interface IWebPushNotificationPayload {
  type: string;
  content: any;
  user: string;
  title: string;
  message: string;
}

export interface IChatNotification {
  gameId: number;
  sender: string;
  receiver: string;
  content: string;
  version: number;
}

export enum ActivationState {
  NotSet,
  Running,
  Suspended
}

export class Orchestrator {

  public AppName: string = 'ChessM8';

  private _loginVM: LoginVM;
  private _gamesVM: GamesVM;
  private _gameVM: GameVM;
  private _newGameVM: NewGameVM;
  private _chatVM: ChatVM;
  private _logVM: LogVM;
  private _errorVM: ErrorVM;
  private _inviteToWatchVM: InviteToWatchVM;

  private _yesNoModal: Modal;
  private _settingsModal: Modal;
  private _newGameModal: Modal;
  private _logModal: Modal;
  private _loginModal: Modal;
  private _inviteToWatchModal: Modal;
  private _promoteModal: Modal;

  private _toast: Toast;

  static Page_None = "none";
  static Page_Game = "paneGame";
  static Page_Games = "paneGames";
  static Page_Login = "loginModal";
  static Page_NewGame = "newGameModal";
  static Page_Chat = "paneGameExtra";
  static Page_Settings = "settingsModal";
  static Page_Error = "errorPage";
  static Page_InviteToWatch = "inviteToWatchModal";
  static Page_CompletedGames = "completedGamesModal";
  static Page_Log = "logModal";
  static Page_Promote = "promoteModal";
  static Page_PersistentMsg = "persistentMsg";

  public allGamesStats: Observable<StatsVsOpponent>;

  // store the machine id
  private _machineId = ko.observable("");

  // for the yesNo dialog
  private _yesNoCaption = ko.observable("");
  private _yesNoContent = ko.observable("");
  private _yesNoCallback: (yesClicked: boolean) => void = null;

  // toast message
  public toastInfo = ko.observable("");

  // controls the show progress animated gif
  private _showProgressIndicator = ko.observable(false);

  private _showHelp = ko.observable(false);
  private _helpMsg = ko.observable("");
  private _helpLocation = ko.observable(HelpTopic.Nowhere);

  private _registeredForNotifications: boolean = false;

  private _help: Help;

  private _settingsVM: SettingsVM;

  // current user name and Elo
  public currentUser: IUser = null;
  public currentUserAvatar: Observable<UserAvatar>;

  public currentUserName = ko.observable("");
  public currentUserElo = ko.observable("");

  // backend settings
  public hoursBetweenMovesRated = ko.observable(0);
  public hoursBetweenMovesUnrated = ko.observable(0);
  public hoursBeforeExpiration = ko.observable(0);
  public maxActiveGames = ko.observable(0);
  public maxCompletedGames = ko.observable(0);

  public showPersistentMsg = ko.observable(false);
  public persistentMsg = ko.observable("Please allow push notifications to receive moves and texts for the games. Tap on this message to accept push notfications.");

  // store the version of the app
  public version = ko.observable("");

  public discussionsCollection: DiscussionsCollection;

  public materialAlwaysAtBottom: boolean;

  public platform: string;

  public activationState: ActivationState = ActivationState.NotSet;

  public gameIdInGameView = ko.observable(0);

  private _PicCache: PicCache;

  constructor() {

    let self = this;

    //listen to messages
    navigator.serviceWorker.onmessage = (event) => {
      if (!event.data) {
        console.error(`CLIENT COMMS: Invalid message from the worker!`);
        return;

      }
      if (event.data.type === 'INFO') {
        console.log(`CLIENT COMMS: Received an INFO message.`);
      } else {
        self._handleWebPushNotification(event.data);
      }
    };

    //window.localStorage.clear();
    this.allGamesStats = ko.observable(new StatsVsOpponent());

    device = {
      uuid: Orchestrator._uniqueID(),
      platform: 'Browser',
      version: '1.2.12103'
    };

    this.version(device.version);

    Zumo.setMachineId(device.uuid);

    this.platform = device.platform.toLowerCase();

    Discussion.orchestrator = this;
    Game.orchestrator = this;
    Move.orchestrator = this;

    this.currentUserAvatar = ko.observable(null);

    this._help = new Help();

    this._yesNoModal = new Modal(document.getElementById('yesNoDlg'));
    this._newGameModal = new Modal(document.getElementById(Orchestrator.Page_NewGame));
    this._settingsModal = new Modal(document.getElementById(Orchestrator.Page_Settings));
    this._logModal = new Modal(document.getElementById(Orchestrator.Page_Log));
    this._loginModal = new Modal(document.getElementById(Orchestrator.Page_Login));
    this._inviteToWatchModal = new Modal(document.getElementById(Orchestrator.Page_InviteToWatch));
    this._promoteModal = new Modal(document.getElementById(Orchestrator.Page_Promote));

    this._toast = new Toast(document.getElementById('toastMsg'))

    // create all the view models, starting with the Log

    let sessionId: number = Math.floor(Math.random() * 30000);

    this._logVM = new LogVM(this, sessionId);
    ko.applyBindings(this._logVM, document.getElementById(Orchestrator.Page_Log));

    ko.applyBindings(this, document.getElementById("navBar"));
    ko.applyBindings(this, document.getElementById(Orchestrator.Page_PersistentMsg));
    //ko.applyBindings(this, document.getElementById("footer"));

    // create the settings view model first so that we have the settings that
    // will be useds in the other models
    this._settingsVM = new SettingsVM(this);

    ko.applyBindings(this._settingsVM, document.getElementById(Orchestrator.Page_Settings));

    this.materialAlwaysAtBottom = true; // (aspectRatio < .57);

    ko.applyBindings(this, document.getElementById("yesNoDlg"));
    ko.applyBindings(this, document.getElementById(Orchestrator.Page_Promote));

    // log the exception if any
    let exceptionStr: string = window.localStorage.getItem("exception");

    if (exceptionStr !== null) {

      console.log(`Crash in the previous session ${exceptionStr}`);
      window.localStorage.removeItem("exception");


      //this._errorVM = new ErrorVM(this, this._continueAfterError);
      //ko.applyBindings(this._errorVM, document.getElementById("errorPage"));

      //this.activatePage(Pages.Error);
      //return;
    }

    this._continueAfterError();
  }

  private static _uniqueID(): string {
    function chr4() {
      return Math.random().toString(16).slice(-4);
    }

    let machineId: string = window.localStorage.getItem(`${AppName}-machineId`);

    if (machineId) {
      return machineId;
    }

    machineId = `${chr4()}${chr4()}-${chr4()}-${chr4()}-${chr4()}-${chr4()}${chr4()}${chr4()}`;

    window.localStorage.setItem(`${AppName}-machineId`, machineId);

    return machineId;
  }

  private _continueAfterError(): void {
    
    this.discussionsCollection = new DiscussionsCollection();

    this._loginVM = new LoginVM(this);
    this._gamesVM = new GamesVM(this);

    let canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById("chessboard");
    let analysisCanvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById("analysis");

    this._gameVM = new GameVM(this, canvas, analysisCanvas);
    this._newGameVM = new NewGameVM(this);
    this._chatVM = new ChatVM(this);
    this._inviteToWatchVM = new InviteToWatchVM(this);

    ko.applyBindings(this._loginVM, document.getElementById(Orchestrator.Page_Login));
    ko.applyBindings(this._gamesVM, document.getElementById(Orchestrator.Page_Games));
    ko.applyBindings(this._gamesVM, document.getElementById(Orchestrator.Page_CompletedGames));
    ko.applyBindings(this._gameVM, document.getElementById(Orchestrator.Page_Game));
    ko.applyBindings(this._newGameVM, document.getElementById(Orchestrator.Page_NewGame));
    ko.applyBindings(this._chatVM, document.getElementById(Orchestrator.Page_Chat));
    ko.applyBindings(this._inviteToWatchVM, document.getElementById(Orchestrator.Page_InviteToWatch));
    ko.applyBindings(this, document.getElementById('toastMsg'));

    let self: Orchestrator = this;

    self.log(LogType.Local, Activity.Resume, "App started");

    // add keyboard handlers
    document.addEventListener("keydown", function (event: KeyboardEvent): void {

      let el = document.activeElement;
      let chatEdit = document.getElementById("chatEdit");

      if (el !== chatEdit) {
        self._gameVM.handleKeyInput(event);
      } else {
        self._chatVM.handleKeyInput(event);
      }
    });

    if (this._loginVM.loginWithCachedCredentials()) {
      this.showGames();
    } else {
      this.activatePage(Orchestrator.Page_Login);
    }

    // send logs if applicable
    //setInterval(this.sendLogs.bind(this), 100000);

    // WEB_ONLY
    //
    // Check for updates every 15 seconds
    //setInterval(this.onResume.bind(this, true), 15000);

    this._machineId(device.uuid);

  }

  public updateUserStats() {
    this.allGamesStats().Wins(this.currentUser.Wins);
    this.allGamesStats().Draws(this.currentUser.Draws);
    this.allGamesStats().Losses(this.currentUser.Losses);
    this.allGamesStats().TotalGames(this.currentUser.Wins + this.currentUser.Draws + this.currentUser.Losses);
  }

  public logoutClick(): void {

    let self = this;

    this.showYesNoDlg("Logout", "Are you sure you want to log out?", function (yesClicked: boolean): void {
      if (!yesClicked) {
        return;
      }

      self.logout();
    });
  }

  public logout(): void {
    appServiceClient.logout();
    window.localStorage.clear();

    this.discussionsCollection = new DiscussionsCollection();

    this._logVM.reset();

    this.currentUserName("");
    this.currentUserElo("");
    this.currentUser = null;

    this._gamesVM.reset();

    Storage.clear();

    this.activatePage(Orchestrator.Page_Login);
  }

  public viewLogClick(): void {
    this._logModal.show();
    this.activatePage(Orchestrator.Page_Log);
  }

  public postMsgClick(): void {
    //send message
    if (navigator.serviceWorker.controller === null) {
      console.log(`CLIENT COMMS: NULL controller!`);
      return;
    }
    navigator.serviceWorker.controller.postMessage({
      type: 'INFO',
    });
  }

  public async testClick(): Promise<void> {
  }

  // public async testClick(): Promise<void> {
  //   let content: string = await Zumo.downloadPictureAsync();

  //   if (!content) {
  //     return;
  //   }

  //   let firstBytes = content.substring(0, 30);
  //   let displayBytes = '';

  //   for (let i = 0; i < firstBytes.length; i++) {
  //     displayBytes + `${this.convertASCIItoHex(firstBytes[i])} `;
  //   }

  //   console.log(`received content of size ${content.length}. ${displayBytes}`);

  //   //let encodedString = this.toBase64(content); //btoa(content);

  //   let i: HTMLImageElement = <HTMLImageElement>document.getElementById('crtPic');

  //   i.src = `data:image/png;base64,${content}`;
  // }

  public settingsClick(): void {
    this._settingsVM.activate();
    this._settingsModal.show();
  }

  public newGameClick(): void {
    this._newGameModal.show();
    this.activatePage(Orchestrator.Page_NewGame);
  }

  public dismissNewGame(): void {
    this._newGameModal.hide();
  }

  public sendLogs(): void {
    this._logVM.sendLogs();
  }

  // #region Notifications

  private _handleChatNotification(message: string, notification: IChatNotification): void {

    // get the text that was sent
    let text: string;
    let index: number;

    index = message.indexOf(" said ");

    if (index === -1) {
      this.log(LogType.Error, Activity.ChatNotification,
        `Failed to find the text in a chat notification: ${message}`);
      return;
    }

    index += 6; // skip over " said "
    text = message.slice(index);

    // if the Chat view was ever visited, we might have a discussion with its items already there
    // get the name of the partner that is on the chat view
    let partnerOnChatView: string = this._chatVM.partner();
    let chatViewIsOn: boolean = true;

    // incorporate the text into the rest of the content
    let content: string = notification.gameId.toString() + "`" + text + "`" + notification.content;

    let partner: string = notification.sender;

    if (partner === this.currentUserName()) {
      partner = notification.receiver;
    }

    let textForPartnerOnChatView: boolean = (partnerOnChatView === partner);

    this.discussionsCollection.receivedChatForDiscussionAsync(partner,
      content, notification.version, textForPartnerOnChatView, chatViewIsOn);

    let g: Game = this.getGame(notification.gameId);

    if (g === null) {
      this.log(LogType.Error, Activity.Notifications, `Chat notification for unknown or new gameId ${notification.gameId}`);
      this.refresh();
    }
    if (!textForPartnerOnChatView) {
      this.showMessage(message, LogType.Info);
    }
  }

  private _handleAnalysisNotification(notification: IAnalysisNotification): void {
    // for now simply refresh the games
    this.refresh();
  }

  private _handleMoveNotification(notification: IGameNotification): void {

    let g: Game = this.getGame(notification.game.id);

    if (g === null) {
      this.log(LogType.Error, Activity.Notifications, "" + notification.info +
        " notification for unknown or new gameId " + notification.game.id);
      this.refresh();
      return;
    }

    let status: MoveNotificationStatus = g.getMoveNotificationStatus(notification);

    if (status === MoveNotificationStatus.Old) {
      return;
    }

    if (status === MoveNotificationStatus.GameNotInSync) {
      this.log(LogType.Warning, Activity.Notifications, "" + notification.info +
        " notification for gameId " + g.id + " not in sync. Refresh all games");
      this.refresh();
      return;
    }

    let newEloWhite: number;
    let newEloBlack: number;

    if (notification.by === g.White) {
      newEloWhite = notification.byElo;
      newEloBlack = notification.otherElo;
    } else {
      newEloWhite = notification.otherElo;
      newEloBlack = notification.byElo;
    }

    this.updateUserData(g, newEloWhite, newEloBlack, null);

    if (g.id == this.gameIdInGameView()) {
      this._gameVM.handleMoveNotification(notification);
    } else {
      this.showMessage(`game against ${notification.by} got updated`, LogType.Info);

      g.updateGameFromNotification(notification);
    }

    this._gamesVM.placeGameInSection(g);

    g.Version = notification.game.Version;
  }

  private _handleWebPushNotification(payload: IWebPushNotificationPayload): void {

    try {
      switch (payload.type) {
        case "chat":
          let notificationChat: IChatNotification = payload.content;
          this._handleChatNotification(payload.message, notificationChat);
          break;

        case "move":
          // received for moves, takebacks, draw offers, challenges, rejected challenges, rejected draw offers,
          // resign, accept draw, expired games
          let notificationMove: IGameNotification = payload.content;
          this._handleMoveNotification(notificationMove);
          break;

        case "analysis":
          let notificationAnalysis: IAnalysisNotification = payload.content;
          this._handleAnalysisNotification(notificationAnalysis);
          break;

        default:
          this.showMessage(`Unexpected notification of type ${payload.type}. ${payload.message}`, LogType.Error, Activity.Notifications);
          break;
      }
    } catch (e) {
      console.error(`exception in handing webpush notification: ${JSON.stringify(e)}`)
    }
  }

  // #endregion Notifications

  // #region WebNotifications

  private _urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  private _popNotification() {

    let n: Notification = new Notification('Title', {
      body: 'here is my body',
      icon: '/images/120/Knight_white.png',
      image: '/images/archive.pgn'
    });

    n.addEventListener("error", evt => {
      console.log('something went wrong!!!');
    });

    n.addEventListener("click", evt => {
      console.log('notification clicked!!!');
      n.close();
    });
  }

  private async _registerSubscription(s: string) {

    let self = this;
    let j = JSON.parse(s);

    let success = await Zumo.registerWebPushSubscriptionAsync(this.currentUser.UserName, j.endpoint, j.keys.p256dh, j.keys.auth);

    if (success) {
      console.log(`PUSH REGISTRATION: registered with the backend. Set up a listener`);
    } else {
      console.error(`PUSH REGISTRATION: failed to register with the backend!`);
    }

    navigator.permissions.query({ name: 'notifications' }).then(function (permission) {
      // Initial status is available at permission.state

      permission.onchange = function () {
        // Whenever there's a change, updated status is available at this.state
        console.log(`PERMISSION CHANGED. ${Notification.permission}`);
        if (Notification.permission !== "granted") {
          self.showPersistentMsg(true);
        }
      };
    });
  }

  private async _checkForWebNotifications() {

    if (Notification.permission !== "granted") {
      this.showPersistentMsg(true);
    } else {
      this._registerForWebNotifications();
    }
  }

  private async showPushNotificationsClick() {
    this.showPersistentMsg(false);

    let self = this;

    let perm: NotificationPermission = await Notification.requestPermission();

    if (perm !== "granted") {
      self.showPersistentMsg(true);
      return;
    }

    self._registerForWebNotifications();
  }

  private async _registerForWebNotifications() {

    let self = this;

    console.log('Trying to register for web push notifications');

    // get keys from https://web-push-codelab.glitch.me/
    let publicKey = 'BCNLxZjdaEPLVtOcdDOD1q7iYmSSKlCbBETh-5PMAOUGam3IVHoqVrSYYH09sGn4CfcszkQ36zQL-6ymjyXnPew';

    try {
      let sw: ServiceWorkerRegistration = await navigator.serviceWorker.ready;

      console.log(`PUSH REGISTRATION: Got the service worker registration ${sw.scope}`);

      let push: PushSubscription = await sw.pushManager.getSubscription();

      if (push) {
        // we have a subscription!
        console.log(`PUSH REGISTRATION: subscription available: ${JSON.stringify(push)}`);
      } else {
        push = await sw.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: self._urlB64ToUint8Array(publicKey)
        });
        console.log(`PUSH REGISTRATION: new subscription: ${JSON.stringify(push)}`);
      }
      self._registerSubscription(JSON.stringify(push));

    } catch (e) {
      console.error(`PUSH REGISTRATION: exception in registration for push notifications. ${e}`);
    }
  }

  // #endregion WebNotifications

  public async refreshAll(): Promise<void> {
    console.info(`refresh all games`);

    this._gamesVM.clearLocalGames();
    this._gamesVM.refreshAsync(true, true);
  }

  public async refresh(): Promise<void> {

    let self: Orchestrator = this;

    let success: boolean = await self._gamesVM.refreshAsync(false, true);

    if (success) {
      self._chatVM.refresh();
    }
  }

  public onPause(): void {
    this._gamesVM.saveGamesToLocal();
  }

  public dump(): string {

    let obj: any = {
      currentUser: this.currentUser,
      currentUserName: this.currentUserName,
      version: this.version,
      registeredForNotifications: this._registeredForNotifications,
      gameIdInGameView: this.gameIdInGameView(),

      gamesPage: this._gamesVM.getObjectToDump(),
      gamePage: this._gameVM.getObjectToDump(),

      logs: this._logVM !== null ? this._logVM.logs() : null
    };

    return JSON.stringify(obj);
  }

  public showGames(): void {
    this._loginModal.hide();

    // Now we can init the picture cache as we have the current user name
    this._PicCache = new PicCache();

    this.currentUserAvatar(this._PicCache.getUserAvatar(this.currentUser.UserName));

    this._checkForWebNotifications();

    this._gamesVM.activate();
  }

  public activatePage(page: string): void {

    switch (page) {
      case Orchestrator.Page_Login:
        //this.discussionsCollection.clear();
        this._loginModal.show();
        this._loginVM.activate();
        break;
      case Orchestrator.Page_NewGame:
        this._newGameVM.activate();
        break;
      case Orchestrator.Page_Settings:
        this._settingsVM.activate();
        break;
      case Orchestrator.Page_Log:
        this._logVM.activate();
        break;
      case Orchestrator.Page_Error:
        this._errorVM.activate();
        break;
      default:
        alert('cannot activate page ' + page);
        break;
    }
  }

  public activateGame(id: number): void {

    document.getElementById(Orchestrator.Page_Game).classList.remove("hidden");
    document.getElementById('msgList').classList.remove("hidden");
    document.getElementById('textInput').classList.remove("hidden");

    let g: Game = this._gamesVM.getGame(id);

    if (g === null) {
      this.showMessage("trying to activate game " + id + " that is not found...", LogType.Error, Activity.ActivateGame);
      return;
    }

    this.gameIdInGameView(id);

    this._gameVM.activate(g);
  }

  public activateChat(g: Game): void {
    this._chatVM.activate(g);
  }

  public inviteToWatch(g: Game): void {
    this._inviteToWatchModal.show();
    this._inviteToWatchVM.activate(g);
  }

  public refreshGameInGameView(): void {
    if (this.gameIdInGameView() === 0) {
      this.log(LogType.Error, Activity.Refresh, "Attempting to refresh the game in the game view, but no game is there!");
      return;
    }
    this._gameVM.refresh();
  }

  public redrawGameInGameView(): void {
    this._gameVM?.redrawBoard();
  }

  public updateUserData(game: Game, newEloWhite: number, newEloBlack: number, user: IUser): void {

    if (game.EloWhite !== newEloWhite) {
      this._gamesVM.updateActiveGamesForNewElo(game.White, newEloWhite);

      if (game.White === this.currentUserName()) {
        this.currentUser.CurrentElo = newEloWhite;
        this.currentUserElo(newEloWhite.toString());
      }
    }

    if (game.EloBlack !== newEloBlack) {
      this._gamesVM.updateActiveGamesForNewElo(game.Black, newEloBlack);

      if (game.Black === this.currentUserName()) {
        this.currentUser.CurrentElo = newEloBlack;
        this.currentUserElo(newEloBlack.toString());
      }
    }

    if (user !== null) {
      this.currentUser = user;
    }
  }

  public placeGameInSection(g: Game): void {
    this._gamesVM.placeGameInSection(g);
  }

  public getGame(id: number): Game {
    return this._gamesVM.getGame(id);
  }

  public getOpponents(): Array<string> {
    return this._gamesVM.getOpponents();
  }

  public showMessage(msg: string, type: LogType, activity?: Activity): void {

    let self: Orchestrator = this;

    if (activity) {
      self.log(type, activity, msg);
    }
    self.toastInfo(msg);
    self._toast.show();
  }

  private _showHelpMessage(helpLoc: HelpTopicLocation): void {

    this._showHelp(true);
    this._helpMsg(helpLoc.message);
    this._helpLocation(helpLoc.location);
  }

  public dismissHelp(): void {
    this._helpMsg("");
    this._helpLocation(HelpTopic.Nowhere);
    this._showHelp(false);
  }

  public showHelpTopic(id: number): void {

    if (this._helpLocation() !== HelpTopic.Nowhere) {
      // some other help topic is being displayed. Not a good time
      return;
    }

    let helpLoc: HelpTopicLocation = this._help.getTopicById(id);

    if (helpLoc.location === HelpTopic.Nowhere) {
      return;
    }

    this._showHelpMessage(helpLoc);
  }

  public resetHelp(): void {
    this._help.reset();
  }

  public showProgress(on: boolean): void {
    this._showProgressIndicator(on);
  }

  // #region YesNo dialog

  public okYesNoDlgClick(data: any, event: any): void {
    this._yesNoModal.hide();

    if (this._yesNoCallback !== null) {
      this._yesNoCallback(event.currentTarget.id === "yes");
      this._yesNoCallback = null;
    }
  }

  public showYesNoDlg(caption: string, message: string, callback: (yesClicked: boolean) => void): void {
    this._yesNoCaption(caption);
    this._yesNoContent(message);

    this._yesNoCallback = callback;

    this._yesNoModal.show();
  }

  // #endregion YesNo dialog

  // #region Promotion dialog

  public showPromotionChoices(): void {
    this._promoteModal.show();
  }

  public promoteClick(data: any, event: any): void {

    let pieceType: PieceType;

    switch (event.currentTarget.id) {
      case "Q-tr":
        pieceType = PieceType.Queen;
        break;
      case "R-tr":
        pieceType = PieceType.Rook;
        break;
      case "N-tr":
        pieceType = PieceType.Knight;
        break;
      case "B-tr":
        pieceType = PieceType.Bishop;
        break;
    }

    this._promoteModal.hide();
    this._gameVM.promotePawn(pieceType);
  }

  // #endregion Promotion dialog


  // #region Zumo

  private _updateApiCallback(activity: Activity, gameId: number, dataReceived: IDataReceived): void {

    let g: Game = this.getGame(gameId);

    let statusCode: ZumoCodes = ZumoCodes.UnexpectedResult;

    if (dataReceived === null || dataReceived.statusCode !== ZumoCodes.Success) {

      statusCode = dataReceived.statusCode;

      g.cloudCall = CloudCall.None;

      if (this.gameIdInGameView() === gameId) {
        this._gameVM.zumoCompleted();
      }
      this.showMessage(`Failed. Error ${Zumo.getErrorDetails(statusCode)}`, LogType.Error, activity);
      return;
    }

    if (g.cloudCall !== CloudCall.AskRematch && dataReceived.game.id != gameId) {
      throw `Received a callback for game id: ${dataReceived.game.id} while expecting one for game id: ${gameId}`;
    }

    let user: IUser = this.currentUserName() === dataReceived.user1.UserName ? dataReceived.user1 : dataReceived.user2;

    let newEloWhite: number;
    let newEloBlack: number;

    if (g.White === dataReceived.user1.UserName) {
      newEloWhite = dataReceived.user1.CurrentElo;
      newEloBlack = (dataReceived.user2 !== null ? dataReceived.user2.CurrentElo : 0);
    } else {
      newEloWhite = (dataReceived.user2 !== null ? dataReceived.user2.CurrentElo : 0);
      newEloBlack = dataReceived.user1.CurrentElo;
    }

    this.updateUserData(g, newEloWhite, newEloBlack, user);

    this.showProgress(false);

    if (this.gameIdInGameView() === gameId) {
      this._gameVM.updateTakeBack();

      if (dataReceived.game.Completed !== Completed.NotCompleted) {
        this.showHelpTopic(4); // show they can rematch
      }
    }

    if (g.cloudCall !== CloudCall.AskRematch) {
      g.updateGameFromCloud(dataReceived.game);
    }

    this.placeGameInSection(dataReceived.game);

    if (this.gameIdInGameView() === gameId) {
      if (g.cloudCall !== CloudCall.AskRematch) {
        this._gameVM.zumoCompleted();
      } else {
        this.activateGame(dataReceived.game.id);
      }
    }
  }

  public async zumoCallAsync(g: Game, api: string): Promise<boolean> {

    this.showProgress(true);

    let self: Orchestrator = this;

    let dataSent: any;
    let activity: Activity;
    let errorMsg: string;

    switch (api) {
      case "move":
        dataSent = <IMoveDataSent>{};

        let moveToSubmit: Move = g.getMove(g.MovesCount);

        dataSent.MoveToDisplay = moveToSubmit.toVisualNotation(false);

        dataSent.Move = moveToSubmit.toNotation();

        if (moveToSubmit.state === MoveState.Mate) {
          dataSent.Completed = (g.MovesCount % 2 === 0) ? Completed.BlackMated : Completed.WhiteMated;
        } else if (moveToSubmit.state === MoveState.Draw) {
          dataSent.Completed = Completed.Draw;
        } else {
          dataSent.Completed = Completed.NotCompleted;
        }

        activity = Activity.Move;
        errorMsg = "Failed to submit move.";
        break;

      case "resignAcceptDraw":
        dataSent = <IResignAcceptDrawDataSent>{
          AcceptDraw: false
        };
        if (g.cloudCall === CloudCall.AcceptDraw) {
          dataSent.AcceptDraw = true;
          activity = Activity.AcceptDrawOffer;
          errorMsg = "Failed to accept draw offer.";
        } else {
          activity = Activity.Resign;
          errorMsg = "Failed to resign game.";
        }

        break;

      case "abandonGame":
        dataSent = <IBasicGameDataSent>{};
        activity = Activity.Abandon;
        errorMsg = "Failed to abandon game.";
        break;

      case "offerDraw":
        dataSent = <IBasicGameDataSent>{};
        activity = Activity.OfferDraw;
        errorMsg = "Failed to offer draw.";
        break;

      case "takeBack":
        dataSent = <IBasicGameDataSent>{};
        activity = Activity.TakeBack;
        errorMsg = "Failed to take back move.";
        break;

      case "resignAcceptDraw":
        dataSent = <IBasicGameDataSent>{};
        activity = Activity.AcceptDrawOffer;
        errorMsg = "Failed to accept draw.";
        break;

      case "rejectDrawOffer":
        dataSent = <IBasicGameDataSent>{};
        activity = Activity.RejectDrawOffer;
        errorMsg = "Failed to reject the draw.";
        break;

      case "rejectChallenge":
        dataSent = <IBasicGameDataSent>{};
        activity = Activity.RejectChallenge;
        errorMsg = "Failed to reject the challenge.";
        break;
    }

    let dataReceived: IDataReceived = await Zumo.gamePostAsync(g, dataSent, api);
    if (dataReceived.statusCode !== ZumoCodes.Success) {
      self.showMessage(`${errorMsg}. Error: ${dataReceived.message}`, LogType.Error, activity);
      return false;
    }
    self._updateApiCallback(activity, g.id, dataReceived);
    return true;
  }

  public async askRematch(g: Game): Promise<void> {

    this.showProgress(true);

    let self: Orchestrator = this;

    let dataSent: IChallengeDataSent = { Machine: Zumo.machine, Opponent: g.opponent(), Rated: g.Rated, AsWhite: !g.currentPlayerIsWhite };

    let dataReceived: IDataReceived = <IDataReceived>await Zumo.postCallAsync<IChallengeDataSent>('challengeGame', dataSent);

    if (dataReceived.statusCode !== ZumoCodes.Success) {
      self.showMessage(`Failed to ask for rematch. Error: ${dataReceived.message}`, LogType.Error, Activity.AskRematch);
      return;
    }
    self._updateApiCallback(Activity.AskRematch, g.id, dataReceived);
  }

  public async markFavoriteGameAsync(g: Game, favorite: boolean): Promise<boolean> {

    let self: Orchestrator = this;

    let dataSent: IFavoriteGameSent = { Machine: Zumo.machine, gameId: g.id, favorite: favorite };

    let dataReceived: IFavoriteGameReceived = <IFavoriteGameReceived>await Zumo.postCallAsync<IFavoriteGameSent>('favoriteGame', dataSent);

    if (dataReceived.statusCode !== ZumoCodes.Success) {
      self.showMessage(`Failed to mark favorite game. Error: ${dataReceived.message}`, LogType.Error, Activity.MarkFavorite);
      return false;
    }

    g.favorite(favorite);
    self._gamesVM.placeOrRemoveFavoriteGame(g);
    return true;
  }
  // #endregion Zumo

  public getActiveGamesCount(): number {
    return this._gamesVM.getActiveGamesCount();
  }

  public log(type: LogType, activity: Activity, details: string): void {
    switch (type) {
      case LogType.Info:
        console.log("Activity: " + activity + ". Info: " + details);
        break;
      case LogType.Warning:
        console.log("Activity: " + activity + ". Warning: " + details);
        break;
      case LogType.Error:
        console.log("Activity: " + activity + ". Error: " + details);
        break;
      case LogType.AppLifecycle:
        console.log("Activity: " + activity + ". App lifecycle: " + details);
        break;
    }

    this._logVM.log(type, activity, details);
  }

  public saveUserInfo(user: IUser): void {

    this.currentUser = user;
    this.currentUserName(user.UserName);
    this.currentUserElo(user.CurrentElo.toString());

    if (this._PicCache != null) {
      this.currentUserAvatar(this._PicCache.getUserAvatar(user.UserName));
    }

    // set the current user name for the Game class
    Game.CurrentUser = user.UserName;

    // set the current user name for the Discussion classes
    Discussion.currentUserName = user.UserName;
    DiscussionsCollection.currentUserName = user.UserName;

    window.localStorage.setItem("userInfo", JSON.stringify(user));
  }

  public updateUserCountry(countryCode: string): void {
    this._PicCache.updateUserCountry(this.currentUser.UserName, countryCode);
  }


  // #region Avatars

  public getUserAvatar(userName: string): UserAvatar {
    let ua: UserAvatar = this._PicCache.getUserAvatar(userName);
    return ua;
  }

  public async updateAvatarsAsync(): Promise<void> {
    let users = `${this.currentUser.UserName},${this._gamesVM.getAllOpponents()}`
    await this._PicCache.updateAvatarsAsync(users);
    this.currentUserAvatar(this._PicCache.getUserAvatar(this.currentUser.UserName));
  }
  // #endregion Avatars
}
