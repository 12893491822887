import { Coord } from "./coord";
import { CloudCall, Completed, Game } from "./game";
import { Move, MoveInfo, MoveState } from "./move";
import { Color, Piece, PieceType } from "./piece";

export interface IGameCallbacks {
  that: any;
  updateMaterial: (diff: Array<number>) => void;
  castling: (coordFrom: Coord, coordTo: Coord) => void;
  simpleMove: (move: Move, reverse: boolean, animate: boolean) => void;
  showTransformChoices: (color: Color) => void;
}

class PieceLocations {
  piece: Piece;
  private _locations: Array<Coord>;

  constructor(piece: Piece) {
    this.piece = piece;

    this._locations = [];
  }

  addLocation(location: Coord): void {
    this._locations[this._locations.length] = location;
  }

  removeLocation(location: Coord): void {

    let index: number = -1;

    for (let i: number = 0; i < this._locations.length; i++) {
      if (this._locations[i].equals(location)) {
        index = i;
        break;
      }
    }

    if (index === -1) {
      throw "could not find this location in the current entry!";
    }

    this._locations.splice(index, 1);
  }

  getFirstLocation(): Coord {
    if (this._locations.length === 0) {
      throw "no location available";
    }

    return this._locations[0];
  }

  getOtherLocations(location: Coord): Array<Coord> {

    let locations: Array<Coord> = this._locations.slice(0); // make a copy of the locations

    let index: number = -1;

    for (let i: number = 0; i < this._locations.length; i++) {
      if (this._locations[i].equals(location)) {
        index = i;
        break;
      }
    }

    if (index === -1) {
      throw "could not find this location in the current entry!";
    }

    locations.splice(index, 1);

    return locations;
  }

  getLocationsCount(): number {
    return this._locations.length;
  }
}

class PieceLocationsDictionary {

  private _pieceLocations: Array<PieceLocations> = [];

  constructor() {
    this._pieceLocations[0] = new PieceLocations(Piece.WhitePawn);
    this._pieceLocations[1] = new PieceLocations(Piece.WhiteRook);
    this._pieceLocations[2] = new PieceLocations(Piece.WhiteBishop);
    this._pieceLocations[3] = new PieceLocations(Piece.WhiteKnight);
    this._pieceLocations[4] = new PieceLocations(Piece.WhiteQueen);
    this._pieceLocations[5] = new PieceLocations(Piece.WhiteKing);

    this._pieceLocations[6] = new PieceLocations(Piece.BlackPawn);
    this._pieceLocations[7] = new PieceLocations(Piece.BlackRook);
    this._pieceLocations[8] = new PieceLocations(Piece.BlackBishop);
    this._pieceLocations[9] = new PieceLocations(Piece.BlackKnight);
    this._pieceLocations[10] = new PieceLocations(Piece.BlackQueen);
    this._pieceLocations[11] = new PieceLocations(Piece.BlackKing);
  }

  addPiece(piece: Piece, location: Coord): void {

    for (let i: number = 0; i < this._pieceLocations.length; i++) {
      if (this._pieceLocations[i].piece.equals(piece)) {
        this._pieceLocations[i].addLocation(location);
        return;
      }
    }
  }

  removePiece(piece: Piece, location: Coord): void {

    for (let i: number = 0; i < this._pieceLocations.length; i++) {
      if (this._pieceLocations[i].piece.equals(piece)) {
        this._pieceLocations[i].removeLocation(location);
        return;
      }
    }

    throw "could not find the piece in the dictionary!";
  }

  getPieceFirstLocation(piece: Piece): Coord {
    for (let i: number = 0; i < this._pieceLocations.length; i++) {
      if (this._pieceLocations[i].piece.equals(piece)) {

        return this._pieceLocations[i].getFirstLocation();
      }
    }
    return null;
  }

  getPieceOtherLocations(piece: Piece, coord: Coord): Array<Coord> {
    for (let i: number = 0; i < this._pieceLocations.length; i++) {
      if (this._pieceLocations[i].piece.equals(piece)) {

        return this._pieceLocations[i].getOtherLocations(coord);
      }
    }
    return null;
  }

  countLocations(piece: Piece): number {
    for (let i: number = 0; i < this._pieceLocations.length; i++) {
      if (this._pieceLocations[i].piece.equals(piece)) {

        return this._pieceLocations[i].getLocationsCount();
      }
    }
    return 0;
  }
}

export class ChessLogic {

  private _game: Game;
  private _callbacks: IGameCallbacks;

  private _whatIfMode: boolean;

  private _moveId: number;
  private _blackKingMoved: number;
  private _blackRookLongMoved: number;
  private _blackRookShortMoved: number;

  private _whiteKingMoved: number;
  private _whiteRookLongMoved: number;
  private _whiteRookShortMoved: number;
  private _pieceLocationsDictionary: PieceLocationsDictionary;
  private _promoMove: Move;

  private _matrix: Array<Array<Piece>>;
  private _matrixSim: Array<Array<Piece>>;

  constructor(game: Game, callbacks: IGameCallbacks) {
    this._game = game;
    this._callbacks = callbacks;

    this._whatIfMode = false;

    this._moveId = 0;
    this._blackKingMoved = -1;
    this._blackRookLongMoved = -1;
    this._blackRookShortMoved = -1;

    this._whiteKingMoved = -1;
    this._whiteRookLongMoved = -1;
    this._whiteRookShortMoved = -1;

    this._pieceLocationsDictionary = new PieceLocationsDictionary();

    this._promoMove = null;

    let color: Color = Color.White;
    let type: PieceType;

    this._matrix = new Array(8);
    this._matrixSim = new Array(8);

    for (let r: number = 0; r < 8; r++) {
      this._matrix[r] = new Array(8);
      this._matrixSim[r] = new Array(8);

      for (let c: number = 0; c < 8; c++) {
        this._matrix[r][c] = null;

        type = null;

        if (r === 0 || r === 7) {
          color = ((r === 0) ? Color.Black : Color.White);

          switch (c) {
            case 0:
            case 7:
              type = PieceType.Rook;
              break;
            case 1:
            case 6:
              type = PieceType.Knight;
              break;
            case 2:
            case 5:
              type = PieceType.Bishop;
              break;
            case 3:
              type = PieceType.Queen;
              break;
            case 4:
              type = PieceType.King;
              break;
          }

        } else if (r === 1 || r === 6) {
          color = ((r === 1) ? Color.Black : Color.White);
          type = PieceType.Pawn;
        }

        if (type !== null) {
          let piece: Piece = new Piece(type, color);

          this._addPieceToDictionary(piece, new Coord(r, c));
        }
      }
    }

    // clean extra moves
    if (this._game.cloudCall === CloudCall.None) {
      this._game.eraseExtraMoves();
    }
  }

  public getObjectToDump(): any {

    let obj: any = {
      whatIfMode: this._whatIfMode,
      moveId: this._moveId,
      matrix: this._matrix,
      matrixSim: this._matrixSim
    };

    return obj;
  }

  private _addPieceToDictionary(piece: Piece, coord: Coord): void {

    this._pieceLocationsDictionary.addPiece(piece, coord);
    this._matrix[coord.r][coord.c] = piece;
  }

  private _removePieceFromDictionary(piece: Piece, coord: Coord): void {

    if (!this._matrix[coord.r][coord.c].equals(piece)) {
      throw "Board inconsistency!";
    }

    this._pieceLocationsDictionary.removePiece(piece, coord);

    this._matrix[coord.r][coord.c] = null;
  }

  public goToMove(moveId: number): void {

    if (moveId > this._moveId) {
      while (moveId > this._moveId) {
        this.forwardMove(false);
      }
    } else {
      while (moveId < this._moveId) {
        this.backOneMove(false);
      }
    }
  }

  public currentMove(): number {
    return this._moveId;
  }

  public takeBackLastMove(): void {
    this.goToMove(this._game.MovesCount - 1);
  }

  public goToEnd(): void {
    if (!this.canGoForward()) {
      return;
    }

    while (this._moveId < this._game.totalMovesCount()) {
      this.forwardMove(false);
    }
    this.backOneMove(false);
    this.forwardMove(true);
  }

  public goToBeginning(): void {
    while (this.canGoBack()) {
      this.backOneMove(false);
    }
  }

  public setWhatIfMode(value: boolean): void {

    if (value === this._whatIfMode) {
      return;
    }

    let syncPosition: number;

    if (this._game.Completed === Completed.NotCompleted) {
      syncPosition = this._game.MovesCount;
    } else {
      syncPosition = this._game.getExtraMoveStartPosition();
    }

    if (syncPosition !== -1) {
      // go back to the last move on the backend
      while (this._moveId < syncPosition) {
        this.forwardMove(false);
      }

      while (this._moveId > syncPosition) {
        this.backOneMove(false);
      }
    }

    this._game.eraseExtraMoves();

    if (value === true) {
      this._game.setExtraMoveStartPosition(this._moveId);
    }

    this._whatIfMode = value;
  }

  public canGoBack(): boolean {

    if (this._moveId === 0) {
      return false;
    }

    if (this._whatIfMode) {
      let startPosition: number = this._game.getExtraMoveStartPosition();

      return this._moveId > startPosition && startPosition !== -1;
    }

    return true;

  }

  public canGoForward(): boolean {
    return (this._moveId < this._game.totalMovesCount());
  }

  public shouldShowSubmit(): boolean {

    if (this._game.watchedGame) {
      return false;
    }

    if (this._game.Completed !== Completed.NotCompleted || this._game.cloudCall !== CloudCall.None) {
      return false;
    }

    if (this._whatIfMode || this._game.totalMovesCount() === 0 || this._game.DrawOffered) {
      return false;
    }

    return (this._moveId === this._game.MovesCount + 1) && this._game.currentPlayerToMove();
  }

  public getPiece(coord: Coord): Piece {
    return this._matrix[coord.r][coord.c];
  }

  public playerToMove(): Color {
    return (this._moveId % 2 === 0) ? Color.White : Color.Black;
  }

  public isMoveAllowed(): boolean {
    if (this._whatIfMode) {

      if (this._game.Completed === Completed.NotCompleted) {
        return (this._moveId >= this._game.MovesCount);
      }

      return true;
    }

    if (this._moveId === this._game.MovesCount && this._game.currentPlayerToMove()) {
      return true;
    }
    return false;
  }

  public getKingLocation(king: Piece): Coord {
    return this._pieceLocationsDictionary.getPieceFirstLocation(king);
  }

  public transformPiece(pieceTypeTransformed: PieceType): void {
    this.movePiece(this._promoMove.origin, this._promoMove.destination, pieceTypeTransformed);
  }

  public movePiece(origin: Coord, destination: Coord, pieceTypeTransformed: PieceType): void {

    let move: Move = new Move(this._moveId, origin, destination, this._matrix[origin.r][origin.c], null, null, null);

    if (pieceTypeTransformed === null && this._isPromotion(move)) {
      return;
    }

    move.pieceRemoved = this._matrix[destination.r][destination.c];

    if (move.pieceRemoved !== null) {
      move.destinationRemoved = destination;
    }

    // check for en-passant
    if (this._matrix[origin.r][origin.c].type === PieceType.Pawn &&
      origin.c !== destination.c &&
      this._matrix[destination.r][destination.c] === null) {

      move.extraInfo = MoveInfo.EnPassant;
      move.pieceRemoved = this._matrix[origin.r][destination.c];
      move.destinationRemoved = new Coord(origin.r, destination.c);
    }

    // check for promotion!
    if (pieceTypeTransformed !== null) {
      this._promoMove = null;

      move.pieceAtDestination = new Piece(pieceTypeTransformed, move.piece.color);

      switch (pieceTypeTransformed) {

        case PieceType.Queen:
          move.extraInfo = MoveInfo.TransformQueen;
          break;
        case PieceType.Rook:
          move.extraInfo = MoveInfo.TransformRook;
          break;
        case PieceType.Bishop:
          move.extraInfo = MoveInfo.TransformBishop;
          break;
        case PieceType.Knight:
          move.extraInfo = MoveInfo.TransformKnight;
          break;
      }
    }

    // check for ambiguity
    if (this._isTargetForTheOtherPieces(move.piece, origin, destination)) {
      move.extraInfo = MoveInfo.Ambiguous;
    }

    // update the matrix.
    if (move.pieceRemoved !== null) {
      // this takes care of both the normal moves as well as en-passant!
      this._removePieceFromDictionary(move.pieceRemoved, move.destinationRemoved);
    }

    switch (move.extraInfo) {

      case MoveInfo.TransformQueen:
      case MoveInfo.TransformRook:
      case MoveInfo.TransformKnight:
      case MoveInfo.TransformBishop:
        this._addPieceToDictionary(move.pieceAtDestination, move.destination);
        break;

      default:
        this._addPieceToDictionary(move.piece, move.destination);
        break;
    }

    this._removePieceFromDictionary(move.piece, move.origin);

    this._handleCastling(move);

    let kingToCheck: Piece = new Piece(PieceType.King, move.piece.color === Color.White ? Color.Black : Color.White);

    let checkedKingCoord: Coord = this._kingInCheckForSimulation(null, null, kingToCheck);

    if (move.boardMask === null) {
      move.boardMask = this._getBoardMask();
    }

    if (checkedKingCoord !== null) {

      if (this._noMoreMovesPossible(kingToCheck.color)) {
        move.state = MoveState.Mate;
      } else {
        move.state = MoveState.Check;
      }
    } else {
      // check for draw positions
      this._checkForDraw(move);

      // check for stalemate
      if (this._noMoreMovesPossible(kingToCheck.color)) {
        move.state = MoveState.Draw;
      }
    }

    // remove all the moves that are made irrelevant by this move
    this._game.eraseExtraMoves(this._moveId);

    this._game.addExtraMove(move, this._moveId);

    this._updateMaterial();

    this._moveId++;

    // update the VisualBoard
    if (this._callbacks.simpleMove !== null) {
      this._callbacks.simpleMove.bind(this._callbacks.that)(move, false, true);
    }
  }

  public forwardMove(animate: boolean): void {
    if (this._moveId === this._game.totalMovesCount()) {
      return;
    }

    let move: Move = this._game.getMove(this._moveId);

    // update the dictionary
    if (move.pieceRemoved !== null) {
      this._removePieceFromDictionary(move.pieceRemoved, move.destinationRemoved);
    }

    if (move.pieceAtDestination !== null) {
      this._addPieceToDictionary(move.pieceAtDestination, move.destination);
    } else {
      this._addPieceToDictionary(move.piece, move.destination);
    }

    this._removePieceFromDictionary(move.piece, move.origin);

    // handle castling
    this._handleCastling(move);

    this._updateMaterial();

    this._moveId++;

    // update the VisualBoard
    if (this._callbacks.simpleMove !== null) {
      this._callbacks.simpleMove.bind(this._callbacks.that)(move, false, animate);
    }

    if (move.boardMask === null) {
      move.boardMask = this._getBoardMask();
    }
  }

  public backOneMove(animate: boolean): void {
    if (!this.canGoBack()) {
      return;
    }

    this._moveId--;

    let move: Move = this._game.getMove(this._moveId);

    // update the dictionary
    if (this._matrix[move.origin.r][move.origin.c] !== null) {
      throw "Board is not in the expected state!";
    }

    this._addPieceToDictionary(move.piece, move.origin);

    if (move.pieceAtDestination !== null) {
      this._removePieceFromDictionary(move.pieceAtDestination, move.destination);
    } else {
      this._removePieceFromDictionary(move.piece, move.destination);
    }

    if (move.pieceRemoved !== null) {
      this._addPieceToDictionary(move.pieceRemoved, move.destinationRemoved);
    }

    this._handleReverseCastling(move);

    // update the VisualBoard
    if (this._callbacks.simpleMove !== null) {
      this._callbacks.simpleMove.bind(this._callbacks.that)(move, true, animate);
    }

    this._updateMaterial();
  }

  private _updateMaterial(): void {

    let diff: Array<number> = []; // Q, R, B, N, P

    diff[0] = this._pieceLocationsDictionary.countLocations(Piece.WhiteQueen) -
      this._pieceLocationsDictionary.countLocations(Piece.BlackQueen);
    diff[1] = this._pieceLocationsDictionary.countLocations(Piece.WhiteRook) -
      this._pieceLocationsDictionary.countLocations(Piece.BlackRook);
    diff[2] = this._pieceLocationsDictionary.countLocations(Piece.WhiteBishop) -
      this._pieceLocationsDictionary.countLocations(Piece.BlackBishop);
    diff[3] = this._pieceLocationsDictionary.countLocations(Piece.WhiteKnight) -
      this._pieceLocationsDictionary.countLocations(Piece.BlackKnight);
    diff[4] = this._pieceLocationsDictionary.countLocations(Piece.WhitePawn) -
      this._pieceLocationsDictionary.countLocations(Piece.BlackPawn);

    if (this._callbacks.updateMaterial !== null) {
      this._callbacks.updateMaterial.bind(this._callbacks.that)(diff);
    }
  }

  private _getBoardMask(): Array<number> {
    let mask: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0];

    for (let r: number = 0; r < 8; r++) {

      mask[r] = Piece.getMask(this._matrix[r][0]) + Piece.getMask(this._matrix[r][1]) * 16 +
        Piece.getMask(this._matrix[r][2]) * 256 + Piece.getMask(this._matrix[r][3]) * 4069 +
        Piece.getMask(this._matrix[r][4]) * 65536 + Piece.getMask(this._matrix[r][5]) * 1048576 +
        Piece.getMask(this._matrix[r][6]) * 16777216 + Piece.getMask(this._matrix[r][7]) * 268435456;
    }

    return mask;
  }

  // #region Castling

  private _handleCastling(move: Move): void {
    if (this._whiteKingMoved >= this._moveId) {
      this._whiteKingMoved = -1;
    }

    if (this._whiteRookShortMoved >= this._moveId) {
      this._whiteRookShortMoved = -1;
    }

    if (this._whiteRookLongMoved >= this._moveId) {
      this._whiteRookLongMoved = -1;
    }

    if (this._blackKingMoved >= this._moveId) {
      this._blackKingMoved = -1;
    }

    if (this._blackRookShortMoved >= this._moveId) {
      this._blackRookShortMoved = -1;
    }

    if (this._blackRookLongMoved >= this._moveId) {
      this._blackRookLongMoved = -1;
    }

    if (move.piece.type !== PieceType.King) {
      return;
    }

    if (move.piece.color === Color.White) {
      if (move.origin.equals(Coord.e1)) {
        if (move.destination.equals(Coord.g1)) {

          // move the h1 rook.
          this._addPieceToDictionary(this._matrix[7][7], Coord.f1);
          this._removePieceFromDictionary(this._matrix[7][7], Coord.h1);

          move.extraInfo = MoveInfo.CastleKingSide;

          if (this._callbacks.castling !== null) {
            this._callbacks.castling.bind(this._callbacks.that)(Coord.h1, Coord.f1);
          }
        } else if (move.destination.equals(Coord.c1)) {
          // move the a1 rook.
          this._addPieceToDictionary(this._matrix[7][0], Coord.d1);
          this._removePieceFromDictionary(this._matrix[7][0], Coord.a1);

          move.extraInfo = MoveInfo.CastleQueenSide;

          if (this._callbacks.castling !== null) {
            this._callbacks.castling.bind(this._callbacks.that)(Coord.a1, Coord.d1);
          }
        }
      }
    } else {
      if (move.origin.equals(Coord.e8)) {
        if (move.destination.equals(Coord.g8)) {
          // move the h8 rook.
          this._addPieceToDictionary(this._matrix[0][7], Coord.f8);
          this._removePieceFromDictionary(this._matrix[0][7], Coord.h8);

          move.extraInfo = MoveInfo.CastleKingSide;

          if (this._callbacks.castling !== null) {
            this._callbacks.castling.bind(this._callbacks.that)(Coord.h8, Coord.f8);
          }
        } else if (move.destination.equals(Coord.c8)) {
          // move the a8 rook.
          this._addPieceToDictionary(this._matrix[0][0], Coord.d8);
          this._removePieceFromDictionary(this._matrix[0][0], Coord.a8);

          move.extraInfo = MoveInfo.CastleQueenSide;

          if (this._callbacks.castling !== null) {
            this._callbacks.castling.bind(this._callbacks.that)(Coord.a8, Coord.d8);
          }
        }
      }
    }

    // update castle data
    if (move.origin.equals(Coord.e1)) {
      if (this._whiteKingMoved === -1) {
        this._whiteKingMoved = this._moveId;
      }
    } else if (move.origin.equals(Coord.a1)) {
      if (this._whiteRookLongMoved === -1) {
        this._whiteRookLongMoved = this._moveId;
      }
    } else if (move.origin.equals(Coord.h1)) {
      if (this._whiteRookShortMoved === -1) {
        this._whiteRookShortMoved = this._moveId;
      }
    } else if (move.origin.equals(Coord.e8)) {
      if (this._blackKingMoved === -1) {
        this._blackKingMoved = this._moveId;
      }
    } else if (move.origin.equals(Coord.a8)) {
      if (this._blackRookLongMoved === -1) {
        this._blackRookLongMoved = this._moveId;
      }
    } else if (move.origin.equals(Coord.h8)) {
      if (this._blackRookShortMoved === -1) {
        this._blackRookShortMoved = this._moveId;
      }
    }
  }

  private _handleReverseCastling(move: Move): void {
    if (move.piece.type !== PieceType.King) {
      return;
    }

    if (move.piece.color === Color.White) {
      switch (move.extraInfo) {
        case MoveInfo.CastleKingSide: {
          this._addPieceToDictionary(this._matrix[7][5], Coord.h1);
          this._removePieceFromDictionary(this._matrix[7][5], Coord.f1);

          if (this._callbacks.castling !== null) {
            this._callbacks.castling.bind(this._callbacks.that)(Coord.f1, Coord.h1);
          }
          break;
        }
        case MoveInfo.CastleQueenSide: {
          this._addPieceToDictionary(this._matrix[7][3], Coord.a1);
          this._removePieceFromDictionary(this._matrix[7][3], Coord.d1);

          if (this._callbacks.castling !== null) {
            this._callbacks.castling.bind(this._callbacks.that)(Coord.d1, Coord.a1);
          }
          break;
        }
      }
    } else {
      switch (move.extraInfo) {
        case MoveInfo.CastleKingSide: {
          this._addPieceToDictionary(this._matrix[0][5], Coord.h8);
          this._removePieceFromDictionary(this._matrix[0][5], Coord.f8);

          if (this._callbacks.castling !== null) {
            this._callbacks.castling.bind(this._callbacks.that)(Coord.f8, Coord.h8);
          }
          break;
        }
        case MoveInfo.CastleQueenSide: {
          this._addPieceToDictionary(this._matrix[0][3], Coord.a8);
          this._removePieceFromDictionary(this._matrix[0][3], Coord.d8);

          if (this._callbacks.castling !== null) {
            this._callbacks.castling.bind(this._callbacks.that)(Coord.d8, Coord.a8);
          }
          break;
        }
      }
    }
  }

  // #endregion Catling

  private _isPromotion(move: Move): boolean {
    if (move.piece.type !== PieceType.Pawn) {
      return false;
    }

    if (move.piece.color === Color.White && move.destination.r === 0) {
      this._promoMove = move;
      if (this._callbacks.showTransformChoices !== null) {
        this._callbacks.showTransformChoices.bind(this._callbacks.that)(Color.White);
      }
      return true;
    }

    if (move.piece.color === Color.Black && move.destination.r === 7) {
      this._promoMove = move;
      if (this._callbacks.showTransformChoices !== null) {
        this._callbacks.showTransformChoices.bind(this._callbacks.that)(Color.Black);
      }
      return true;
    }

    return false;
  }

  // #region Check

  private _checkForDraw(move: Move): void {
    // check for three way repetition
    let repetitions: number = 0;

    for (let i: number = move.moveId - 1; i >= 0; i--) {
      if (ChessLogic.areBoardMasksEqual(this._game.getMove(i).boardMask, move.boardMask)) {
        repetitions++;

        if (repetitions === 2) {
          move.state = MoveState.Draw;
          return;
        }
      }
    }

    if (this._pieceLocationsDictionary.countLocations(Piece.WhitePawn) !== 0 ||
      this._pieceLocationsDictionary.countLocations(Piece.BlackPawn) !== 0 ||
      this._pieceLocationsDictionary.countLocations(Piece.WhiteQueen) !== 0 ||
      this._pieceLocationsDictionary.countLocations(Piece.BlackQueen) !== 0 ||
      this._pieceLocationsDictionary.countLocations(Piece.WhiteRook) !== 0 ||
      this._pieceLocationsDictionary.countLocations(Piece.BlackRook) !== 0) {

      // pawns, queens or rooks are on the board.
      return;
    }

    if (this._pieceLocationsDictionary.countLocations(Piece.WhiteBishop) > 1 ||
      this._pieceLocationsDictionary.countLocations(Piece.BlackBishop) > 1) {

      // at least two bishops for a given player are on the board.
      return;
    }

    if (this._pieceLocationsDictionary.countLocations(Piece.WhiteKnight) > 2 ||
      this._pieceLocationsDictionary.countLocations(Piece.BlackKnight) > 2) {

      // at least three nights for a given player are on the board.
      return;
    }

    if (this._pieceLocationsDictionary.countLocations(Piece.WhiteKnight) > 0 &&
      this._pieceLocationsDictionary.countLocations(Piece.WhiteBishop) > 0 ||
      this._pieceLocationsDictionary.countLocations(Piece.BlackKnight) > 0 &&
      this._pieceLocationsDictionary.countLocations(Piece.BlackBishop) > 0) {

      // at least one night and a bishop for a given player are on the board.
      return;
    }

    move.state = MoveState.Draw;
  }

  private _removeBadTargets(targets: Array<Coord>, coord: Coord, kingToCheck: Piece): void {

    for (let i: number = targets.length - 1; i >= 0; i--) {
      if (this._kingInCheckForSimulation(coord, targets[i], kingToCheck) !== null) {
        // remove the target
        targets.splice(i, 1);
      }
    }
  }

  private _noMoreMovesPossible(color: Color): boolean {

    // go through all the pieces and see if they can be moved
    for (let r: number = 0; r < 8; r++) {
      for (let c: number = 0; c < 8; c++) {
        let piece: Piece = this._matrix[r][c];

        if (piece !== null && piece.color === color) {
          let pieceCoord: Coord = new Coord(r, c);

          let targets: Coord[] = this.getTargets(pieceCoord);

          if (targets.length > 0) {
            return false;
          }
        }
      }
    }

    return true;
  }

  private _isInCheckForPawn(coord: Coord, kingToCheck: Piece): boolean {

    if (kingToCheck.color === Color.White) {
      if (coord.r === 0) {
        return false;
      }

      // check pawns advancing South
      if (coord.c > 0 && Piece.BlackPawn.equals(this._matrixSim[coord.r - 1][coord.c - 1])) {
        return true;
      }

      if (coord.c < 7 && Piece.BlackPawn.equals(this._matrixSim[coord.r - 1][coord.c + 1])) {
        return true;
      }
    } else {
      if (coord.r === 7) {
        return false;
      }

      // check pawns advancing North
      if (coord.c > 0 && Piece.WhitePawn.equals(this._matrixSim[coord.r + 1][coord.c - 1])) {
        return true;
      }

      if (coord.c < 7 && Piece.WhitePawn.equals(this._matrixSim[coord.r + 1][coord.c + 1])) {
        return true;
      }
    }

    return false;
  }

  private _isInCheckForKnight(coord: Coord, kingToCheck: Piece): boolean {
    let knightToCheck: Piece;

    if (kingToCheck.color === Color.White) {
      knightToCheck = Piece.BlackKnight;
    } else {
      knightToCheck = Piece.WhiteKnight;
    }

    if (coord.r >= 2) {
      if (coord.c >= 1 && knightToCheck.equals(this._matrixSim[coord.r - 2][coord.c - 1])) {
        return true;
      }

      if (coord.c < 7 && knightToCheck.equals(this._matrixSim[coord.r - 2][coord.c + 1])) {
        return true;
      }
    }

    if (coord.r >= 1) {
      if (coord.c >= 2 && knightToCheck.equals(this._matrixSim[coord.r - 1][coord.c - 2])) {
        return true;
      }

      if (coord.c < 6 && knightToCheck.equals(this._matrixSim[coord.r - 1][coord.c + 2])) {
        return true;
      }
    }

    if (coord.r < 7) {
      if (coord.c >= 2 && knightToCheck.equals(this._matrixSim[coord.r + 1][coord.c - 2])) {
        return true;
      }

      if (coord.c < 6 && knightToCheck.equals(this._matrixSim[coord.r + 1][coord.c + 2])) {
        return true;
      }
    }

    if (coord.r < 6) {
      if (coord.c >= 1 && knightToCheck.equals(this._matrixSim[coord.r + 2][coord.c - 1])) {
        return true;
      }

      if (coord.c < 7 && knightToCheck.equals(this._matrixSim[coord.r + 2][coord.c + 1])) {
        return true;
      }
    }

    return false;
  }

  private _isInCheckForRQB(coord: Coord, kingToCheck: Piece, inc: Coord): boolean {
    // checks if the passed in King is in check by a Rook, Queen or Bishop
    //
    // coord - The coordinates of the kingToCheck.</param>
    // kingToCheck - The kingToCheck. Either "KW" or "KB".</param>
    // inc - The row and column increment. Either -1, 0 or 1.</param>
    //
    // returns true if the king is in check, false otherwise.</returns>

    let test: Coord = new Coord(coord.r + inc.r, coord.c + inc.c);

    while (test.r >= 0 && test.r <= 7 && test.c >= 0 && test.c <= 7) {
      if (this._matrixSim[test.r][test.c] === null) {
        // keep going...
        test.r += inc.r;
        test.c += inc.c;
        continue;
      }

      // we encountered a piece. Check if it's one of our own.
      if (this._matrixSim[test.r][test.c].color === kingToCheck.color) {
        // it is one of own. Not in check.
        return false;
      }

      // it is an enemy piece!
      if (inc.r * inc.c === 0) {
        // we're checking North, South, East or West.
        // make sure a Rook or Queen are not there.
        if (this._matrixSim[test.r][test.c].type === PieceType.Queen ||
          this._matrixSim[test.r][test.c].type === PieceType.Rook) {

          return true;
        } else {
          return false;
        }
      } else {
        // we're checking diagonally.
        // make sure a Rook or Queen are not there.
        if (this._matrixSim[test.r][test.c].type === PieceType.Queen ||
          this._matrixSim[test.r][test.c].type === PieceType.Bishop) {

          return true;
        } else {
          return false;
        }
      }
    }

    return false;
  }

  private _isInCheckForKing(coord: Coord, kingToCheck: Piece): boolean {

    let otherKing: Piece = null;

    if (kingToCheck.color === Color.White) {
      otherKing = Piece.BlackKing;
    } else {
      otherKing = Piece.WhiteKing;
    }

    if (coord.r > 0) {
      if (coord.c > 0) {
        if (otherKing.equals(this._matrixSim[coord.r - 1][coord.c - 1])) {
          return true;
        }
      }

      if (coord.c < 7) {
        if (otherKing.equals(this._matrixSim[coord.r - 1][coord.c + 1])) {
          return true;
        }
      }

      if (otherKing.equals(this._matrixSim[coord.r - 1][coord.c])) {
        return true;
      }
    }

    if (coord.r < 7) {
      if (coord.c > 0) {
        if (otherKing.equals(this._matrixSim[coord.r + 1][coord.c - 1])) {
          return true;
        }
      }

      if (coord.c < 7) {
        if (otherKing.equals(this._matrixSim[coord.r + 1][coord.c + 1])) {
          return true;
        }
      }

      if (otherKing.equals(this._matrixSim[coord.r + 1][coord.c])) {
        return true;
      }
    }

    if (coord.c > 0) {
      if (otherKing.equals(this._matrixSim[coord.r][coord.c - 1])) {
        return true;
      }
    }

    if (coord.c < 7) {
      if (otherKing.equals(this._matrixSim[coord.r][coord.c + 1])) {
        return true;
      }
    }

    return false;
  }

  private _kingInCheckForSimulation(coord: Coord, coordNew: Coord, kingToCheck: Piece): Coord {

    let kingCoord: Coord = null;

    // copy the matrix and identify the king to check
    for (let i: number = 0; i < 8; i++) {
      for (let j: number = 0; j < 8; j++) {
        this._matrixSim[i][j] = this._matrix[i][j];

        if (kingToCheck.equals(this._matrix[i][j])) {
          kingCoord = new Coord(i, j);
        }
      }
    }

    if (!Coord.equalCoords(coord, coordNew)) {

      // special case for en-passant
      if (this._matrix[coord.r][coord.c].type === PieceType.Pawn &&
        this._matrix[coordNew.r][coordNew.c] === null &&
        (Math.abs(coordNew.r - coord.r) - Math.abs(coordNew.c - coord.c) === 0)) {

        this._matrixSim[coord.r][coordNew.c] = null;
      }

      // move the piece to the destination
      this._matrixSim[coordNew.r][coordNew.c] = this._matrix[coord.r][coord.c];

      this._matrixSim[coord.r][coord.c] = null;

      // update the king's coordinates if the moved piece is actually the king.
      if (kingToCheck.equals(this._matrixSim[coordNew.r][coordNew.c])) {
        kingCoord = new Coord(coordNew.r, coordNew.c);
      }
    }

    // now see if the king is in check by a pawn or a knight
    if (this._isInCheckForPawn(kingCoord, kingToCheck) ||
      this._isInCheckForKnight(kingCoord, kingToCheck) ||
      this._isInCheckForKing(kingCoord, kingToCheck)) {

      return kingCoord;
    }

    // check for Rooks, Queens and Bishops
    if (this._isInCheckForRQB(kingCoord, kingToCheck, Coord.CoordN) ||
      this._isInCheckForRQB(kingCoord, kingToCheck, Coord.CoordNW) ||
      this._isInCheckForRQB(kingCoord, kingToCheck, Coord.CoordNE) ||
      this._isInCheckForRQB(kingCoord, kingToCheck, Coord.CoordW) ||
      this._isInCheckForRQB(kingCoord, kingToCheck, Coord.CoordE) ||
      this._isInCheckForRQB(kingCoord, kingToCheck, Coord.CoordS) ||
      this._isInCheckForRQB(kingCoord, kingToCheck, Coord.CoordSW) ||
      this._isInCheckForRQB(kingCoord, kingToCheck, Coord.CoordSE)) {

      return kingCoord;
    }

    return null;
  }

  // #endregion Check

  // #region Targets

  private _addTargetsForDirection(coord: Coord, coord_inc: Coord, coordinates: Array<Coord>): void {

    let r_test: number = coord.r + coord_inc.r;
    let c_test: number = coord.c + coord_inc.c;

    let coordsIndex: number = coordinates.length;

    while (r_test >= 0 && r_test <= 7 && c_test >= 0 && c_test <= 7) {

      if (this._matrix[r_test][c_test] === null || this._matrix[r_test][c_test].color !== this._matrix[coord.r][coord.c].color) {

        coordinates[coordsIndex++] = new Coord(r_test, c_test);

        if (this._matrix[r_test][c_test] !== null) {
          break;
        }
      } else {
        break;
      }
      r_test += coord_inc.r;
      c_test += coord_inc.c;
    }
  }

  private _getTargetsForKing(coord: Coord, isInCheck: boolean): Array<Coord> {

    let coordinates: Array<Coord> = [];

    let coordsIndex: number = 0;

    let king: Piece = this._matrix[coord.r][coord.c];

    if (coord.r > 0 && (this._matrix[coord.r - 1][coord.c] === null ||
      this._matrix[coord.r - 1][coord.c].color !== king.color)) {
      coordinates[coordsIndex++] = new Coord(coord.r - 1, coord.c);
    }

    if (coord.r > 0 && coord.c > 0 && (this._matrix[coord.r - 1][coord.c - 1] === null ||
      this._matrix[coord.r - 1][coord.c - 1].color !== king.color)) {
      coordinates[coordsIndex++] = new Coord(coord.r - 1, coord.c - 1);
    }

    if (coord.c > 0 && (this._matrix[coord.r][coord.c - 1] === null ||
      this._matrix[coord.r][coord.c - 1].color !== king.color)) {
      coordinates[coordsIndex++] = new Coord(coord.r, coord.c - 1);
    }

    if (coord.r < 7 && coord.c > 0 && (this._matrix[coord.r + 1][coord.c - 1] === null ||
      this._matrix[coord.r + 1][coord.c - 1].color !== king.color)) {
      coordinates[coordsIndex++] = new Coord(coord.r + 1, coord.c - 1);
    }

    if (coord.r < 7 && (this._matrix[coord.r + 1][coord.c] === null ||
      this._matrix[coord.r + 1][coord.c].color !== king.color)) {
      coordinates[coordsIndex++] = new Coord(coord.r + 1, coord.c);
    }

    if (coord.r < 7 && coord.c < 7 && (this._matrix[coord.r + 1][coord.c + 1] === null ||
      this._matrix[coord.r + 1][coord.c + 1].color !== king.color)) {
      coordinates[coordsIndex++] = new Coord(coord.r + 1, coord.c + 1);
    }

    if (coord.c < 7 && (this._matrix[coord.r][coord.c + 1] === null ||
      this._matrix[coord.r][coord.c + 1].color !== king.color)) {
      coordinates[coordsIndex++] = new Coord(coord.r, coord.c + 1);
    }

    if (coord.r > 0 && coord.c < 7 && (this._matrix[coord.r - 1][coord.c + 1] === null ||
      this._matrix[coord.r - 1][coord.c + 1].color !== king.color)) {
      coordinates[coordsIndex++] = new Coord(coord.r - 1, coord.c + 1);
    }

    if (isInCheck) {
      return coordinates;
    }

    // need to check for castle options!
    if (king.color === Color.White && (this._whiteKingMoved === -1 || this._whiteKingMoved >= this._moveId)) {

      if ((this._whiteRookShortMoved === -1 ||
        this._whiteRookShortMoved >= this._moveId) && this._matrix[7][5] === null && this._matrix[7][6] === null) {

        // we can potentially castle short. Need to simulate and see if we're not passing through check!
        if (this._kingInCheckForSimulation(Coord.e1, Coord.f1, Piece.WhiteKing) === null &&
          this._kingInCheckForSimulation(Coord.e1, Coord.g1, Piece.WhiteKing) === null) {

          coordinates[coordsIndex++] = Coord.g1;
        }
      }

      if ((this._whiteRookLongMoved === -1 || this._whiteRookLongMoved >= this._moveId) &&
        this._matrix[7][1] === null && this._matrix[7][2] === null && this._matrix[7][3] === null) {

        // we can potentially castle long. Need to simulate and see if we're not passing through check!
        if (this._kingInCheckForSimulation(Coord.e1, Coord.d1, Piece.WhiteKing) === null &&
          this._kingInCheckForSimulation(Coord.e1, Coord.c1, Piece.WhiteKing) === null) {

          coordinates[coordsIndex++] = Coord.c1;
        }
      }
    }

    if (king.color === Color.Black && (this._blackKingMoved === -1 || this._blackKingMoved >= this._moveId)) {

      if ((this._blackRookShortMoved === -1 || this._blackRookShortMoved >= this._moveId) &&
        this._matrix[0][5] === null && this._matrix[0][6] === null) {

        // we can potentially castle short. Need to simulate and see if we're not passing through check!
        if (this._kingInCheckForSimulation(Coord.e8, Coord.f8, Piece.BlackKing) === null &&
          this._kingInCheckForSimulation(Coord.e8, Coord.g8, Piece.BlackKing) === null) {

          coordinates[coordsIndex++] = Coord.g8;
        }
      }

      if ((this._blackRookLongMoved === -1 || this._blackRookLongMoved >= this._moveId) && this._matrix[0][1] === null &&
        this._matrix[0][2] === null && this._matrix[0][3] === null) {

        // we can potentially castle long. Need to simulate and see if we're not passing through check!
        if (this._kingInCheckForSimulation(Coord.e8, Coord.d8, Piece.BlackKing) === null &&
          this._kingInCheckForSimulation(Coord.e8, Coord.c8, Piece.BlackKing) === null) {

          coordinates[coordsIndex++] = Coord.c8;
        }
      }
    }

    return coordinates;
  }

  private _getTargetsForBishop(coord: Coord): Array<Coord> {

    let coordinates: Array<Coord> = [];

    // check on the first quadrant
    this._addTargetsForDirection(coord, Coord.CoordNE, coordinates);

    // check on the second quadrant
    this._addTargetsForDirection(coord, Coord.CoordNW, coordinates);

    // check on the third quadrant
    this._addTargetsForDirection(coord, Coord.CoordSW, coordinates);

    // check on the fourth quadrant
    this._addTargetsForDirection(coord, Coord.CoordSE, coordinates);

    return coordinates;
  }

  private _getTargetsForKnight(coord: Coord): Array<Coord> {

    let coordinates: Array<Coord> = [];

    let coordsIndex: number = 0;

    if (coord.r >= 2) {
      if (coord.c >= 1) {
        if (this._matrix[coord.r - 2][coord.c - 1] === null ||
          this._matrix[coord.r][coord.c].color !== this._matrix[coord.r - 2][coord.c - 1].color) {

          coordinates[coordsIndex++] = new Coord(coord.r - 2, coord.c - 1);
        }
      }

      if (coord.c < 7) {

        if (this._matrix[coord.r - 2][coord.c + 1] === null ||
          this._matrix[coord.r][coord.c].color !== this._matrix[coord.r - 2][coord.c + 1].color) {

          coordinates[coordsIndex++] = new Coord(coord.r - 2, coord.c + 1);
        }
      }
    }

    if (coord.r >= 1) {
      if (coord.c >= 2) {

        if (this._matrix[coord.r - 1][coord.c - 2] === null ||
          this._matrix[coord.r][coord.c].color !== this._matrix[coord.r - 1][coord.c - 2].color) {

          coordinates[coordsIndex++] = new Coord(coord.r - 1, coord.c - 2);
        }
      }

      if (coord.c < 6) {

        if (this._matrix[coord.r - 1][coord.c + 2] === null ||
          this._matrix[coord.r][coord.c].color !== this._matrix[coord.r - 1][coord.c + 2].color) {

          coordinates[coordsIndex++] = new Coord(coord.r - 1, coord.c + 2);
        }
      }
    }

    if (coord.r < 7) {
      if (coord.c >= 2) {

        if (this._matrix[coord.r + 1][coord.c - 2] === null ||
          this._matrix[coord.r][coord.c].color !== this._matrix[coord.r + 1][coord.c - 2].color) {

          coordinates[coordsIndex++] = new Coord(coord.r + 1, coord.c - 2);
        }
      }

      if (coord.c < 6) {

        if (this._matrix[coord.r + 1][coord.c + 2] === null ||
          this._matrix[coord.r][coord.c].color !== this._matrix[coord.r + 1][coord.c + 2].color) {

          coordinates[coordsIndex++] = new Coord(coord.r + 1, coord.c + 2);
        }
      }
    }

    if (coord.r < 6) {
      if (coord.c >= 1) {

        if (this._matrix[coord.r + 2][coord.c - 1] === null ||
          this._matrix[coord.r][coord.c].color !== this._matrix[coord.r + 2][coord.c - 1].color) {

          coordinates[coordsIndex++] = new Coord(coord.r + 2, coord.c - 1);
        }
      }

      if (coord.c < 7) {

        if (this._matrix[coord.r + 2][coord.c + 1] === null ||
          this._matrix[coord.r][coord.c].color !== this._matrix[coord.r + 2][coord.c + 1].color) {

          coordinates[coordsIndex++] = new Coord(coord.r + 2, coord.c + 1);
        }
      }
    }

    return coordinates;
  }

  private _getTargetsForRook(coord: Coord): Array<Coord> {

    let coordinates: Array<Coord> = [];

    // check on the first west
    this._addTargetsForDirection(coord, Coord.CoordN, coordinates);

    // check on the second east
    this._addTargetsForDirection(coord, Coord.CoordS, coordinates);

    // check on the third north
    this._addTargetsForDirection(coord, Coord.CoordE, coordinates);

    // check on the fourth south
    this._addTargetsForDirection(coord, Coord.CoordW, coordinates);

    return coordinates;
  }

  private _getTargetsForQueen(coord: Coord): Array<Coord> {

    let coordinates: Array<Coord> = [];

    // check on the first west
    this._addTargetsForDirection(coord, Coord.CoordN, coordinates);

    // check on the second east
    this._addTargetsForDirection(coord, Coord.CoordS, coordinates);

    // check on the third north
    this._addTargetsForDirection(coord, Coord.CoordE, coordinates);

    // check on the fourth south
    this._addTargetsForDirection(coord, Coord.CoordW, coordinates);

    // check on the first quadrant
    this._addTargetsForDirection(coord, Coord.CoordNE, coordinates);

    // check on the second quadrant
    this._addTargetsForDirection(coord, Coord.CoordNW, coordinates);

    // check on the third quadrant
    this._addTargetsForDirection(coord, Coord.CoordSW, coordinates);

    // check on the fourth quadrant
    this._addTargetsForDirection(coord, Coord.CoordSE, coordinates);

    return coordinates;
  }

  private _getTargetsForPawnMovingSouth(coord: Coord, prevMove: Move): Array<Coord> {

    let coordinates: Array<Coord> = [];

    let coordsIndex: number = 0;

    // see if the pawn can jump two cells
    if (coord.r === 1 && this._matrix[coord.r + 1][coord.c] === null && this._matrix[coord.r + 2][coord.c] === null) {
      coordinates[coordsIndex++] = new Coord(coord.r + 2, coord.c);
    }

    // see if it can advance one cell
    if (coord.r < 7 && this._matrix[coord.r + 1][coord.c] === null) {
      coordinates[coordsIndex++] = new Coord(coord.r + 1, coord.c);
    }

    // see if it can take on the right
    if (coord.r < 7 && coord.c < 7 && this._matrix[coord.r + 1][coord.c + 1] !== null &&
      this._matrix[coord.r + 1][coord.c + 1].color !== this._matrix[coord.r][coord.c].color) {
      coordinates[coordsIndex++] = new Coord(coord.r + 1, coord.c + 1);
    }

    // see if it can take on the left
    if (coord.r < 7 && coord.c > 0 && this._matrix[coord.r + 1][coord.c - 1] !== null &&
      this._matrix[coord.r + 1][coord.c - 1].color !== this._matrix[coord.r][coord.c].color) {
      coordinates[coordsIndex++] = new Coord(coord.r + 1, coord.c - 1);
    }

    // check for en-passant
    if (coord.r === 4 && prevMove.piece.type === PieceType.Pawn) {
      if (coord.c > 0) {
        // check on the left
        if (prevMove.origin.r === 6 && prevMove.destination.r === 4 &&
          prevMove.origin.c === coord.c - 1 && prevMove.destination.c === coord.c - 1) {
          coordinates[coordsIndex++] = new Coord(coord.r + 1, coord.c - 1);
        }
      }

      if (coord.c < 7) {
        // check on the right
        if (prevMove.origin.r === 6 && prevMove.destination.r === 4 &&
          prevMove.origin.c === coord.c + 1 && prevMove.destination.c === coord.c + 1) {
          coordinates[coordsIndex++] = new Coord(coord.r + 1, coord.c + 1);
        }
      }
    }

    return coordinates;
  }

  private _getTargetsForPawnMovingNorth(coord: Coord, prevMove: Move): Array<Coord> {

    let coordinates: Array<Coord> = [];

    let coordsIndex: number = 0;

    // see if the pawn can jump two cells
    if (coord.r === 6 && this._matrix[coord.r - 1][coord.c] === null && this._matrix[coord.r - 2][coord.c] === null) {
      coordinates[coordsIndex++] = new Coord(coord.r - 2, coord.c);
    }

    // see if it can advance one cell
    if (coord.r > 0 && this._matrix[coord.r - 1][coord.c] === null) {
      coordinates[coordsIndex++] = new Coord(coord.r - 1, coord.c);
    }

    // see if it can take on the right
    if (coord.r > 0 && coord.c < 7 && this._matrix[coord.r - 1][coord.c + 1] !== null &&
      this._matrix[coord.r - 1][coord.c + 1].color !== this._matrix[coord.r][coord.c].color) {
      coordinates[coordsIndex++] = new Coord(coord.r - 1, coord.c + 1);
    }

    // see if it can take on the left
    if (coord.r > 0 && coord.c > 0 && this._matrix[coord.r - 1][coord.c - 1] !== null &&
      this._matrix[coord.r - 1][coord.c - 1].color !== this._matrix[coord.r][coord.c].color) {
      coordinates[coordsIndex++] = new Coord(coord.r - 1, coord.c - 1);
    }

    // check for en-passant
    if (coord.r === 3 && prevMove.piece.type === PieceType.Pawn) {
      if (coord.c > 0) {
        // check on the left
        if (prevMove.origin.r === 1 && prevMove.destination.r === 3 &&
          prevMove.origin.c === coord.c - 1 && prevMove.destination.c === coord.c - 1) {
          coordinates[coordsIndex++] = new Coord(coord.r - 1, coord.c - 1);
        }
      }

      if (coord.c < 7) {
        // check on the right
        if (prevMove.origin.r === 1 && prevMove.destination.r === 3 &&
          prevMove.origin.c === coord.c + 1 && prevMove.destination.c === coord.c + 1) {
          coordinates[coordsIndex++] = new Coord(coord.r - 1, coord.c + 1);
        }
      }
    }

    return coordinates;
  }

  public getTargets(coord: Coord): Array<Coord> {

    let coordinates: Array<Coord> = null;

    let piece: Piece = this._matrix[coord.r][coord.c];

    if (piece === null) {
      throw "piece should have been selected";
    }

    let prevMove: Move = null;

    switch (piece.type) {
      case PieceType.Pawn:

        if (this._moveId > 0) {
          prevMove = this._game.getMove(this._moveId - 1);
        }

        if (piece.color === Color.White) {
          coordinates = this._getTargetsForPawnMovingNorth(coord, prevMove);
        } else {
          coordinates = this._getTargetsForPawnMovingSouth(coord, prevMove);
        }

        break;

      case PieceType.Knight:
        coordinates = this._getTargetsForKnight(coord);
        break;

      case PieceType.Bishop:
        coordinates = this._getTargetsForBishop(coord);
        break;

      case PieceType.Rook:
        coordinates = this._getTargetsForRook(coord);
        break;

      case PieceType.Queen:
        coordinates = this._getTargetsForQueen(coord);
        break;

      case PieceType.King:

        let isInCheck: boolean = false;

        if (this._moveId > 0) {
          prevMove = this._game.getMove(this._moveId - 1);
          isInCheck = (prevMove.state === MoveState.Check);
        }

        coordinates = this._getTargetsForKing(coord, isInCheck);
        break;
    }

    let kingToCheck: Piece = new Piece(PieceType.King, piece.color);

    this._removeBadTargets(coordinates, coord, kingToCheck);

    return coordinates;
  }

  private _isTargetForDirection(origin: Coord, coord_inc: Coord, destination: Coord): boolean {

    let r_test: number = origin.r + coord_inc.r;
    let c_test: number = origin.c + coord_inc.c;

    while (r_test !== destination.r || c_test !== destination.c) {

      if (this._matrix[r_test][c_test] !== null) {
        return false;
      }

      r_test += coord_inc.r;
      c_test += coord_inc.c;
    }

    if (r_test === destination.r && c_test === destination.c) {
      return true;
    }

    return false;
  }

  private _isTargetForBishop(origin: Coord, destination: Coord): boolean {

    if (destination.r === origin.r || destination.c === origin.c) {
      return false;
    }

    if (Math.abs(destination.r - origin.r) !== Math.abs(destination.c - origin.c)) {
      return false;
    }

    let coord_inc: Coord = new Coord((destination.r - origin.r) / Math.abs(destination.r - origin.r),
      (destination.c - origin.c) / Math.abs(destination.c - origin.c));

    return this._isTargetForDirection(origin, coord_inc, destination);
  }

  private _isTargetForRook(origin: Coord, destination: Coord): boolean {

    let coord_inc: Coord = null;

    if (origin.r === destination.r) {

      coord_inc = (origin.c < destination.c) ? Coord.CoordE : Coord.CoordW;

      if (this._isTargetForDirection(origin, coord_inc, destination)) {
        return true;
      }
    } else if (origin.c === destination.c) {
      coord_inc = (origin.r < destination.r) ? Coord.CoordS : Coord.CoordN;

      if (this._isTargetForDirection(origin, coord_inc, destination)) {
        return true;
      }
    }

    return false;
  }

  private _isTargetForKnight(origin: Coord, destination: Coord): boolean {

    let diff: Coord = new Coord(Math.abs(origin.r - destination.r), Math.abs(origin.c - destination.c));

    if (diff.r === 1 && diff.c === 2 || diff.r === 2 && diff.c === 1) {
      return true;
    }

    return false;
  }

  private _isTargetForTheOtherPieces(piece: Piece, origin: Coord, destination: Coord): boolean {

    if (piece.type === PieceType.King || piece.type === PieceType.Pawn) {
      return false;
    }

    let otherLocations: Coord[] = this._pieceLocationsDictionary.getPieceOtherLocations(piece, origin);

    if (otherLocations.length === 0) {
      return false;
    }

    for (let i: number = 0; i < otherLocations.length; i++) {

      let location: Coord = otherLocations[i];

      switch (piece.type) {
        case PieceType.Rook:
          if (this._isTargetForRook(location, destination)) {
            return true;
          }
          break;
        case PieceType.Knight:
          if (this._isTargetForKnight(location, destination)) {
            return true;
          }
          break;
        case PieceType.Bishop:
          if (this._isTargetForBishop(location, destination)) {
            return true;
          }
          break;
        case PieceType.Queen:
          if (this._isTargetForRook(location, destination) || this._isTargetForBishop(location, destination)) {
            return true;
          }
          break;
      }
    }

    return false;
  }

  // #endregion Targets

  // #region static methods

  static areBoardMasksEqual(mask1: Array<number>, mask2: Array<number>): boolean {
    for (let i: number = 0; i < 8; i++) {
      if (mask1[i] !== mask2[i]) {
        return false;
      }
    }

    return true;
  }

  // #endregion Static Methods
}
