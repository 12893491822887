
export class ChartView {

  private _canvas: HTMLCanvasElement;
  private _ctx: CanvasRenderingContext2D;

  private _width: number;
  private _height: number;
  private _marginX: number = 5;
  private _marginY: number = 5;

  private _values: Array<number> = [];
  private _minValue: number;
  private _maxValue: number;

  private _lowestHundred: number;
  private _highestHundred: number;
  private _rangeY: number;

  private static CHART_BKGRND = "#fafafa";
  private static AXIS_COLOR = "darkblue";
  private static MINOR_AXIS_COLOR = "#dadada";
  private static MINOR_AXIS_LABEL_COLOR = "#b0b0b0";

  private static VALUES_COLOR = "darkred";

  private static PERCENT_BOTTOM_BUFFER: number = .2; // 20% of the height at the bottom is left empty

  constructor(canvas: HTMLCanvasElement, containerWidth: number, containerHeight: number) {

    this._canvas = canvas;
    this._ctx = this._canvas.getContext("2d");

    this._canvas.width = containerWidth * window.devicePixelRatio;
    this._canvas.height = containerHeight * window.devicePixelRatio;
    this._canvas.style.width = containerWidth + "px";
    this._canvas.style.height = containerHeight + "px";

    this._ctx.scale(window.devicePixelRatio, window.devicePixelRatio);

    this._width = containerWidth;
    this._height = containerHeight;
  }

  private _drawValues(): void {

    if (this._values.length < 2) {
      return;
    }

    this._ctx.strokeStyle = ChartView.VALUES_COLOR;
    this._ctx.lineWidth = 2;

    this._ctx.beginPath();

    let stepX: number = (this._width - this._marginX * 2) / (this._values.length - 1);
    let y: number;

    y = this._marginY * 2 + (this._rangeY - (this._values[0] - this._lowestHundred) * this._rangeY / (this._highestHundred - this._lowestHundred));

    this._ctx.moveTo(this._marginX, y);

    for (let i: number = 1; i < this._values.length; i++) {

      y = this._marginY * 2 + (this._rangeY - (this._values[i] - this._lowestHundred) * this._rangeY / (this._highestHundred - this._lowestHundred));

      this._ctx.lineTo(this._marginX + stepX * i, y);
    }

    this._ctx.stroke();
  }

  private _drawMinorAxis(): void {

    let linesCount: number = 1 + Math.floor((this._highestHundred - this._lowestHundred) / 100);

    let crtHundred: number = this._lowestHundred;

    this._ctx.strokeStyle = ChartView.MINOR_AXIS_COLOR;
    this._ctx.fillStyle = ChartView.MINOR_AXIS_LABEL_COLOR;

    this._ctx.beginPath();

    for (let i: number = 0; i < linesCount; i++) {

      let y: number = this._marginY * 2 + (this._rangeY - (crtHundred - this._lowestHundred) * this._rangeY / (this._highestHundred - this._lowestHundred));

      this._ctx.moveTo(this._marginX, y);
      this._ctx.lineTo(this._width - this._marginX, y);

      this._ctx.fillText(crtHundred.toString(), this._marginX * 2, y + this._marginY * 2);

      crtHundred += 100;
    }

    this._ctx.stroke();
  }

  private _drawMainAxis(): void {
    this._ctx.fillStyle = ChartView.CHART_BKGRND;
    this._ctx.fillRect(0, 0, this._width, this._height);

    this._ctx.strokeStyle = ChartView.AXIS_COLOR;

    this._ctx.beginPath();
    this._ctx.moveTo(this._marginX / 2, this._marginY * 2);
    this._ctx.lineTo(this._marginX, this._marginY);
    this._ctx.lineTo(this._marginX * 1.5, this._marginY * 2);
    this._ctx.stroke();

    this._ctx.beginPath();
    this._ctx.moveTo(this._marginX, this._height - this._marginY / 2);
    this._ctx.lineTo(this._marginX, this._marginY);
    this._ctx.stroke();

    this._ctx.beginPath();
    this._ctx.moveTo(this._marginX / 2, this._height - this._marginY);
    this._ctx.lineTo(this._width - this._marginX, this._height - this._marginY);
    this._ctx.stroke();

    this._ctx.beginPath();
    this._ctx.moveTo(this._width - this._marginX * 2, this._height - this._marginY * 1.5);
    this._ctx.lineTo(this._width - this._marginX, this._height - this._marginY);
    this._ctx.lineTo(this._width - this._marginX * 2, this._height - this._marginY / 2);
    this._ctx.stroke();
  }

  public draw(): void {

    this._ctx.lineWidth = 1;

    this._drawMainAxis();
    this._drawMinorAxis();

    this._drawValues();
  }

  public setValues(values: Array<number>): void {
    this._values = values;

    if (this._values.length > 0) {
      this._minValue = this._values[0];
      this._maxValue = this._values[0];
    }

    for (let i: number = 0; i < this._values.length; i++) {
      if (this._values[i] < this._minValue) {
        this._minValue = this._values[i];
      }
      if (this._values[i] > this._maxValue) {
        this._maxValue = this._values[i];
      }
    }
    this._lowestHundred = Math.floor(this._minValue / 100) * 100;
    this._highestHundred = (1 + Math.floor(this._maxValue / 100)) * 100;
    this._rangeY = (this._height - this._marginY * 3) * (1 - ChartView.PERCENT_BOTTOM_BUFFER);
  }
}
