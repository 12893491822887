import { Orchestrator } from "./orchestrator";
import { Zumo } from "./zumo";

import * as ko from 'knockout';
import {
  Observable, ObservableArray, PureComputed
} from 'knockout';

export enum LogType {
  Info = 0,
  Warning = 1,
  Error = 2,
  AppLifecycle = 3,
  Local = 4
}

export enum Activity {

  //None = 0,
  Settings = 1,
  //AppStarted = 2,
  Login = 3,
  RegisterUser = 4,
  GetUserInfo = 5,
  LocalUser = 6,
  LocalAuthUser = 7,
  //AspectRatio = 8,
  //DevicePixelRatio = 9,
  //Version = 10,
  Notifications = 11,
  //NotificationHub = 12,
  //NotificationAndroid = 13,
  ChatNotification = 14,

  Resume = 15,

  ActivateGame = 16,
  GetGames = 17,
  GetDiscussions = 18,
  GetDiscussion = 19,

  Text = 20,

  Resign = 21,
  Abandon = 22,
  TakeBack = 23,
  OfferDraw = 24,
  AskRematch = 25,
  Share = 26,
  AcceptDrawOffer = 27,
  RejectChallenge = 28,
  NewGame = 29,
  NewChallenge = 30,

  NotImplemented = 31,

  Refresh = 32,

  Suspended = 33,

  DrawOffer = 34,

  Performance = 35,

  Exception = 36,
  ExceptionDump = 37,

  RejectDrawOffer = 38,

  Discussions = 39,

  Move = 40,
  Game = 41,

  Registration = 42,

  MarkFavorite = 43,

  ParseException = 44,
  NotationError = 45,

  GetAvatars = 46
}

export class LogEntry {

  id: number;

  appVersion: string;
  type: LogType;
  activity: Activity;
  time: Date;
  details: string;
  uploaded: boolean;

  constructor(id: number, appVersion: string, type: LogType, activity: Activity, details: string) {
    this.id = id;
    this.type = type;
    this.activity = activity;
    this.details = details;
    this.uploaded = false;

    this.appVersion = appVersion;

    let dateTime: Date = new Date();

    this.time = dateTime;
  }
}

export class LogVM {
  orchestrator: Orchestrator;

  logs: ObservableArray<LogEntry>;

  sessionId: number;

  lastId: number;

  static MAX_LOG_ENTRIES: number = 70;

  constructor(orchestrator: Orchestrator, sessionId: number) {
    this.orchestrator = orchestrator;
    this.sessionId = sessionId;

    this.logs = ko.observableArray([]);

    this.reset();
  }

  public reset(): void {
    this.lastId = 0;

    this.logs([]);

    this._loadLogs();
  }

  private _scrollListIntoView(): void {
    let logList: HTMLElement = document.getElementById("logList");

    logList.scrollTop = logList.scrollHeight;
  }

  private _loadLogs(): void {
    let logsString: string = window.localStorage.getItem(`${this.orchestrator.AppName}-logs`);

    if (logsString === null) {
      return;
    }

    try {
      this.logs(JSON.parse(logsString));

      if (this.logs() === null) {
        this.logs([]);
      }
    } catch (e) {
      this.logs([]);
      this.log(LogType.Error, Activity.ParseException, "Failed to parse the logs");
    }

    let len: number = this.logs().length;

    if (len > 0) {
      this.lastId = this.logs()[len - 1].id;
    }
  }

  private _saveLogs(): void {
    let ls: string = JSON.stringify(this.logs());

    window.localStorage.setItem(`${this.orchestrator.AppName}-logs`, ls);
  }

  public activate(): void {
    setTimeout(this._scrollListIntoView.bind(self), 5);
  }

  public okClick(): void {
  }

  private _getLogsToUpload(): Array<LogEntry> {

    let upload: Array<LogEntry> = [];

    for (let i: number = 0; i < this.logs().length; i++) {
      let log: LogEntry = this.logs()[i];

      if (!log.uploaded && log.type !== LogType.Local) {
        upload.push(log);
      }
    }

    return upload;
  }

  public log(type: LogType, activity: Activity, details: string): void {

    this.lastId++;

    let appVersion: string = this.orchestrator.version();

    this.logs.push(new LogEntry(this.lastId, appVersion, type, activity, details));

    if (this.logs().length > LogVM.MAX_LOG_ENTRIES) {
      this.logs.splice(0, 1);
    }

    this._saveLogs();
  }

  private _markUploadedLogs(uploaded: Array<LogEntry>): void {

    let j: number = 0;

    for (let i: number = 0; i < uploaded.length; i++) {

      while (j < this.logs().length) {
        if (this.logs()[j].id === uploaded[i].id) {
          break;
        }
        j++;
      }

      if (j >= this.logs().length) {
        break;
      }

      this.logs()[j].uploaded = true;
    }
  }

  public clearLogs(): void {

  }

  public sendLogs(): void {

    //// Remove for public builds
    // if (1) {
    //     return;
    // }

    let logs: Array<LogEntry> = this._getLogsToUpload();

    if (logs.length === 0) {
      return;
    }

    let self: LogVM = this;

    Zumo.sendLogs(this.sessionId, this.orchestrator.currentUserName(), logs,
      function (dataReceived: any): void {

        if (dataReceived === null) {
          console.log("Uploaded logs. No result back");
          return;
        }
        console.log("Uploaded logs. Entries processed: " + JSON.stringify(dataReceived));

        self._markUploadedLogs(logs);

        self._saveLogs();
      },

      function (err: string): void {
        console.log("Something went wrong :-( Details: " + err);
      });
  }
}
