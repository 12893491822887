import { Game } from "./game";
import { Activity, LogType } from "./logViewModel";
import { Move } from "./move";
import { Orchestrator } from "./orchestrator";
import { IChallengeDataSent, IDataReceived, IEmailInviteReceived, IEmailInviteSent, IJoinableGame, IJoinGameDataSent, INewGameSent, IRunQueryJoinableGamesReceived, Zumo, ZumoCodes } from "./zumo";

import * as ko from 'knockout';
import {
  Observable, ObservableArray, PureComputed
} from 'knockout';

export class NewGameVM {
  orchestrator: Orchestrator;

  rated = ko.observable(true);

  opponentType = ko.observable("any");

  showPlayerDoesNotExist = ko.observable(false);

  opponents = ko.observableArray([]);
  selectedOpponents = ko.observableArray([]);
  bots = ko.observable(true);

  joinableGames = ko.observableArray([]);

  opponent = ko.observable("");
  email = ko.observable("");

  pageInfo = ko.observable("");

  allowBotsHasFocus = ko.observable(true);
  aliasNameHasFocus = ko.observable(false);
  opponentsHasFocus = ko.observable(false);
  emailHasFocus = ko.observable(false);

  newGameErrorMsg = ko.observable('');

  constructor(orchestrator: Orchestrator) {
    this.orchestrator = orchestrator;

    let self: NewGameVM = this;

    this.allowBotsHasFocus.subscribe(function (focus: any): void {
      if (focus) {
        self.opponentType("any");
      }
    });

    this.aliasNameHasFocus.subscribe(function (focus: any): void {
      if (focus) {
        self.opponentType("player");
      }
    });

    this.opponentsHasFocus.subscribe(function (focus: any): void {
      if (focus) {
        self.opponentType("opponent");
      }
    });

    this.emailHasFocus.subscribe(function (focus: any): void {
      if (focus) {
        self.opponentType("email");
      }
    });
  }

  private _newGameError(message: string): void {
    this.orchestrator.log(LogType.Error, Activity.NewGame, `New game error: ${message}`);
    this.newGameErrorMsg(message);

    // show the login failed
    document.getElementById("newGameError").classList.remove("hidden");
    setTimeout(() => {
      document.getElementById("newGameError").classList.add("hidden");
      this.newGameErrorMsg('');
    }, 4000);
  }

  private _handleNewGameReceivedData(dataReceived: IDataReceived): void {

    this.orchestrator.showProgress(false);

    let status: ZumoCodes = dataReceived.statusCode;

    if (status !== ZumoCodes.Success) {
      this._newGameError(dataReceived.message);
      return;
    }

    this.orchestrator.dismissNewGame();

    this.orchestrator.placeGameInSection(dataReceived.game);

    this.orchestrator.activateGame(dataReceived.game.id);
  }

  private async _newGameAsync(rated: boolean): Promise<void> {

    this.orchestrator.showProgress(true);

    let self: NewGameVM = this;

    let dataSent: INewGameSent = {
      Machine: Zumo.machine,
      Rated: rated,
      SimilarOpponent: false,
      ComputerOpponent: self.bots(),
      NewGame: true
    };

    let dataReceived: IDataReceived = <IDataReceived>await Zumo.postCallAsync<INewGameSent>('newGame', dataSent);
    dataReceived.message = dataReceived.statusCode === ZumoCodes.Success ? null : `Failed to create a new game. Error: ${Zumo.getErrorDetails(dataReceived.statusCode)}`;
    self._handleNewGameReceivedData(dataReceived);
  }

  private async _newChallenge(opponent: string, rated: boolean, asWhite: boolean): Promise<void> {

    this.orchestrator.showProgress(true);

    let self: NewGameVM = this;

    let dataSent: IChallengeDataSent = { Machine: Zumo.machine, Opponent: opponent, Rated: rated, AsWhite: asWhite };

    let dataReceived: IDataReceived = <IDataReceived>await Zumo.postCallAsync<IChallengeDataSent>('challengeGame', dataSent);
    dataReceived.message = dataReceived.statusCode === ZumoCodes.Success ? null : `Failed to start a new challenge. Error: ${Zumo.getErrorDetails(dataReceived.statusCode)}`;

    self._handleNewGameReceivedData(dataReceived);
  }

  private async _inviteByEmail(): Promise<void> {

    this.orchestrator.showProgress(true);

    let self: NewGameVM = this;

    let dataSent: IEmailInviteSent = { Machine: Zumo.machine, Email: this.email(), From: '', Content: '' };

    let dataReceived: IEmailInviteReceived = <IEmailInviteReceived>await Zumo.postCallAsync<IEmailInviteSent>('sendEmailInvite', dataSent);

    if (dataReceived.statusCode === ZumoCodes.Success) {
      if (dataReceived.foundUserName.length > 0) {
        // send the invite as the user is already in the system!!!
        this._newChallenge(dataReceived.foundUserName, false, true);
      } else if (dataReceived.sent) {
        this._newGameError(`Invitation was sent by email. ${this.email()} is not yet playing on ChessM8.`);
      }
    } else {
      this._newGameError(`Failed to send the invitation! Error: ${Zumo.getErrorDetails(dataReceived.statusCode)}`);
    }
  }

  private async _updateJoinableGamesAsync(): Promise<void> {
    // query available games
    let dataReceived: IRunQueryJoinableGamesReceived = await Zumo.getJoinableGamesApiCallAsync();

    console.log(`received ${dataReceived.rows?.length} joinable games`);

    this.joinableGames.removeAll();

    dataReceived.rows.forEach(jg => {
      let now: Date = new Date();
      let dateDiff = (now.getTime() - jg.updatedAt.getTime()) / 1000; // in seconds

      if (dateDiff < 60) {
        jg.created = 'created moments ago';
      } else if (dateDiff < 60 * 60) {
        jg.created = `created ${Math.floor(dateDiff / 60)} minutes ago`;
      } else if (dateDiff < 60 * 60 * 24) {
        jg.created = `created ${Math.floor(dateDiff / 3600)} hours ago`;
      } else {
        jg.created = `created ${Math.floor(dateDiff / (60 * 60 * 24))} days ago`;
      }

      jg.Joinable = (jg.White != Game.CurrentUser);
      if (jg.Moves.length > 0) {
        jg.Moves = Move.toVisualFromBackendMove(jg.Moves);
      } else {
        jg.Moves = '-';
      }

      this.joinableGames.push(jg);
    });
  }

  public activate(): void {

    this.opponents(this.orchestrator.getOpponents());

    this._updateJoinableGamesAsync();

    this.pageInfo(this.orchestrator.getActiveGamesCount() + " current active games");
  }

  public async joinGameClick(jg: IJoinableGame) {
    console.log(`clicked on joining game against ${jg.White} ${jg.id}`);
    this.orchestrator.showProgress(true);

    let self: NewGameVM = this;

    let dataSent: IJoinGameDataSent = { Machine: Zumo.machine, gameId: jg.id };

    let dataReceived: IDataReceived = <IDataReceived>await Zumo.postCallAsync<IJoinGameDataSent>('matchGame', dataSent);
    dataReceived.message = dataReceived.statusCode === ZumoCodes.Success ? null : `Failed to join the game against ${jg.White}. Error: ${Zumo.getErrorDetails(dataReceived.statusCode)}`;

    self._handleNewGameReceivedData(dataReceived);
  }

  public startNewGameClick(): void {

    let rated: boolean = this.rated();
    let opponent: string;

    let newGameType = this.opponentType();

    switch (newGameType) {
      case 'opponent':
        opponent = this.selectedOpponents()[0];
        this._newChallenge(opponent, rated, true);
        break;
      case 'player':
        opponent = this.opponent();

        if (opponent.length === 0) {
          this._newGameError(`You need to type the alias of another player`);
        } else {
          this._newChallenge(opponent, rated, true);
        }
        break;
      case 'any':
        this._newGameAsync(rated);
        break;
      case 'email':
        this._inviteByEmail();
        break;
    }
  }
}
