import { Modal } from "bootstrap";
import { ChartView } from "./chartView";
import { Discussion } from "./discussions";
import { Completed, Game, IGame, IGameInfo } from "./game";
import { Activity, LogType } from "./logViewModel";
import { Orchestrator } from "./orchestrator";
import { IGetGamesReceived, IGetGamesSent, IUserAvatar, Zumo, ZumoCodes } from "./zumo";

import { Dropdown } from 'bootstrap';

import * as ko from 'knockout';
import {
  Observable, ObservableArray, PureComputed
} from 'knockout';
import { UserAvatar } from "./picFileCache";

export class DisplayGame {

  game: Game;
  discussion: Observable<Discussion>;
  showChat: PureComputed<boolean>;
  newChat: PureComputed<boolean>;

  alert = ko.observable(false);
  info = ko.observable("");

  eloChange: Observable<string>;

  opponent: UserAvatar;
  wAvatar: UserAvatar;
  bAvatar: UserAvatar;

  constructor(g: Game, disc: Discussion, orchestrator: Orchestrator) {
    this.game = g;

    this.wAvatar = orchestrator.getUserAvatar(g.White);
    this.bAvatar = orchestrator.getUserAvatar(g.Black);

    let country: string;

    if (g.opponent() == g.White) {
      this.opponent = this.wAvatar;
    } else {
      this.opponent = this.bAvatar;
    }

    this.discussion = ko.observable(disc);

    this.eloChange = ko.observable("");

    this.showChat = ko.computed(function (): boolean {
      return (this.discussion().id() !== "0");
    }, this);

    this.newChat = ko.computed(function (): boolean {
      return (this.discussion().id() !== "0" && (this.discussion().outOfSync() || this.discussion().userOutOfSync()));
    }, this);

    this.updateInfo();
  }

  public updateInfo(): void {

    let sd: IGameInfo = this.game.getInfo();

    this.info(sd.info);
    this.alert(sd.alert);
  }
}

export class GameSection {
  public id: number;
  public name: string;

  private displayGames: ObservableArray<DisplayGame>;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.displayGames = ko.observableArray([]);
  }

  public gamesCount(): number {
    return this.displayGames().length;
  }

  public clear(): void {
    this.displayGames.removeAll();
  }

  public remove(dg: DisplayGame) {
    this.displayGames.remove(dg);
  }

  public updateGamesStatus(): void {

    for (let i: number = 0; i < this.displayGames().length; i++) {

      let dg: DisplayGame = this.displayGames()[i];

      dg.updateInfo();
    }
  }

  public findDisplayGame(id: number): DisplayGame {

    for (let i: number = 0; i < this.displayGames().length; i++) {

      let dg: DisplayGame = this.displayGames()[i];

      if (dg.game.id == id) {
        return dg;
      }
    }
    return null;
  }

  public insertGame(dg: DisplayGame, ascending: boolean) {
    let i: number = 0;

    for (i = 0; i < this.displayGames().length; i++) {

      let dgc: DisplayGame = this.displayGames()[i];

      let newer: boolean = dg.game.updatedAt.getTime() > dgc.game.updatedAt.getTime();

      if (ascending && newer || !ascending && !newer) {
        this.displayGames.splice(i, 0, dg);
        break;
      }
    }

    if (i >= this.displayGames().length) {
      this.displayGames.push(dg);
    }
  }

  public updateGamesForNewElo(player: string, newElo: number): void {

    for (let i: number = 0; i < this.displayGames().length; i++) {
      let g: Game = this.displayGames()[i].game;

      if (g.White === player) {
        g.EloWhite = newElo;
      }

      if (g.Black === player) {
        g.EloBlack = newElo;
      }
    }
  }

  private _toEloDiff(eloChange: number): string {

    let eloDiff: string;

    if (eloChange === 0) {
      eloDiff = "►";
    } else if (eloChange < 0) {
      eloDiff = "▼";
      eloChange *= -1;
    } else {
      eloDiff = "▲";
    }

    eloDiff += eloChange.toString();

    return eloDiff;
  }

  public getEloPoints(): Array<number> {

    // This is called only for the completed games section

    let values: Array<number> = [];

    let dgArray: Array<DisplayGame> = this.displayGames();

    if (dgArray.length === 0) {
      return values;
    }

    let elo: number;
    let eloDiff: number;

    for (let i: number = dgArray.length - 1; i >= 0; i--) {

      elo = dgArray[i].game.currentPlayerIsWhite ? dgArray[i].game.EloWhite : dgArray[i].game.EloBlack;

      values.push(elo);

      eloDiff = 0;

      if (dgArray[i].game.EloDiff && dgArray[i].game.EloDiff !== null) {
        eloDiff = dgArray[i].game.EloDiff;

        if (!dgArray[i].game.currentPlayerIsWhite) {
          eloDiff = -eloDiff;
        }
      }

      dgArray[i].eloChange(this._toEloDiff(eloDiff));
    }

    return values;
  }

  public computeStatsVsOpponent(opponent: string): StatsVsOpponent {

    let dgArray: Array<DisplayGame> = this.displayGames();

    let stats: StatsVsOpponent = new StatsVsOpponent();

    for (let i: number = 0; i < dgArray.length; i++) {

      let game: Game = dgArray[i].game;

      if (game.White === opponent) {
        if (game.Completed === Completed.Draw) {
          stats.Draws(stats.Draws() + 1);
        } else if (game.Completed === Completed.WhiteDroppedOut || game.Completed === Completed.WhiteResigned ||
          game.Completed === Completed.WhiteMated || (game.Completed === Completed.Abandoned && game.MovesCount % 2 === 1)) {

          stats.Wins(stats.Wins() + 1);
        } else if (game.Completed !== Completed.ChallengeRejected) {
          stats.Losses(stats.Losses() + 1);
        }

        stats.TotalGames(stats.TotalGames() + 1);
      }

      if (game.Black === opponent) {
        if (game.Completed === Completed.Draw) {
          stats.Draws(stats.Draws() + 1);
        } else if (game.Completed === Completed.BlackDroppedOut || game.Completed === Completed.BlackResigned ||
          game.Completed === Completed.BlackMated || (game.Completed === Completed.Abandoned && game.MovesCount % 2 === 0)) {

          stats.Wins(stats.Wins() + 1);
        } else if (game.Completed !== Completed.ChallengeRejected) {
          stats.Losses(stats.Losses() + 1);
        }

        stats.TotalGames(stats.TotalGames() + 1);
      }
    }

    return stats;
  }
}

export enum Sections {
  None = -1,
  MyTurn = 0,
  TheirTurn = 1,
  Completed = 2,
  Watching = 3,
  Favorites = 4
}

export class GameInSection {
  section: Sections;
  displayGame: DisplayGame;

  constructor(section: Sections, displayGame: DisplayGame) {
    this.section = section;
    this.displayGame = displayGame;
  }
}

export enum ShowGames {
  All = 0,
  Archived = 1,
  VsOpponent = 2,
  Favorites = 3,
  Watching = 4
}

export class StatsVsOpponent {
  Wins: Observable<number>;
  Draws: Observable<number>;
  Losses: Observable<number>;
  TotalGames: Observable<number>;

  constructor() {
    this.Wins = ko.observable(0);
    this.Draws = ko.observable(0);
    this.Losses = ko.observable(0);
    this.TotalGames = ko.observable(0);
  }
}

export interface GamesActivation {
  gamesToShow: ShowGames;
  opponent: string;
}

export class GamesVM {

  orchestrator: Orchestrator;

  // the mode we are in. It can be that we show all the games,
  // only archived or only completed against an opponent
  showingGames: ShowGames;

  // the array with the games from the backend
  games: ObservableArray<Game>;

  gameSections: ObservableArray<GameSection>;

  gamesMyTurnSection: Observable<GameSection>;
  gamesTheirTurnSection: Observable<GameSection>;
  gamesCompletedSection: Observable<GameSection>;
  gamesWatchingSection: Observable<GameSection>;
  gamesFavoriteSection: Observable<GameSection>;

  completedGames: ObservableArray<DisplayGame>;

  private _completedModal: Modal;


  lastMoveTime: Date;

  loaded: boolean;

  chart: ChartView = null;

  showMenu = ko.observable(false);

  opponent: Observable<string>; // used only when displaying games vs a given opponent
  statsVsOpponent: Observable<StatsVsOpponent>;

  userAvatars: Array<IUserAvatar> = [];

  constructor(orchestrator: Orchestrator) {
    this.orchestrator = orchestrator;

    this.showingGames = ShowGames.All;

    this.opponent = ko.observable("");

    this.statsVsOpponent = ko.observable(new StatsVsOpponent());

    this.games = ko.observableArray([]);

    this.gameSections = ko.observableArray([]);

    this.completedGames = ko.observableArray([]);

    this._completedModal = new Modal(document.getElementById('completedGamesModal'));

    this.reset();

    // update the game status every 50 seconds
    setInterval(this._updateGameStatusForActiveGames.bind(this), 50000);
  }

  public reset() {
    this.opponent("");

    this.statsVsOpponent(new StatsVsOpponent());

    this.games([]);

    this.gameSections([]);

    this.completedGames([]);

    this.gamesMyTurnSection = ko.observable(new GameSection(Sections.MyTurn, "My turn"));
    this.gamesTheirTurnSection = ko.observable(new GameSection(Sections.TheirTurn, "Their turn"));
    this.gamesCompletedSection = ko.observable(new GameSection(Sections.Completed, "Completed"));
    this.gamesWatchingSection = ko.observable(new GameSection(Sections.Watching, "Watching"));
    this.gamesFavoriteSection = ko.observable(new GameSection(Sections.Favorites, "Favorites"));

    this.gameSections.push(this.gamesMyTurnSection());
    this.gameSections.push(this.gamesTheirTurnSection());
    this.gameSections.push(this.gamesCompletedSection());
    this.gameSections.push(this.gamesWatchingSection());
    this.gameSections.push(this.gamesFavoriteSection());

    this.lastMoveTime = new Date("1/1/1980");

    this.loaded = false;
  }

  private _logout(): void {
    this.orchestrator.logout();
  }

  private _initChart(): void {

    if (this.chart !== null) {
      return;
    }

    let canvasContainer: HTMLElement = document.getElementById("gamesChartContainer");

    let canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById("gamesChart");

    this.chart = new ChartView(canvas, canvasContainer.clientWidth, canvasContainer.clientHeight);
  }

  private _updateGameStatusForActiveGames(): void {

    if (this.gameSections().length === 0) {
      return;
    }

    this.gameSections()[0].updateGamesStatus();
    this.gameSections()[1].updateGamesStatus();
  }

  private async _getGamesFromCloud(all: boolean): Promise<boolean> {

    // the progress indicator is ON...
    let self: GamesVM = this;

    let resetLastMoveTime: boolean = true;

    if (!all) {
      let lastMoveTimeString: string = window.localStorage.getItem("lastMoveTime");

      if (lastMoveTimeString !== null) {
        try {
          this.lastMoveTime = new Date(JSON.parse(lastMoveTimeString));
          resetLastMoveTime = false;
        } catch (e) {
          self.orchestrator.log(LogType.Error, Activity.GetGames,
            "Failed to parse lastMoveTime: " + lastMoveTimeString + ". Error: " + e.message);
        }
      }
    }

    if (resetLastMoveTime) {
      this.lastMoveTime = new Date("1/1/1980");
    }

    // get the games from the Cloud
    let dataSent: IGetGamesSent = { NewerThan: self.lastMoveTime };

    let result: IGetGamesReceived = <IGetGamesReceived>await Zumo.postCallAsync<IGetGamesSent>('getGames', dataSent);

    self.orchestrator.showProgress(false);

    if (result.statusCode !== ZumoCodes.Success) {
      // need to show errors!
      if (result.statusCode === ZumoCodes.Exception) {
        if (result.message.indexOf("token") !== -1 && result.message.indexOf("authentication") !== -1) {
          self.orchestrator.log(LogType.Error, Activity.Login, `Auth issue. error: ${result.message}`);

          self._logout();
          return false;
        }
        if (result.message === "Unexpected connection failure.") {
          self.orchestrator.showMessage(`Failed to update the games. You are not online.`, LogType.Error, Activity.GetGames);

        }
      } else {
        self.orchestrator.showMessage(`Failed to update the games. Error: ${result.message}`, LogType.Error, Activity.GetGames);
      }
      return false;
    }

    self.completedGames.removeAll();

    for (let i: number = 0; i < result.games.length; i++) {

      let game: IGame = result.games[i];

      let g: Game = self.placeGameInSection(game);

      if (game.updatedAt.getTime() > self.lastMoveTime.getTime()) {
        self.lastMoveTime = game.updatedAt;
      }

      if (g.favorite()) {
        self.placeGameInFavoritesSection(g);
      }
    }

    if (result.watching !== null) {
      for (let i: number = 0; i < result.watching.length; i++) {

        let game: IGame = result.watching[i];

        let g: Game = self.placeGameInSection(game);

        if (game.updatedAt.getTime() > self.lastMoveTime.getTime()) {
          self.lastMoveTime = game.updatedAt;
        }
      }
    }

    self.orchestrator.currentUser.Active = result.user.Active;
    self.orchestrator.currentUser.Wins = result.user.Wins;
    self.orchestrator.currentUser.Losses = result.user.Losses;
    self.orchestrator.currentUser.Draws = result.user.Draws;

    if (self.orchestrator.currentUser.Active !== self.gamesMyTurnSection.length + self.gamesTheirTurnSection.length)
    {
      self.orchestrator.log(LogType.Error, Activity.GetGames, `Active games mismatch. Cloud: ${self.orchestrator.currentUser.Active}. Local - MyTurn: {GamesMyTurn.Count}, TheirTurn: {GamesTheirTurn.Count}`);
    }

    // Reveal the games pane
    document.getElementById("gamesLoading").classList.add("hidden");
    document.getElementById("gamesContainer").classList.remove("hidden");

    // save the last move time
    window.localStorage.setItem("lastMoveTime", JSON.stringify(self.lastMoveTime));

    // save the games we got from the cloud
    self.saveGamesToLocal();

    if (self.completedGames().length > 0) {
      self._showCompletedDlg();
    }

    self.orchestrator.showMessage(`Refreshed ${result.games.length} game(s) from the cloud`, LogType.Info);

    self.orchestrator.showHelpTopic(1); // New game

    // now we need to get the discussions
    await self._getDiscussionsFromTheCloudAsync(all);

    await self.orchestrator.updateAvatarsAsync();

    return true;
  }

  public getAllOpponents(): string {
    let opponents: string = '';
    let opponentsList: Array<string> = [];

    for (let i = 0; i < this.games().length; i++) {
      let g: Game = this.games()[i];

      if (opponentsList.indexOf(g.opponent()) == -1) {
        opponentsList.push(g.opponent());

        if (opponents === "") {
          opponents += g.opponent();
        } else {
          opponents += "," + g.opponent();
        }
      }
    }

    return opponents;
  }

  private async _getGames(): Promise<void> {
    this.orchestrator.showProgress(true);

    await this._getLocalDiscussions();

    // load the games from the local store. We do this with a timeout so that the browser can render
    setTimeout(this._getGamesFromLocal.bind(this), 0);
  }

  private async _getLocalDiscussions(): Promise<boolean> {

    return await this.orchestrator.discussionsCollection.loadLocalDiscussions();
  }

  private async _getDiscussionsFromTheCloudAsync(all: boolean): Promise<void> {

    let self: GamesVM = this;

    let error: string = await self.orchestrator.discussionsCollection.updateDiscussionsFromCloudAsync(all);

    if (error !== null) {
      self.orchestrator.showMessage(`Failed to get discussions. Error: ${error}`, LogType.Error, Activity.GetDiscussions);
    }
  }

  private _findDisplayGameInSection(id: number, sectionId: Sections): DisplayGame {

    let gs: GameSection = this.gameSections()[sectionId];

    return gs.findDisplayGame(id);
  }

  private _findDisplayGame(id: number): GameInSection {

    for (let sectionId: number = 0; sectionId < this.gameSections().length; sectionId++) {

      let dg: DisplayGame = this._findDisplayGameInSection(id, sectionId);

      if (dg !== null) {
        return new GameInSection(sectionId, dg);
      }
    }
    return new GameInSection(Sections.None, null);
  }

  private _addGameToCompleted(dg: DisplayGame): void {

    if (dg.game.Completed === Completed.NotCompleted) {
      return;
    }

    let crtTime: Date = new Date();

    if (crtTime.getTime() - dg.game.updatedAt.getTime() < 4 * 24 * 60 * 60 * 1000) {
      // show the game as a completed game if it completed in the last 4 days
      this.completedGames.push(dg);
    }
  }

  private _insertInSectionAscending(section: Sections, g: Game, disc: Discussion, gameAlreadyCompleted: boolean): void {

    let gameSection: GameSection = this.gameSections()[section];

    let dg: DisplayGame = new DisplayGame(g, disc, this.orchestrator);

    if (section === Sections.Completed && !gameAlreadyCompleted) {
      this._addGameToCompleted(dg);
    }

    let i: number = 0;

    gameSection.insertGame(dg, true);
  }

  private _insertInSectionDescending(section: Sections, g: Game, disc: Discussion, gameAlreadyCompleted: boolean): void {

    let gameSection: GameSection = this.gameSections()[section];

    let dg: DisplayGame = new DisplayGame(g, disc, this.orchestrator);

    if (!gameAlreadyCompleted) {
      this._addGameToCompleted(dg);
    }

    let i: number = 0;

    gameSection.insertGame(dg, false);
  }

  private _toEloDiff(eloChange: number): string {

    let eloDiff: string;

    if (eloChange === 0) {
      eloDiff = "►";
    } else if (eloChange < 0) {
      eloDiff = "▼";
      eloChange *= -1;
    } else {
      eloDiff = "▲";
    }

    eloDiff += eloChange.toString();

    return eloDiff;
  }

  public async activate(params?: GamesActivation): Promise<void> {

    this.showMenu(false);

    if (params) {
      this.showingGames = params.gamesToShow;
      this.opponent(params.opponent);
    }

    // need to call this in all cases since this also updates the eloChange for each game
    let values: Array<number> = this.gameSections()[2].getEloPoints();

    if (this.showingGames === ShowGames.Archived) {
      this._initChart();
      this.chart.setValues(values);
      this.chart.draw();
    } else if (this.showingGames === ShowGames.VsOpponent) {
      // show only the games against "opponent"
      let stats = this.gameSections()[2].computeStatsVsOpponent(this.opponent());
      this.statsVsOpponent(stats);
    } else {
      this.orchestrator.updateUserStats();
    }

    if (this.showingGames === ShowGames.All && !this.loaded) {

      await this._getGames();

      this.loaded = true;
    }
  }

  public getObjectToDump(): any {

    let obj: any = {
      loaded: this.loaded,
      showingGames: this.showingGames,
      gamesCount: this.games().length,
      lastMoveTime: this.lastMoveTime
    };

    return obj;
  }

  // APP_ONLY

  //public backClick(): void {
  //    this.orchestrator.backOnePage();
  //}

  public async refreshAsync(all: boolean, noProgress: boolean): Promise<boolean> {

    // refresh the games from the cloud. This will also refresh the discussions...
    if (!noProgress) {
      this.orchestrator.showProgress(true);
    }

    if (all) {
      // remove all display games
      this.gameSections().forEach(section => {
        section.clear();
      });
    }

    let success: boolean = await this._getGamesFromCloud(all);

    return success;
  }

  public clearLocalGames(): void {
    let userName: string = this.orchestrator.currentUserName();

    for (let i = 0; i < this.games().length; i++) {
      this.games()[i].clear();
    }

    let setting: string = `${userName}-allgames`;

    window.localStorage.removeItem(setting);
  }

  public saveGamesToLocal(): void {

    let userName: string = this.orchestrator.currentUserName();

    let gameIds: Array<number> = [];

    for (let i = 0; i < this.games().length; i++) {
      gameIds.push(this.games()[i].id);
    }

    let setting: string = `${userName}-allgames`;

    let gs: string = JSON.stringify(gameIds);

    window.localStorage.setItem(setting, gs);

    this.games().forEach(game => {
      game.save();
    });
  }

  private _getGamesFromLocal(): void {

    let userName: string = this.orchestrator.currentUserName();

    let getAllGames: boolean = true;

    let setting: string = `${userName}-allgames`;
    let localGames: string = window.localStorage.getItem(setting);

    try {
      let gamesIds: Array<number> = JSON.parse(localGames);

      if (gamesIds && gamesIds.length > 0) {
        for (let i = 0; i < gamesIds.length; i++) {
          let id = gamesIds[i];

          let g: IGame = Game.load(id);

          if (g != null) {
            let game: Game = this.placeGameInSection(g);

            if (game.favorite()) {
              this.placeGameInFavoritesSection(game);
            }

          }
        }
        getAllGames = false;
      }

    } catch (e) {
      this.orchestrator.log(LogType.Error, Activity.GetGames, "_getGamesFromLocal ran into an exception: " + e.message);
    }

    // now load the games from the cloud. We do this with a timeout so that the browser can render
    setTimeout(this._getGamesFromCloud.bind(this), 0, getAllGames);
  }

  public getOpponents(): Array<string> {
    let opponents: Array<string> = [];

    for (let i: number = 0; i < this.games().length; i++) {

      let game: Game = this.games()[i];

      if (opponents.indexOf(game.opponent()) === -1) {
        opponents.push(game.opponent());
      }
    }

    return opponents;
  }

  public getGame(id: number): Game {
    let count = this.games().length;

    for (let i: number = 0; i < count; i++) {
      let g: Game = this.games()[i];

      if (g.id == id) { // use == instead of ===. This will allow id that is number vs id that is string
        return g;
      }
    }
    return null;
  }

  public getActiveGamesCount(): number {
    return this.gameSections()[0].gamesCount() + this.gameSections()[1].gamesCount();
  }

  public placeGameInSection(g: IGame): Game {

    let gameExisting: Game = this.getGame(g.id);
    let existing: GameInSection = this._findDisplayGame(g.id);
    let newSection: Sections;
    let game: Game = gameExisting;
    let gameUpdated: boolean = false;
    let gameAlreadyCompleted: boolean = false;

    if (gameExisting === null) {
      game = new Game(g);
    } else {
      gameAlreadyCompleted = (g.Completed !== Completed.NotCompleted);

      gameUpdated = game.copy(g, false);
    }

    let disc: Discussion = this.orchestrator.discussionsCollection.getDiscussion(game.opponent());

    if (gameExisting === null) {
      this.games.push(game);
    }

    if (game.watchedGame) {
      newSection = Sections.Watching;
    } else if (game.Completed !== Completed.NotCompleted) {
      newSection = Sections.Completed;
    } else if (game.canSubmit()) {
      newSection = Sections.MyTurn;
    } else {
      newSection = Sections.TheirTurn;
    }

    if (newSection === Sections.MyTurn) {
      this._insertInSectionDescending(newSection, game, disc, gameAlreadyCompleted);
    } else {
      this._insertInSectionAscending(newSection, game, disc, gameAlreadyCompleted);
    }

    if (existing.section !== Sections.None) {
      this.gameSections()[existing.section].remove(existing.displayGame);
    }

    if (gameUpdated && game.id == this.orchestrator.gameIdInGameView()) {
      this.orchestrator.refreshGameInGameView();
    }

    return game;
  }

  public placeOrRemoveFavoriteGame(g: Game): void {

    if (g.favorite()) {
      this.placeGameInFavoritesSection(g);
    } else {
      this.removeGameFromFavoritesSection(g);
    }
  }

  private placeGameInFavoritesSection(g: Game): void {

    if (g.Completed === Completed.NotCompleted) {
      return;
    }

    let dg: DisplayGame = this._findDisplayGameInSection(g.id, Sections.Favorites);

    if (dg !== null) {
      return;
    }

    let disc: Discussion = this.orchestrator.discussionsCollection.getDiscussion(g.opponent());

    this._insertInSectionAscending(Sections.Favorites, g, disc, true);
  }

  private removeGameFromFavoritesSection(g: Game): void {

    if (g.Completed === Completed.NotCompleted) {
      return;
    }

    let dg: DisplayGame = this._findDisplayGameInSection(g.id, Sections.Favorites);

    if (dg === null) {
      return;
    }
    this.gameSections()[Sections.Favorites].remove(dg);
  }

  public updateActiveGamesForNewElo(player: string, newElo: number): void {

    let section: GameSection = this.gameSections()[0];

    section.updateGamesForNewElo(player, newElo);

    section = this.gameSections()[1];

    section.updateGamesForNewElo(player, newElo);
  }

  // #region Button clicks

  public selectedGameClick(dg: DisplayGame): void {
    this.orchestrator.activateGame(dg.game.id);
  }

  public imageLoadError(dg: DisplayGame) {
    dg.opponent.switchToLocal();
  }

  public moreClick(): void {
    this.showMenu(!this.showMenu());
  }

  // #endregion Button clicks


  public okCompletedClick(data: any, event: any): void {
    this._completedModal.hide();
  }

  private _showCompletedDlg(): void {
    this._completedModal.show();
  }
}
